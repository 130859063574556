import Voltar from '../icons/icon-img/voltar.png'
import { createStyles, makeStyles } from "@material-ui/core";

export const VoltarSairIcon = () => {
  const useStyles = makeStyles(() =>
    createStyles({
      img: {
        height: '28px',
        width: '28px',
        top: '5px',
        margin: '0 10px 0 0',
      },
    })
  )
  const classes = useStyles();
  return (
    <>
      <img
        className={classes.img}
        src={Voltar}
        alt=""
      />
    </>
  );
};


