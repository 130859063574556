import { useCallback } from "react";
import { useApiBase } from '../../base/api-base';
import { VariaveisAmbiente } from "../../../../config";

export function useGetPlanoAtual() {
  const { invocarApi, ...outrasPropriedades } = useApiBase();

  const getPlanoAtual = useCallback(
    (tokenUsuario?: string) =>
      invocarApi({
        url: `/${VariaveisAmbiente.gatewayVersion}/planos/plano-atual`,
        method: "GET",
        enviarTokenUsuario: true,
        tokenUsuario: `Bearer ${tokenUsuario}`
      })
    ,
    [invocarApi]
  );

  return {
    ...outrasPropriedades,
    getPlanoAtual,
  };
}
