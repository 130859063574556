export enum EnumMenu {

  MONITORAMENTO = 1000,
  CONFIGURARSETORES = 1001,
  CONCLUIDOSCANCELADOS = 1002,
  PESQUISAAVANCADA = 1003,
  ENTREGALOCAL = 1010,
  ENTREGADELIVERY = 1011,

  //SAIR
  MASTER_SAIR = 99999,
}
