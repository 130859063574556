import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  setores: {
    borderRadius: '8px!important',
    border: '1px solid #1F9CE4',
    boxSizing: 'border-box',
  },
  title: {
    color: '#808080',
    fontSize: '20px',
    fontWeight: 600,
    display: 'flex',
    flexBasis: '63.33%',
    alignItems: 'center',
    flexShrink: 0,
    [theme.breakpoints.down("md")]: {
      color: '#808080',
      fontSize: '18px',
      fontWeight: '700',
      flexBasis: '33.33%',
    },
    [theme.breakpoints.down("sm")]: {
      color: '#808080',
      fontSize: '18px',
      fontWeight: '700',
      flexBasis: '33.33%',
    },
  },
  formControl: {
    width: "90%",
    marginTop: 10,
    marginLeft: 0,
    display: 'flex',
    justifyContent: 'space-between'
  },
  label: {
    fontWeight: 500,
    fontSize: '17px',
    color: '#A8A8A8',
  },
  container: {
    display: 'flex',
    flexDirection: "column",
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    width: '100%',
    paddingBottom: '10px'
  },
  comSalao: {
    fontSize: '13px',
    alignItems: 'center',
    display: 'flex',
  },
  semSalao: {
    fontSize: '13px',
    alignItems: 'center',
    display: 'flex',
    color: '#a59e9e',
    marginLeft: 8
  },
  dividerTitle: {
    backgroundColor: '#DADADA',
    marginLeft: '15px',
    width: "95%",
    height: '1px'
  },
  divider: {
    backgroundColor: '#EEEEEE',
    marginTop: 5,
    width: "90%",
    height: '1px'
  }
}));