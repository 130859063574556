import { useCallback } from "react";
import { useHistory } from "react-router-dom";
import { useMenuPrincipal } from '../../../../../../../../services/app/providers/menu-principal-provider/menu-principal-provider';

export const useAcoesMenu = () => {
  const history = useHistory();
  const { fecharMenu } = useMenuPrincipal();

  const selectAction = useCallback(
    (codigoModulo: number, path: string) => {
      switch (codigoModulo) {
        default:
          history.push(path);
          break;
      }
      fecharMenu();
    },
    [history, fecharMenu]
  );
  return { selectAction: selectAction };
};
