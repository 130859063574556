import { useMemo, useCallback } from "react";
import classNames from "classnames";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { useStyles } from "./list-modulo-filho-styles";
import { useAcoesMenu } from "./acoes-menu/acoes-menu";
import { ListModuloFilhoProps } from "./list-modulo-filho-props";
import { Tooltip } from "@material-ui/core";
import React from "react";
import { IconsHelper } from "../../../../../../components/icons-helper";
import { useMenuPrincipal } from "../../../../../../../services/app/providers/menu-principal-provider";
import { EnumMenu } from "../../../../../../../model";
import { useSetores } from "../../../../../../../services/app/providers/setores-provider";

export const ListModuloFilho = (props: ListModuloFilhoProps) => {
  const { ...listItemProps } = useMemo(() => {
    return {
      ...props,
    };
  }, [props]);

  const classes = useStyles();
  const { selectAction } = useAcoesMenu();
  const { fecharMenu } = useMenuPrincipal()
  const { abrirSetores } = useSetores()
  const onClickItem = useCallback(() => {
    if (props.codigoModulo === EnumMenu.CONFIGURARSETORES) {
      abrirSetores()
      fecharMenu()
      return
    }
    selectAction(listItemProps.codigoModulo, listItemProps.rota);
  }, [props.codigoModulo, selectAction, listItemProps.codigoModulo, listItemProps.rota, abrirSetores, fecharMenu]);

  const component = React.useMemo(() => {
    return (<>
      <ListItem className={props.isFavorite ? classes.favoriteRoot : classes.root} button onClick={onClickItem}>
        <Tooltip arrow title={listItemProps.nomeModulo} placement="right">
          <ListItemIcon>
            <IconsHelper
              codigoModulo={listItemProps.codigoModulo} class={props.isFavorite ? classes.favoriteIcon : classes.icon} tipo="GERAL"
            />
          </ListItemIcon>
        </Tooltip>
        {props.modeloAtual === "Completo" && (
          <ListItemText
            classes={{
              primary: classNames(
                classes.text,
                props.isFavorite &&
                classes.favoriteText
              ),
            }}
            primary={listItemProps.nomeModulo}
          />
        )}
      </ListItem>
    </>)
  }, [
    classes.favoriteIcon,
    classes.favoriteRoot,
    classes.favoriteText,
    classes.icon,
    classes.root,
    classes.text,
    listItemProps.codigoModulo,
    listItemProps.nomeModulo,
    onClickItem,
    props.isFavorite,
    props.modeloAtual
  ]);

  return component;
};
