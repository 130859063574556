import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    container: {
        boxShadow: '1px 1px 1px 2px rgb(0, 0, 0, 0.1)',
        padding: '16px'
    },
    divider: {
        background: theme.palette.primary.main,
    },
}))