import { useEventTools } from "../../../../../services/app/use-cases/events/event-tools";
import { memo, useCallback, useEffect, useMemo, useState } from "react";
import { EnumDeviceType } from "../../../../../model/enums/enum-device-type";
import { VariaveisAmbiente } from "../../../../../config";
import { useThemeQueries } from "../../../../theme";
import { KdsStorageKeys, useKdsStorage, useSessaoAtual, useToastSaurus } from "../../../../../services/app";
import { useStyles } from "./leitor-comanda-fragment-styles";
import { AppEventEnum } from "../../../../../model/enums/enum-app-event";
import { Button, Grid, Typography } from "@material-ui/core";
import Scanner from "./components/scanner/scanner";
import { Keyboard } from "../../../../components/keyboard/keyboard";
import { useKeyboard } from "../../../../../services/app/providers/keyboard-provider/keyboard-provider";
import { ArrowDownIcon } from "../../../../components/icons/arrow-down-icon";
import { KeypadIcon } from "../../../../components/icons/keypad-icon";
import { ButtonKeyboardBackspace } from "../../../../components/keyboard/components/button-keyboard-backspace/button-keyboard-backspace";
import { useGetComanda } from "../../../../../data/api/gestao/comanda/get-comanda";
import { useDefaultCadastroStyles } from "../../cadastros/components/default-cadastro-styles";
import { ComandasModel } from "../../../../../model/api/comanda/comanda-model";
import { useHistory } from "react-router-dom";
import { ConfirmarEntregaModel } from "../../../../../model/app/forms/confirmar-entrega/confirmar-entrega-model";
import { isEmpty } from "lodash";

interface LeitorComandaProps {
  handleConfime: () => void;
}

const LeitorComanda = ({ handleConfime }: LeitorComandaProps) => {
  //PROVIDERS
  const {
    getFormattedText,
    addValue,
    sendText,
    resetText,
    addKey,
    submitKey,
    backSpaceKey,
  } = useKeyboard();
  const { callEvent } = useEventTools()

  //AUX E Outros
  const history = useHistory();
  const classes = useDefaultCadastroStyles();
  const classesLeitor = useStyles();
  const digitado = getFormattedText();
  const { showToast } = useToastSaurus();
  const { isMobile, theme } = useThemeQueries();
  const { getRegistro } = useKdsStorage();

  const confirmarPedido = getRegistro(KdsStorageKeys.PedidoConfirmarEntrega, false) as ConfirmarEntregaModel;

  //STATES E REFS
  const [digitarCodigo, setDigitarCodigo] = useState(false);

  const possuiScanner =
    VariaveisAmbiente.paymentDevice !== EnumDeviceType.MERCADOPAGO &&
    VariaveisAmbiente.paymentDevice !== EnumDeviceType.MERCADOPAGO_DEV;

  const { getComanda, carregando: carregandoComanda } = useGetComanda();
  const { getEmpresaSelecionada, carregando: carregandoSessaoAtual } = useSessaoAtual();

  const exibirDigitarCodigo = digitarCodigo || !possuiScanner || carregandoComanda || carregandoSessaoAtual;

  useEffect(() => {
    if (!exibirDigitarCodigo) callEvent(AppEventEnum.PermiteEntradaDigitada, true)

    return () => callEvent(AppEventEnum.PermiteEntradaDigitada, false)
  }, [callEvent, exibirDigitarCodigo])

  const handleClickDigitarCodigo = () => {
    setDigitarCodigo(!digitarCodigo);
  };

  const handleAddValue = useCallback(
    (value: number) => {
      addValue(value);
    },
    [addValue],
  );

  const handleBackSpace = useCallback(() => {
    backSpaceKey();
  }, [backSpaceKey]);

  const handleText = useCallback(
    (value: string) => {
      sendText(value);
    },
    [sendText],
  );

  const handleAdd = useCallback(() => {
    addKey();
  }, [addKey]);

  const handleSubmit = useCallback(() => {
    handleConfime();
    resetText('');
    submitKey();
  }, [handleConfime, resetText, submitKey]);

  const getComandaWrapper = useCallback(async () => {
    const res = await getComanda(getEmpresaSelecionada()?.Id!);

    if (res.erro)
      throw res.erro

    return res.resultado?.data?.list as Array<ComandasModel>;
  }, [getComanda, getEmpresaSelecionada])

  const redirecionar = useCallback(async (codigo: string) => {
    try {
      const comandas = await getComandaWrapper();
      const comandaFiltrada = comandas.filter(x => x.codigoComanda === confirmarPedido.codigoComanda);

      if (isEmpty(comandaFiltrada))
        return showToast('error', 'Nenhuma comanda identificada. Verifique se é uma comanda válida.')

      const comandaPedido = comandaFiltrada.filter(x => x.codigoComanda === codigo);

      if (isEmpty(comandaPedido))
        return showToast('error', 'Está comanda não existe neste pedido.')

      return history.push('/assinatura');
    } catch (err: any) {
      showToast('error', err.message)
    }
  }, [confirmarPedido.codigoComanda, getComandaWrapper, history, showToast])

  const confirmarComanda = useCallback(
    async (codComanda: string) => {
      try {
        if (codComanda.length > 3) {

          codComanda = codComanda.slice(codComanda.length - 3, codComanda.length)

          if (codComanda[0] === '0' && codComanda[1] === '0') {
            codComanda = codComanda.slice(codComanda.length - 1, codComanda.length)
          }
          else if (codComanda[0] === '0') {
            codComanda = codComanda.slice(codComanda.length - 2, codComanda.length)
          }
        } else {
          if (codComanda[0] === '0' && codComanda[1] === '0') {
            codComanda = codComanda.slice(codComanda.length - 1, codComanda.length)
          }
          else if (codComanda[0] === '0') {
            codComanda = codComanda.slice(codComanda.length - 2, codComanda.length)
          }
        }

        redirecionar(codComanda);
      } catch (err: any) {
        showToast('error', err.message);
      } finally {
        resetText('');
      }
    },
    [redirecionar, resetText, showToast],
  );

  const teclado = useMemo(() => {
    return (
      <>
        <Grid style={{ flex: 1, display: 'flex', overflowX: 'hidden' }}>
          <Keyboard
            handleAdd={handleAdd}
            handleBackSpace={handleBackSpace}
            handleAddValue={handleAddValue}
            handleSubmit={handleSubmit}
            handleText={handleText}
            isNumeric={false}
          />
        </Grid>
      </>
    );
  }, [handleAdd, handleAddValue, handleBackSpace, handleSubmit, handleText]);

  const butonBackspace = useMemo(() => {
    return (
      <Grid style={{ marginRight: 15 }}>
        <ButtonKeyboardBackspace alt="apagar" clickFunc={handleBackSpace} />
      </Grid>
    );
  }, [handleBackSpace]);

  const RenderFluxoInicialLeitor = memo(() => {
    return (
      <>
        <Button
          className={classesLeitor.button}
          onClick={handleClickDigitarCodigo}
          variant="outlined"
          color="primary"
          style={{ height: isMobile ? '90px' : '60px' }}
        >
          <KeypadIcon tipo="BUTTON" fill={theme.palette.primary.main} />

          <Typography className={classesLeitor.textButton}>
            Inserir Código Manualmente
          </Typography>
        </Button>
      </>
    );
  });


  const renderInserirCodigoManual = () => {
    return (
      <>
        <Grid className={classesLeitor.visorCodigo} style={{
          marginTop: isMobile ? '10%' : '3%'
        }}>
          <Grid
            container
            style={{ justifyContent: 'center', alignItems: 'center' }}
          >
            {possuiScanner && (
              <Grid>
                <Button
                  onClick={handleClickDigitarCodigo}
                  variant="outlined"
                  color="primary"
                  style={{
                    marginLeft: '8px',
                    width: '40px',
                    height: '40px',
                    borderRadius: '100%',
                    padding: '0',
                  }}
                >
                  <ArrowDownIcon
                    tipo="GERAL"
                    fill={theme.palette.primary.main}
                  />
                </Button>
              </Grid>
            )}
            <Grid style={{ marginRight: 15, width: '32px' }} />
            <Grid className={classesLeitor.contentVisor}>
              <Typography className={classesLeitor.label}>
                Código da Comanda
              </Typography>
              {digitado && (
                <Typography
                  className={classesLeitor.textVisor}
                  style={{ paddingLeft: '8px' }}
                >
                  {digitado.replace(/\*/g, 'x')}
                </Typography>
              )}
              {!digitado && (
                <Typography variant="h6" color="primary">
                  Aguardando Digitação
                </Typography>
              )}
            </Grid>
            {butonBackspace}
          </Grid>
        </Grid>
        {teclado}
      </>
    );
  };

  const leituraRealizada = useCallback(
    (novoCod: string) => {
      confirmarComanda(digitado + novoCod);
    },
    [confirmarComanda, digitado],
  );

  // const header = useMemo(() => {
  //   return (
  //     <Grid className={classes.header}>
  //       <LeitorComandaHeader />
  //     </Grid>
  //   );
  // }, [classes.header]);

  const adicionarButton = useMemo(() => {
    return (
      <Grid item xs={12} className={classesLeitor.containerItemFooter}>
        <Button
          disabled={!digitado}
          onClick={() => {
            handleConfime();
            resetText('');
          }}
          variant="contained"
          color="primary"
          fullWidth
          className={classesLeitor.textButton}
        >
          Confirmar Comanda
        </Button>
      </Grid>
    );
  }, [
    classesLeitor.containerItemFooter,
    classesLeitor.textButton,
    digitado,
    handleConfime,
    resetText,
  ]);

  return (
    <Grid className={classes.root}>

      {!exibirDigitarCodigo ? (
        <Grid className={classesLeitor.containerPreviewInfo}>
          <Typography className={classesLeitor.textPreview}>
            (Passe o código do produto)
          </Typography>
          <Typography
            className={classesLeitor.textPreview}
            style={{
              maxWidth: '100%',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {digitado}
          </Typography>
        </Grid>
      ) : (
        <></>
      )}

      {possuiScanner && (
        !exibirDigitarCodigo ? (
          <>
            <Scanner
              onScan={leituraRealizada}
              inicialOrSecundario={false}
            />
            <RenderFluxoInicialLeitor />
          </>
        ) : (
          renderInserirCodigoManual()
        )
      )}

      {possuiScanner && exibirDigitarCodigo && (
        <Grid className={classesLeitor.containerFooter}>{adicionarButton}</Grid>
      )}
    </Grid>
  );
};

export default LeitorComanda;