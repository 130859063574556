import * as Yup from 'yup';
import { useValidationYupSenha } from '../../../form-validations/yup/senha';
export const useFormLoginValidation = () => {
    const { senhaYup } = useValidationYupSenha()
    const FormLoginYupValidation = Yup.object().shape({
        usuario: Yup.string()
            .required('E-mail ou apelido é obrigatório.')
            .min(3, 'Deve conter no mínimo 3 caracteres.'),
        senha: senhaYup(),
    });

    return {
        FormLoginYupValidation
    }
}

