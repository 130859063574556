import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    cardRightButton: {
        width: "50px",
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        marginRight: theme.spacing(0),
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        "& svg": {
            margin: 0,
            width: "22px",
            height: "22px",
        },
    },
    cardIcon: {
        width: "50px",
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        marginRight: theme.spacing(0),
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    shadow: {
        "& .makeStyles-cardContainer-73": {
            "box-shadow": "1px 1px 7px rgb(0 0 0 / 21%)"
        }
    },
    titleCard: {
        fontSize: 14,
        fontWeight: 700,
        lineHeight: 1.5
    },
    subtitleCard: {
        fontSize: "12px!important",
        lineHeight: 1.4
    }
}));
