import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    qrcode: {
        display: 'flex',
        flexDirection: "column",
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    qrText: {
        textAlign: 'center',
        marginTop: theme.spacing(2)
    },
    loading: {
        position: 'fixed',
        right: 0,
        top: 60,
        '& > div': {
            width: 80,
            height: 80
        }
    }
}))