import { EnumMenu } from "../../model";
import { MenuModel } from '../../model/app';
import { EnumModulo } from "../../model/enums/enum-modulo";

export const ModulosMock: MenuModel[] = [
  //OPERACIONAL
  new MenuModel("Monitoramento", EnumMenu.MONITORAMENTO, [EnumModulo.KDS], "/monitoramento", 1, EnumModulo.KDS),
  new MenuModel("Configurar Setores", EnumMenu.CONFIGURARSETORES, [EnumModulo.KDS], "", 2, EnumModulo.KDS),
  new MenuModel("Concluídos e Cancelados", EnumMenu.CONCLUIDOSCANCELADOS, [EnumModulo.KDS], "/cancelados-concluidos", 3, EnumModulo.KDS),
  // new MenuModel("Pesquisa Avançada", EnumMenu.PESQUISAAVANCADA, [], "/pesquisa-avancada", 4),
  new MenuModel("Entrega Local", EnumMenu.ENTREGALOCAL, [EnumModulo.EntregaLocal], "", 1, EnumModulo.EntregaLocal),
  new MenuModel("Entrega Delivery", EnumMenu.ENTREGADELIVERY, [EnumModulo.EntregaDelivery], "", 1, EnumModulo.EntregaDelivery),

];
