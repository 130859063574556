import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    imgTitulo: {
        width: '46px',
        height: '36px',
        marginBottom: -6,
        marginRight: 6,
        fill: theme.palette.primary.main,
        [theme.breakpoints.up('sm')]: {
            width: '36px',
            height: '36px',
            marginBottom: -6,
        },
        [theme.breakpoints.up('md')]: {
            width: '40px',
            height: '40px',
            marginBottom: -6,

        },
        [theme.breakpoints.up('lg')]: {
            width: '50px',
            height: '50px',
            marginBottom: -8,
        }
    }
}));