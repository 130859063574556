import { useCallback } from "react"
import { isEmpty } from "lodash";
import { useHistory } from "react-router-dom";
import { KdsStorageKeys, useKdsStorage, useSessaoAtual, useToastSaurus } from "../../../../../services/app";
import { useGetMesa } from "../../../../../data/api/gestao/mesa/get-mesa";
import { ConfirmarEntregaModel } from "../../../../../model/app/forms/confirmar-entrega/confirmar-entrega-model";
import { MesasModel } from "../../../../../model/api/mesa/mesa-model";
import { CircularLoading } from "../../../../components";
import Scanner from "./scanner/scanner";
import { validaGuid } from "../../../../../utils/valida-guid";

export const LeitorMesaFragment = () => {

    const { getRegistro } = useKdsStorage();
    const { showToast } = useToastSaurus();
    const history = useHistory();

    const pedido = getRegistro(KdsStorageKeys.PedidoConfirmarEntrega, false) as ConfirmarEntregaModel;

    const { getMesa, carregando: carregandoMesa } = useGetMesa();
    const { getEmpresaSelecionada, carregando: carregandoSessaoAtual } = useSessaoAtual();

    const carregando = carregandoMesa || carregandoSessaoAtual;

    const getMesaWrapper = useCallback(async () => {
        const res = await getMesa(getEmpresaSelecionada()?.Id!);

        if (res.erro)
            throw res.erro

        return res.resultado?.data.list as MesasModel[]
    }, [getEmpresaSelecionada, getMesa])

    const leituraRealizada = useCallback(async (codigo: string) => {
        try {
            const mesas = await getMesaWrapper();
            const mesaFiltrada = mesas.filter(x => x.codigo === pedido.codigoMesa && x.salaoId === pedido.salaoId);

            if (isEmpty(mesaFiltrada))
                return showToast('error', 'Nenhuma mesa foi encontrada. Verifique se é um código válido.');

            const splitCodigo = codigo.split('/');
            let id = '';
            if (splitCodigo.length > 1) {
                if (validaGuid(splitCodigo[4])) {
                    id = splitCodigo[4];
                }
                else {
                    return showToast('error', 'A mesa informada não foi encontrada. Verifique se o id da mesa é valido.');
                }
            }
            else {
                id = codigo;
            }

            const mesaPedido = mesaFiltrada.filter(x => splitCodigo.length > 1 ? (x.id === id) : (x.codigo === id));

            if (isEmpty(mesaPedido))
                return showToast('error', 'A mesa informada não é a mesma deste pedido.')

            return history.push('/assinatura');
        } catch (err: any) {
            showToast('error', err.message);
        }
    }, [getMesaWrapper, history, pedido.codigoMesa, pedido.salaoId, showToast])

    return (
        <>
            {carregando && <CircularLoading tipo="FULLSIZED" />}
            <div style={{ overflow: 'auto', height: '100%' }}>
                <Scanner inicialOrSecundario={false} onScan={leituraRealizada} />
            </div>
        </>
    )
}