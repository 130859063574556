import { makeStyles } from "@material-ui/core"

export const useDefaultIconStyles = makeStyles((theme) => ({
    defaultIconOnInput: {
        marginBottom: -2,
        marginRight: 8,
        width: '28px',
        height: '28px',
        fill: theme.palette.primary.main
    },
    defaultIconOnPrivateHeader: {
        marginRight: 0,
        marginBottom: 0,
        width: '30px',
        height: '30px',
        fill: theme.palette.primary.contrastText
    },
    defaultIconOnModalHeader: {
        marginRight: 0,
        marginBottom: 0,
        width: '50px',
        height: '50px',
        fill: theme.palette.primary.contrastText
    },
    defaultIconOnButton: {
        marginBottom: 0,
        marginRight: 8,
        width: '28px',
        height: '28px',
        fill: theme.palette.primary.main
    },
    defaultIconOnButtonPrimary: {
        marginBottom: 0,
        marginRight: 8,
        width: '28px',
        height: '28px',
        fill: theme.palette.primary.contrastText
    },
    defaultIconOnButtonFab: {
        margin: 0,
        width: '32px',
        height: '32px',
        fill: theme.palette.primary.contrastText
    },
    defaultIconOnButtonlogout: {
        marginBottom: 0,
        marginRight: 8,
        width: '28px',
        height: '28px',
        fill: theme.palette.primary.main,
    }
}));