
export class SetorModel {
   constructor(
      public id: string = '',
      public descricao: string = '',
      public uriImage: string = ''
   ) { }
}



