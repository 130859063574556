import { DefaultIconProps } from "./default-icon/default-icon-props";
import { DefaultIcon } from "./default-icon/default-icon"
export const VoltarIcon = (props: DefaultIconProps) => {
    return (
        <>
            <DefaultIcon tipo={props.tipo} class={props.class} style={props.style} fill={props.fill}>
                <path d="M34 13.0888L30.341 9.42981L14.7708 25L30.341 40.5702L34 36.9112L22.1148 25L34 13.0888Z" />
            </DefaultIcon>
        </>
    );
};


