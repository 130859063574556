import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
   pedidos: {
      display: 'flex',
   },

   anterior: {
      borderRadius: '8px',
      padding: 0,
      margin: 0,
      width: '40px',
      height: '40px',
      display: 'flex',
      alignItems: 'center',
      '& svg': {
         padding: 0,
         margin: 0
      }
   },
   divBotaoAnterior: {
      display: 'flex',
      maxWidth: '40px',
      maxHeight: '40px',
      paddingRight: '8px'
   },
   cardPedidos: {
      display: 'inline-block',
      width: '100%',
      backgroundColor: '#f4f4f4',
      borderRadius: '10px',
   },
   titlePedidos: {
      color: '#545454',
      padding: '10px 10px',
      fontSize: '13px',
      borderRadius: '4px',
      fontWeight: 500,
   },
   obsPedidos: {
      color: '#545454',
      padding: '0px 10px 10px',
      fontSize: '12px',
      fontWeight: 500,
   },
   titleSubTitle: {
      fontWeight: 700,
      padding: '0 0 5px 15px',
      fontSize: '11px',

      // minha tela do notbook
      [theme.breakpoints.down("xl")]: {
         padding: '0 0 5px 15px',
         fontSize: '12px',
      },

      //tela a parte
      [theme.breakpoints.down("lg")]: {
         padding: '0 0 5px 15px',
         fontSize: '11px',
      },
      [theme.breakpoints.down("md")]: {
         padding: '0 0 5px 15px',
         fontSize: '11px',
      },
   },
   risquinho: {
      textDecoration: 'line-through'
   },
   subTitle: {
      fontWeight: 600,
      padding: '0 0 5px 25px',
      fontSize: '11px',
      color: '#949494',

      // minha tela do notbook
      [theme.breakpoints.down("xl")]: {
         padding: '0 0 5px 25px',
         fontSize: '12px',
      },

      //tela a parte
      [theme.breakpoints.down("lg")]: {
         padding: '0 0 5px 25px',
         fontSize: '11px',
      },
      [theme.breakpoints.down("md")]: {
         padding: '0 0 5px 25px',
         fontSize: '11px',
      },
   },
   botoes: {

   },
   divBotao: {
      display: 'flex',
      maxWidth: '40px',
      maxHeight: '40px',
      paddingLeft: '8px',
   },
   botaoFinalizar: {
      width: '40px',
      height: '40px',
      borderRadius: '8px',
      padding: 0,
      margin: 0,
      display: 'flex',
      alignItems: 'center',
      '& svg': {
         padding: 0,
         margin: 0
      }
   },
   divider: {
      backgroundColor: '#BDBDBD',
      marginTop: 10,
      width: "100%",
      height: '1px'
   },
   botaoCancelar: {
      backgroundColor: theme.palette.error.main,
      padding: 0,
      margin: 0,
      width: '40px',
      height: '40px',
      display: 'flex',
      '& svg': {
         padding: 0,
         margin: 0,
         width: 22,
         height: 22
      },
      borderRadius: '8px',
      '&:hover': {
         backgroundColor: theme.palette.error.dark
      },
   },
   iconInfo: {
      cursor: 'pointer',
      margin: 5,
      '& svg': {
         margin: 0,
         padding: 0
      }
   },
   prodsCheked: {
      padding: '8px',
      background: theme.palette.grey[200],
      cursor: 'pointer'
   },
}));