import { createStyles, makeStyles } from '@material-ui/styles';
import cancelados from '../icons/icon-img/cancelados.png'

export const CanceladosIcon = () => {
  const useStyles = makeStyles(() =>
    createStyles({
      img: {
        height: '28px',
        maxWidth: '35px',
        margin: '3px 10px 0 0',
      },
    })
  )
  const classes = useStyles();
  return (
    <>
      <img
        className={classes.img}
        src={cancelados}
        alt=""
      />
    </>
  );
};
