import { MultiContratoModel } from "../../../../../../model/api/multi-contrato/multi-contrato";
import { CardContrato } from "../../../../../components/cards/card-contrato/card-contrato";
import { CardNaoEncontrado } from "../../../../../components/cards/card-nao-encontrado";
import { InformacaoIcon } from "../../../../../components/icons";

export interface SelecionarContratosGridProps {
    list: Array<MultiContratoModel>;
    carregando: boolean;
    selectedList: Array<string>;
    onCardSelected: (id: string, documento: string) => any;
    onCardChecked: (id: string) => any;
}

export const SelecionarContratosListData = (props: SelecionarContratosGridProps) => {

    const onCardSelected = (contratoId: string, documento: string) => {
        props.onCardSelected(contratoId, documento);
    };

    const onCardChecked = (id: string) => {
        props.onCardChecked(id);
    };

    return (
        <>
            {props.list.length === 0 && !props.carregando && (
                <CardNaoEncontrado
                    mensagem="Nenhum Contrato encontrado."
                    icon={<InformacaoIcon tipo="GERAL" />}
                />
            )}
            {props.list.length > 0 &&
                props.list.map((contrato, index) => {
                    return (
                        <CardContrato
                            selected={
                                props.selectedList.filter((item) => item === contrato.ContratoId).length >
                                    0
                                    ? true
                                    : false
                            }
                            onCheck={onCardChecked}
                            onClick={onCardSelected}
                            model={contrato}
                            key={index}
                        />
                    );
                })}
        </>
    );
};
