import { useCallback } from "react";
import { VariaveisAmbiente } from "../../../../config";
import { useApiBase } from "../../base/api-base";

export function useGetProcessoSetor() {
    const { invocarApi, ...outrasPropriedades } = useApiBase();

    const getProcessoSetor = useCallback(
        (empresaId: string) => {
            return invocarApi({
                url: `/${VariaveisAmbiente.setoresVersion}/empresa/${empresaId}/setor/processo?pageSize=99999`,
                method: "GET",
                baseURL: `${VariaveisAmbiente.apiSetoresUrl}/api`,
                enviarTokenUsuario: true,
            });
        },
        [invocarApi]
    );

    return {
        ...outrasPropriedades,
        getProcessoSetor,
    };
}
