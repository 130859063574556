import { Grid } from '@material-ui/core';
import useEventListener from '@use-it/event-listener';
import { ButtonKeyboardAdd } from './components/button-keyboard-add/button-keyboard-add';
import { ButtonKeyboardBackspace } from './components/button-keyboard-backspace/button-keyboard-backspace';
import { ButtonKeyboardNumber } from './components/button-keyboard-number/button-keyboard-number';
import { KeyboardProps } from './keyboard-props';
import { useStyles } from './keyboard-styles';
import { ButtonKeyboardString } from './components/button-keyboard-string/button-keyboard-string';
import { ButtonKeyboardTopMultiply } from './components/button-keyboard-top-multiply/button-keyboard-top-multiply';
import { ButtonKeyboardTopAdd } from './components/button-keyboard-top-add/button-keyboard-top-add';
import { useKeyDownHandler } from '../../../services/app/use-cases/keydown-handler';
import { focusableElement } from '../utils/focusable-element';

export const Keyboard = ({
  isButtonKeyboard00,
  isButtonAddTopKeyboard,
  isButtonMultiplyTopKeyboard,
  isButtonKeyboardAdd,
  isButtonComma,
  isButtonMultiply,
  isNumeric,
  isButtonBackspace,
  handleAddValue,
  handleMultiplyValue,
  handleBackSpace,
  handleEscape,
  handleText,
  handleAdd,
  handleSubmit,
}: KeyboardProps) => {
  const classes = useStyles();
  const { handleKeyDown } = useKeyDownHandler();

  useEventListener('keydown', (event) => {
    if (!event.defaultPrevented && !focusableElement(document.activeElement)) {
      const ev = event as KeyboardEvent;
      const newValue = handleKeyDown(
        ev,
        '',
        isNumeric,
        !isNumeric,
        !isNumeric,
        0,
        100,
      );
      if (ev.key === "+" && isButtonAddTopKeyboard) {
        if (handleAdd)
          handleAdd();
      } else if (ev.key === "Enter") {
        if (handleSubmit)
          handleSubmit();
      }
      else if (ev.key === "Backspace") {
        if (handleBackSpace)
          handleBackSpace();
      }
      else if (ev.key === "Escape") {
        if (handleEscape)
          handleEscape();
      }
      else if (newValue.length > 0) {
        if (handleText)
          handleText(newValue);
      }
    }
  });

  const handleButtonAddValue = (value: number) => {
    if (handleAddValue) handleAddValue(value);
  };

  const handleButtonMultiplyValue = (value: string) => {
    if (handleMultiplyValue) handleMultiplyValue(value);
  };

  const handleClickButtonNumber = (value: number) => {
    if (handleText) handleText(value.toString());
  };

  const handleClickButtonString = (value: string) => {
    if (handleText) handleText(value);
  };

  return (
    <Grid container direction="column" style={{ height: '100%'}}>
      {isButtonMultiplyTopKeyboard && (
        <ButtonKeyboardTopMultiply
          handleButtonMultiplyValue={handleButtonMultiplyValue}
        />
      )}
      {isButtonAddTopKeyboard && (
        <ButtonKeyboardTopAdd handleButtonAddValue={handleButtonAddValue} />
      )}
      <Grid item style={{ flex: 1 }}>
        <Grid container className={classes.keyboardContainer}>
          <Grid item xs={4}>
            <ButtonKeyboardNumber
              clickFunc={handleClickButtonNumber}
              value={7}
            />
          </Grid>
          <Grid item xs={4}>
            <ButtonKeyboardNumber
              clickFunc={handleClickButtonNumber}
              value={8}
            />
          </Grid>
          <Grid item xs={4}>
            <ButtonKeyboardNumber
              clickFunc={handleClickButtonNumber}
              value={9}
            />
          </Grid>

          <Grid item xs={4}>
            <ButtonKeyboardNumber
              clickFunc={handleClickButtonNumber}
              value={4}
            />
          </Grid>
          <Grid item xs={4}>
            <ButtonKeyboardNumber
              clickFunc={handleClickButtonNumber}
              value={5}
            />
          </Grid>
          <Grid item xs={4}>
            <ButtonKeyboardNumber
              clickFunc={handleClickButtonNumber}
              value={6}
            />
          </Grid>
          <Grid item xs={4}>
            <ButtonKeyboardNumber
              clickFunc={handleClickButtonNumber}
              value={1}
            />
          </Grid>
          <Grid item xs={4}>
            <ButtonKeyboardNumber
              clickFunc={handleClickButtonNumber}
              value={2}
            />
          </Grid>
          <Grid item xs={4}>
            <ButtonKeyboardNumber
              clickFunc={handleClickButtonNumber}
              value={3}
            />
          </Grid>
          <Grid item xs={4}>
            {isButtonKeyboard00 && (
              <ButtonKeyboardString
                label="00"
                clickFunc={handleClickButtonString}
                value="00"
              />
            )}
            {isButtonKeyboardAdd && (
              <ButtonKeyboardAdd alt="Adicionar" clickFunc={handleAdd} />
            )}
            {isButtonComma && (
              <ButtonKeyboardString
                label=","
                clickFunc={handleClickButtonString}
                value=","
              />
            )}
          </Grid>
          <Grid item xs={4}>
            <ButtonKeyboardNumber
              clickFunc={handleClickButtonNumber}
              value={0}
            />
          </Grid>
          <Grid item xs={4}>
            {isButtonBackspace && (
              <ButtonKeyboardBackspace
                alt="apagar"
                clickFunc={handleBackSpace}
              />
            )}
            {isButtonMultiply && (
              <ButtonKeyboardString
                clickFunc={handleClickButtonString}
                label="x"
                value="*"
              />
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
