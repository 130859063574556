import { Button, Fade, Modal, Typography } from "@material-ui/core"
import { forwardRef, useImperativeHandle, useState } from "react";
import { VoltarSairIcon } from "../../icons"
import { useStyles } from "./card-logout-styles";

import Backdrop from '@material-ui/core/Backdrop';
import { useSessaoAtual } from "../../../../services/app";

export interface CardLogoutRef {
    fecharModal: () => void,
    abrirModal: () => void,
    deslogar: (usuarioId: string) => void;
}

export const CardLogout = forwardRef<CardLogoutRef, unknown>((props, ref) => {
    const classes = useStyles();

    const { deslogar } = useSessaoAtual();
    const [aberto, setAberto] = useState(false);

    const fecharModal = () => setAberto(false);
    const abrirModal = () => setAberto(true);

    useImperativeHandle(ref,
        () => {
            return {
                fecharModal,
                abrirModal,
                deslogar
            }
        }, [deslogar])

    return (
        <Modal
            aria-labelledby="spring-modal-title"
            aria-describedby="spring-modal-description"
            className={classes.modal}
            open={aberto}
            onClose={fecharModal}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
            style={{ zIndex: 1 }}
        >
            <Fade in={aberto}>
                <div className={classes.card}>
                    <Typography className={classes.title}> Deseja sair da sua conta ? </Typography>
                    <div className={classes.dividerF} />
                    <Typography className={classes.bottomTittle}>
                        Ao sair da sua conta não ficará salvo os Setores e Processo selecionados durante o uso neste terminal.
                    </Typography>
                    <div className={classes.divider} />
                    <Button
                        variant="contained"
                        color="primary"
                        className={classes.botaoSair}

                    >
                        <Typography className={classes.titleSair}>Sair da conta</Typography>
                    </Button>
                    <Button
                        variant="outlined"
                        color="primary"
                        className={classes.botaoVoltar}
                        startIcon={<VoltarSairIcon />}
                        onClick={fecharModal}
                    >
                        <Typography className={classes.titleVoltar}>Voltar</Typography>
                    </Button>

                </div>
            </Fade>
        </Modal>
    )
})