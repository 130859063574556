import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
   pedidos: {
      display: 'flex',
      gridTemplateColumns: "300px auto",
      position: 'relative',
      // // tela do notbook
      // [theme.breakpoints.down("xl")]: {
      //    gridTemplateColumns: "200px auto",
      // },
      // //tela do munitor
      // [theme.breakpoints.down("lg")]: {
      //    gridTemplateColumns: "235px auto",
      // },
      // // telas menores
      // [theme.breakpoints.down("md")]: {
      //    gridTemplateColumns: "200px auto",
      // },
   },

   anterior: {
      width: '100%',
      height: '100%',
      borderRadius: '8px',
      '&:hover': {
         backgroundColor: '#333'
      },
   },
   divBotaoAnterior: {
      margin: '8px 7px 0 0',
      display: 'flex',
      maxWidth: '41px',
      maxHeight: '41px'

   },
   cardPedidos: {
      display: 'inline-block',
      width: '100%',
      backgroundColor: '#f4f4f4',
      borderRadius: '10px',
      margin: '6px 0 6px 0'

   },
   titlePedidos: {
      color: '#545454',
      padding: '10px 10px',
      fontSize: '13px',
      borderRadius: '4px',
      fontWeight: 500,
      // // minha tela do notbook
      // [theme.breakpoints.down("xl")]: {
      //    color: '#545454',
      //    padding: '10px 10px',
      //    fontSize: '12px',
      //    borderRadius: '4px',
      // },
      // //tela a parte
      // [theme.breakpoints.down("lg")]: {
      //    fontWeight: 600,
      //    color: '#545454',
      //    padding: '10px 10px',
      //    fontSize: '12px',
      //    borderRadius: '4px',
      // },
      // // telas menores
      // [theme.breakpoints.down("md")]: {
      //    fontWeight: 600,
      //    color: '#545454',
      //    padding: '10px 10px',
      //    fontSize: '12px',
      //    borderRadius: '4px',
      // },
   },
   obsPedidos: {
      color: '#545454',
      padding: '0px 10px 10px',
      fontSize: '12px',
      fontWeight: 500,
      // // minha tela do notbook
      // [theme.breakpoints.down("xl")]: {
      //    color: '#545454',
      //    padding: '10px 10px',
      //    fontSize: '12px',
      //    borderRadius: '4px',
      // },
      // //tela a parte
      // [theme.breakpoints.down("lg")]: {
      //    fontWeight: 600,
      //    color: '#545454',
      //    padding: '10px 10px',
      //    fontSize: '12px',
      //    borderRadius: '4px',
      // },
      // // telas menores
      // [theme.breakpoints.down("md")]: {
      //    fontWeight: 600,
      //    color: '#545454',
      //    padding: '10px 10px',
      //    fontSize: '12px',
      //    borderRadius: '4px',
      // },
   },
   subTitle: {
      fontWeight: 600,
      padding: '0 0 5px 30px',
      fontSize: '11px',
      color: '#949494',
      // minha tela do notbook
      [theme.breakpoints.down("xl")]: {
         padding: '0 0 5px 30px',
         fontSize: '12px',
      },
      //tela a parte
      [theme.breakpoints.down("lg")]: {
         padding: '0 0 5px 30px',
         fontSize: '11px',
      },
      [theme.breakpoints.down("md")]: {
         padding: '0 0 5px 30px',
         fontSize: '11px',
      },

   },
   botoes: {

   },
   divBotao: {
      margin: '8px 0 0 10px',
      display: 'flex',
      maxWidth: '41px',
      maxHeight: '41px'

   },
   botaoFinalizar: {
      backgroundColor: '#FFAA6C',
      width: '100%',
      height: '100%',
      borderRadius: '8px',
      textDecoration: 'line-through',
      '&:hover': {
         backgroundColor: '#333'
      },
   },
   divider: {
      backgroundColor: '#BDBDBD',
      marginTop: 10,
      width: "100%",
      height: '1px'
   },
   botaoCancelar: {
      backgroundColor: theme.palette.error.main,
      width: '100%',
      height: '100%',
      borderRadius: '8px',
      textDecoration: 'line-through',
      '&:hover': {
         backgroundColor: theme.palette.error.dark
      },
   },
   buttonPontosHorizontais: {
      '& svg': {
         padding: 0,
         margin: 0
      },
      marginRight: 8
   },
   iconInfo: {
      cursor: 'pointer',
      margin: 5,
      '& svg': {
         margin: 0,
         padding: 0
      }
   }
}));