import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  card: {
    left: '43%',
    width: '100%',
    border: '1px solid #1F9CE4',
    height: '100%',
    display: 'flex',
    position: 'absolute',
    maxWidth: '550px',
    background: '#FFFFFF',
    boxShadow: '0px 0px 10px',
    maxHeight: '460px',
    borderRadius: '22px',
    flexDirection: 'column',
    backgroundColor: '#FFFFFF'

  },
  divider: {
    width: '100%',
    maxWidth: '485px',
    height: '2px',
    marginLeft: '30px',
    backgroundColor: '#ECEDED',
  },
  dividerF: {
    width: '100%',
    height: '4px',
    maxWidth: '537px',
    marginLeft: '6px',
    backgroundColor: '#ECEDED',
  },
  modal: {
    width: '100%',
    height: '100%',
    margin: 'auto',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  logout: {
    width: '100%',
    margin: 'auto',
    justifyContent: 'center',
    marginTop: '100%',
    height: "350px",
    display: 'block',
    position: "relative",
    border: '2px #C5C5C5',
    boxShadow: '0px 0px  8px',
    borderRadius: '10px',
    textAlign: 'center',
    backgroundColor: "white",
  },
  bottomTittle: {
    color: '#808080',
    width: '100%',
    padding: '48px',
    fontSize: '20px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '35px'
  },
  title: {
    color: '#797878',
    display: 'flex',
    fontSize: '25px',
    marginTop: '41px',
    fontWeight: 600,
    marginBottom: '30px',
    justifyContent: 'center',
  },
  titleVoltar: {
    fontWeight: 600,
    fontSize: '20px'
  },
  botaoVoltar: {
    top: '28em',
    right: '13%',
    width: '100%',
    position: 'absolute',
    maxWidth: '410px'
  },
  titleSair: {
    fontSize: '20px',
    fontWeight: 600
  },
  botaoSair: {
    top: '21rem',
    color: '#FDFDFD',
    right: '13%',
    width: '100%',
    display: 'flex',
    position: 'absolute',
    maxWidth: '410px',
    alignItems: 'center',
    justifyItems: 'center',
    backgroundColor: '#1F9CE4'
  },
  // botaoPai: {
  //   maxWidth: '100px'
  // }
}))




    // [theme.breakpoints.down("md")]: {
    //   height: '4px',
    //   marginLeft: '17px',
    //   width: '94%'
    // },
    // [theme.breakpoints.down("sm")]: {
    //   color: '#797878',
    //   display: 'flex',
    //   fontSize: '22px',
    //   marginTop: '40px',
    //   fontWeight: '600',
    //   marginBottom: '33px',
    //   justifyContent: 'center'
    // },