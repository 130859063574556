

export class AutenticacaoPendenciaContratoModel {
    constructor(
        public CodCobranca: string = '',
        public Status: StatusObj = new StatusObj(),
        public ParametroSistema: ParametroSistemaObj = new ParametroSistemaObj(),
        public SistemaId: string = '',
        public SistemaDescricao: string = '',
        public LinkCobranca: string = '',
        public IdRepresentante: string = '',
        public NomeRepresentante: string = '',
        public ProximoVencimento: string = '',
        public Faturas30Dias: number = 0,
        public TpCobranca: number = 0,
        public DataAdesao: string = '',
        public Ambiente: string = '',
        public Modulos: Modulo[] = [],
        public Filiais: Filial[] = [],
    ) { }
}

export class StatusObj {
    constructor(
        public indStatus: number = 0,
        public mensagemUsuario: string = '',
    ) { }
}

export class ParametroSistemaObj {
    constructor(
        public additionalProp1: string = '',
        public additionalProp2: string = '',
        public additionalProp3: string = '',
    ) { }
}

export class Modulo {
    constructor(
        public codModulo: number = 0,
        public qCom: number = 0,
        public vTotal: number = 0,
    ) { }
}

export class Filial {
    constructor(
        public id: string = '',
        public contratoId: string = '',
        public documento: string = '',
        public status: boolean = false,
        public dataAtivacao: string = '',
        public dataDesativacao: string = '',
    ) { }
}