
import { Box, Button, Card, ClickAwayListener, Fade, Menu, MenuItem, Tooltip, Typography } from "@material-ui/core"
import { isEmpty } from "lodash";
import { useCallback, useState } from "react";
import { ProcessoProdutoSetorModel } from "../../../../model/api/processo-produto-setor/processo-produto-setor-model";
import { ProcessoSetorModel } from "../../../../model/api/processo-setor/processo-setor-model";
import { EnumStatusPedido } from "../../../../model/enums/enum-status-pedido";
import { InformacaoIcon, PontosHorizontalIcon } from "../../icons";
import { useStyles } from "./card-item-pedido-pesquisa-styles";
import { usePutAlterarProcesso } from "../../../../data/api/gestao/processo-produto-setor/put-processo-produto-setor";
import { useSessaoAtual, useToastSaurus } from "../../../../services/app";
import { CircularLoading } from "../../utils";
import { useThemeQueries } from "../../../theme";

type Props = {
   model: ProcessoProdutoSetorModel,
   processosSetor: ProcessoSetorModel,
   onClickAtt: () => void
}

export const CardItemPedidoPesquisa = ({ model, processosSetor, onClickAtt }: Props) => {
   const classes = useStyles();
   const [anchorEl, setAnchorEl] = useState(null);
   const [openTooltip, setOpenTooltip] = useState<boolean>(false)
   const open = Boolean(anchorEl);
   const { getEmpresaSelecionada } = useSessaoAtual()
   const { showToast } = useToastSaurus()
   const { theme } = useThemeQueries()

   const { putAlterarProcesso, carregando: carregandoPut } = usePutAlterarProcesso()

   const expiryTimestamp = useCallback(() => new Date(model.systemInsertDate), [model.systemInsertDate])
   expiryTimestamp().setSeconds(expiryTimestamp().getSeconds() + model.minutosDePreparo * 60)

   // const temProximo = model.processos.map(processo => processo.processoProducao).filter(item => item > model.processoProducaoAtual)
   const isCancelado = model.status.codigo >= EnumStatusPedido.SolicitacaoCancelamentoAtendimento
   const isConcluido = model.status.codigo === EnumStatusPedido.Finalizado

   const handleClick = (event: any) => {
      setAnchorEl(event.currentTarget);
   };

   const alterarProcesso = useCallback(
      async (id: string[], processoProducao: number) => {
         try {
            let ret: any = ''
            ret = await putAlterarProcesso(id, getEmpresaSelecionada()?.Id || '', processoProducao)

            if (ret.erro) {
               throw ret.erro
            }
            onClickAtt()
         } catch (e: any) {
            showToast('error', e.message)
         }
      }, [getEmpresaSelecionada, onClickAtt, putAlterarProcesso, showToast])

   const handleClose = () => {
      setAnchorEl(null);
   };

   const alterarCorStatusSemMinutos = useCallback(() => {
      //Se o status for igual a 1 (PRODUÇÃO)
      if (model.status.codigo === 1) {
         return "#1F9CE4"
      }
      else if (model.status.codigo === 2) {
         return "#1F9CE4"
      }
      else if (isConcluido) {
         return "#10C822"
      }
      else if (isCancelado) {
         return theme.palette.error.main
      }
   }, [isCancelado, isConcluido, model.status.codigo, theme.palette.error.main])

   const corCancelado = useCallback(() => {
      if (isCancelado && model.processoProducaoAtual < 9000)
         return theme.palette.error.main

   }, [isCancelado, model.processoProducaoAtual, theme.palette.error.main])

   const quantidadeProcessos = model.processos.filter(item => item.processoProducao === model.processoProducaoAtual).length

   const mudaDeProcessos = processosSetor.processos.filter(item => item.processoProducao !== 9999)

   const handleCloseTooltip = useCallback(() => setOpenTooltip(false), [])

   const handleOpenTooltip = useCallback(() => setOpenTooltip(prev => !prev), [])

   return (
      <>
         <Fade in timeout={900}>
            <div className={classes.pedidos}>
               {carregandoPut && <CircularLoading tipo="FULLSIZED" />}
               <>
                  <Card className={classes.cardPedidos}
                     style={{ backgroundColor: (model.minutosDePreparo !== 0) || model.status.codigo >= EnumStatusPedido.SolicitacaoCancelamentoAtendimento ? corCancelado() : '' }}>
                     <div style={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center'
                     }}>
                        <Typography className={classes.titlePedidos} style={{
                           display: 'flex',
                           alignItems: 'center'
                        }}>
                           <strong style={{ fontSize: '15px', marginRight: 4 }}>
                              {model.produto.quantidade}x</strong> {model.produto.descricao}
                           <div style={{
                              backgroundColor: alterarCorStatusSemMinutos(),
                              color: '#FFF',
                              padding: 4,
                              borderRadius: 4,
                              marginLeft: 16,
                              fontWeight: 600
                           }}>
                              {model.processos.filter(item => item.processoProducao === model.processoProducaoAtual)[quantidadeProcessos - 1].processo}
                           </div>
                        </Typography>
                        <div style={{
                           display: 'flex'
                        }}>
                           <ClickAwayListener onClickAway={handleCloseTooltip}>
                              <div>
                                 <Tooltip
                                    disableFocusListener
                                    disableHoverListener
                                    disableTouchListener
                                    title={`Vendedor: ${model.produto.vendedor}`}
                                    open={openTooltip}
                                    onClose={handleCloseTooltip}
                                    placement="top-end"
                                    arrow
                                 >
                                    <Box display="flex" alignItems="center" className={classes.iconInfo}>
                                       <InformacaoIcon tipo="BUTTON" fill={
                                          model.status.codigo === EnumStatusPedido.SolicitacaoCancelamentoAtendimento
                                             ? '#666'
                                             : theme.palette.text.secondary} onClick={handleOpenTooltip} />
                                    </Box>
                                 </Tooltip>
                              </div>
                           </ClickAwayListener>
                           {!(model.status.codigo >= EnumStatusPedido.SolicitacaoCancelamentoAtendimento) && (
                              <>
                                 <Tooltip title="Mudar Processo" arrow>
                                    <Button variant="text" onClick={handleClick} className={classes.buttonPontosHorizontais}>
                                       <PontosHorizontalIcon tipo="BUTTON" style={{
                                          cursor: 'pointer'
                                       }} />
                                    </Button>
                                 </Tooltip>
                                 <Menu anchorEl={anchorEl} keepMounted open={open} onClose={handleClose}>
                                    {mudaDeProcessos.filter(item => item.processoProducao !== model.processoProducaoAtual).map(item => {
                                       return (
                                          <MenuItem onClick={() => {
                                             alterarProcesso([model.id], item.processoProducao)
                                             handleClose()
                                          }}>
                                             {item.descricao}
                                          </MenuItem>
                                       )
                                    })}
                                 </Menu>
                              </>
                           )}
                        </div>
                     </div>
                     {
                        !isEmpty(model.produto.observacao) && (
                           <Typography variant="body2" className={classes.obsPedidos}>
                              <strong>Obs:</strong> {model.produto.observacao}
                           </Typography>
                        )
                     }
                     {/* {
                        model.produto.subItens.map(item => {
                           return (
                              <>
                                 <Typography className={classes.subTitle}>
                                    {item.subItens}
                                 </Typography>
                              </>
                           )
                        })
                     }
                     {
                        model.produto.subItens.map(item => {
                           return (
                              <Typography className={classes.subTitle}>
                                 {item.observacao}
                              </Typography>
                           )
                        })
                     } */}
                  </Card >
               </>
            </div >
         </Fade >
      </>
   )
}

