import { useCallback, useEffect, useState, useRef } from 'react';
import { useStylesInicial } from './scanner-inicial-style';
import { useStylesSecundario } from './scanner-secundario-style';
import { Box, Grid, Typography } from '@material-ui/core';
import { isEmpty } from 'lodash';
import { Html5Qrcode, Html5QrcodeSupportedFormats } from 'html5-qrcode';
import { SemCameraIcon } from '../../../../../components/icons/sem-camera-icon';
import { useToastSaurus } from '../../../../../../services/app';

interface ScannerProps {
    inicialOrSecundario: boolean;
    openned?: boolean;
    onScan: (codigo: string) => void;
}

const Scanner = ({
    onScan,
    openned,
    inicialOrSecundario,
}: ScannerProps) => {
    const { showToast } = useToastSaurus();

    const classesInicial = useStylesInicial();
    const classesSecundario = useStylesSecundario();
    const [erro, setErro] = useState('')
    const ultimaLeitura = useRef(new Date());

    let html5QrCode: any;

    const onDetected = useCallback(
        (result) => {
            try {
                if (ultimaLeitura.current < new Date(new Date().getTime() - 3000)) {
                    ultimaLeitura.current = new Date();
                    onScan(result);
                }
            } catch (e: any) {
                showToast(
                    'error',
                    'Erro ao Interpretar o Código. Detalhe: ' + e.message,
                );
            }
        },
        [onScan, showToast],
    );

    const scannerCamera = useCallback(() => {

        let formatos = [
            Html5QrcodeSupportedFormats.CODE_128,
            Html5QrcodeSupportedFormats.CODE_93,
            Html5QrcodeSupportedFormats.CODE_39,
            Html5QrcodeSupportedFormats.QR_CODE,
            Html5QrcodeSupportedFormats.EAN_8,
            Html5QrcodeSupportedFormats.EAN_13,
        ];

        Html5Qrcode.getCameras().then(devices => {
            if (devices && devices.length) {
                let cameraBackList = []
                var cameraId: any = {
                    facingMode: 'environment'
                }
                cameraBackList = devices
                    .filter(device => device.label.match(/back/) !== null)
                if (cameraBackList.length > 0 && cameraBackList[cameraBackList.length - 1]['id'] !== undefined) {
                    cameraId = {
                        deviceId: cameraBackList[cameraBackList.length - 1]["id"]
                    }
                } else {
                    cameraId = {
                        facingMode: 'environment'
                    }
                }

                // eslint-disable-next-line react-hooks/exhaustive-deps
                html5QrCode = new Html5Qrcode("video", { verbose: false, formatsToSupport: formatos });
                html5QrCode.start(
                    cameraId,
                    {
                        fps: 60,
                    },
                    (decodedText: string) => {
                        onDetected(decodedText);
                    },
                )
            }
        })
            .catch((err: any) => {
                if (err.toString().indexOf('Permission denied') > -1) {
                    setErro('Erro ao iniciar a câmera, a permissão de uso é necessaria!')
                }
            })
    }, [onDetected]);

    const stopScanner = useCallback(() => {
        try {
            html5QrCode.stop().then(() => {
                html5QrCode.clear();
            }).catch((err: any) => {
                console.log(err.message);
            });
        } catch (err) {
            console.log(err);
        }
    }, [html5QrCode])

    useEffect(() => {
        scannerCamera();
        return () => {
            stopScanner();
        }
    }, [scannerCamera, stopScanner]);


    const renderIncialScanner = () => {
        return (
            <Grid
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    position: 'relative',
                    maxHeight: '100%',
                    minHeight: '40%',
                    height: '100%',
                }}
            >
                <Grid
                    id="video"
                    className={
                        inicialOrSecundario
                            ? classesSecundario.containerScann
                            : classesInicial.containerScann
                    }
                />
                <Grid
                    style={{
                        width: '100%',
                        height: '100%',
                        position: 'absolute',
                        display: 'flex',
                        flexDirection: 'column',
                    }}
                >
                    <Grid
                        style={{
                            flex: 1,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <div
                            style={{
                                width: '70%',
                                justifyContent: 'center',
                                alignItems: 'center',
                                textAlign: 'center'
                            }}
                        >
                            {
                                !isEmpty(erro) ? (
                                    <Box width="100%" justifyContent={"center"} display="flex">
                                        <div className={classesInicial.semCameraIcon}>
                                            <SemCameraIcon tipo="BUTTON_PRIMARY" />
                                            <Typography>
                                                <Box fontWeight={600} color="#FFF">
                                                    {erro}
                                                </Box>
                                            </Typography>
                                        </div>
                                    </Box>
                                ) : (
                                    <div style={{ borderTop: '1px solid red', width: '100%' }} />
                                )
                            }
                        </div>
                    </Grid>
                </Grid>
            </Grid>
        );
    };

    const renderSecundaryScanner = () => {
        return (
            <>
                <Grid
                    style={{ display: 'flex', justifyContent: 'center', height: '150px' }}
                >
                    <Grid
                        id="video"
                        className={
                            inicialOrSecundario
                                ? classesSecundario.containerScann
                                : classesInicial.containerScann
                        }
                    />
                    <Grid
                        style={{
                            width: '100%',
                            height: '150px',
                            position: 'absolute',
                            display: 'flex',
                            flexDirection: 'column',
                        }}
                    >
                        <Grid
                            style={{
                                flex: 1,
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                            <div
                                style={{
                                    width: '80%',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    textAlign: 'center'
                                }}
                            >
                                {
                                    !isEmpty(erro) ? (
                                        <Box width="100%" justifyContent={"center"} display="flex">
                                            <div className={classesSecundario.semCameraIcon}>
                                                <SemCameraIcon tipo="BUTTON_PRIMARY" />
                                                <Typography>
                                                    <Box fontWeight={600} color="#FFF">
                                                        {erro}
                                                    </Box>
                                                </Typography>
                                            </div>
                                        </Box>
                                    ) : (
                                        <div style={{ borderTop: '1px solid red', width: '100%' }} />
                                    )
                                }
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
            </>
        );
    };

    return (
        <>
            {inicialOrSecundario ? renderSecundaryScanner() : renderIncialScanner()}
        </>
    );
};

export default Scanner;
