import { Tooltip, Typography } from '@material-ui/core';
import { DefaultCard } from '../components/default-card';
import { useDefaultCardStyles } from '../components/default-card/default-card-styles';
import { useStyles } from './card-contrato-styles'
import { CardEmpresaProps } from './card-contrato-props';
import { useThemeQueries } from '../../../theme';
import { CaracteresString } from '../../../../utils/caracteres-string';
import { formatarCPFCNPJ } from '../../../../utils/cpfcnpj-format';
import { AvancarIcon } from '../../icons';
import { PessoaIcon } from '../../icons/pessoa-icon';

export const CardContrato = ({
    model,
    onClick,
    onCheck,
    selected,
}: CardEmpresaProps) => {
    const classes = useDefaultCardStyles();
    const classesCard = useStyles()
    const { xs } = useThemeQueries()

    return (
        <div className={classesCard.shadow}>
            <DefaultCard
                isSelected={selected}
                onClick={() => {
                    onClick(model.ContratoId, model.CNPJ);
                }}
            >
                <div className={classes.cardContent}>
                    <div className={classes.celulaGrid}>
                        <PessoaIcon tipo='BUTTON' />
                    </div>
                    <div className={classes.celulaGridFull}>
                        <Typography
                            className={`celula-grid-value, ${classesCard.titleCard}`}
                            color="primary"
                        >
                            {
                                xs
                                    ? CaracteresString(model.Nome, 26)
                                    : model.Nome
                            }
                        </Typography>
                        <Typography color="textPrimary" className={classesCard.subtitleCard}>
                            {formatarCPFCNPJ(model.CNPJ)}
                        </Typography>
                    </div>
                </div>
                <Tooltip arrow title="Selecionar empresa">
                    <div className={classesCard.cardRightButton}>
                        <AvancarIcon tipo="BUTTON" />
                    </div>
                </Tooltip>
            </DefaultCard>
        </div>
    );
};
