import { guidEmpty } from "../../../utils/guid-empty";
import { ClienteModel } from "../cliente/cliente-model";
import { DadosIntegracaoModel } from "../cliente/dados-integracao-model";
import { PagamentosModel } from "../pagamentos/pagamentos-model";
import { ProdutosModel } from "../produtos/produtos-model";
import { StatusPedidoModel } from "./status-pedido-model";
import { StatusSituacaoModel } from "./status-situacao-model";
import { TipoPedidoModel } from "./tipo-pedido-model";

export class PedidoEnderecoModel {
   constructor(
      public logradouro: string = '',
      public numero: string = '',
      public bairro: string = '',
      public municipio: string = '',
      public uf: string = '',
      public cep: string = '',
      public complemento: string = '',
      public enderecoCompleto: string = '',
      public pais: string = '',
      public pontoReferencia: string = '',
      public codigoMunicipio: string = '',
      public latitude: number = 0,
      public longitude: number = 0,
   ) {}
}

export class PedidoModel {
   constructor(
      public id: string = guidEmpty(),
      public dataCriacao: Date = new Date(),
      public systemUpdateDate: Date = new Date(),
      public systemInsertDate: Date = new Date(),
      public dataAgendamentoFinal: string = '',
      public dataAgendamentoInicial: string = '',
      public mesaId: string = guidEmpty(),
      public salaoId: string = guidEmpty(),
      public comandaId: string = guidEmpty(),
      public tipoPedido: TipoPedidoModel = new TipoPedidoModel(),
      public statusPedido: StatusPedidoModel = new StatusPedidoModel(),
      public statusSituacao: StatusSituacaoModel = new StatusSituacaoModel(),
      public codigoPedido: string = '',
      public codigoReferencia: string = '',
      public posicaoMesa: string = '',
      public documentoLoja: string = '',
      public quantidadePessoas: number = 0,
      public informacaoAdicional: string = '',
      public aplicarDescontoEcommerce: boolean = false,
      public operadorId: string = '',
      public operador: string = '',
      public valorTroco: number = 0,
      public taxaServico: number = 0,
      public valorServico: number = 0,
      public valorEntreg: number = 0,
      public descontoEcommerce: number = 0,
      public cliente: ClienteModel = new ClienteModel(),
      public produtos: ProdutosModel[] = new Array<ProdutosModel>(),
      public enderecoEntrega: PedidoEnderecoModel = new PedidoEnderecoModel(),
      public dadosIntegracao: DadosIntegracaoModel = new DadosIntegracaoModel(),
      public pagamentos: PagamentosModel[] = new Array<PagamentosModel>()
   ) { }
}