import { EnumRegime } from '../../../enums/enum-regime';
export class EmpresaFormModel {
    constructor(
        public cpfcnpj: string = '',
        public razaoSocial: string = '',
        public nomeFantasia: string = '',
        public fone: string = '',
        public cep: string = '',
        public logradouro: string = '',
        public numero: string = '',
        public complemento: string = '',
        public bairro: string = '',
        public cnae: string = '',
        public municipio: string = '',
        public cMunicipio: string = '',
        public cuf: string = '',
        public uf: string = '',
        public regime: EnumRegime = EnumRegime.NAOSEAPLICA,
        public ierg: string = ''
    ) { }
}


