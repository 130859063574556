import { Produto } from "../produto/produto-model";
import { StatusModel } from "../status/status-model";
import { ProdutoProcessoModel } from "./produto-processo/produto-processo-model";

export class ProcessoProdutoSetorModel {
   constructor(
      public id: string = '',
      public setor: string = '',
      public salao: string = '',
      public cancelado: boolean = false,
      public cliente: string = '',
      public clienteDocumento: string = '',
      public mesaCodigo: string = '',
      public posicaoMesa: string = '',
      public codigoPedido: string = '',
      public comandaCodigo: string = '',
      public minutosDePreparo: number = 0,
      public processoProducaoAtual: number = 0,
      public produto: Produto = new Produto(),
      public processos: ProdutoProcessoModel[] = new Array<ProdutoProcessoModel>(),
      public status: StatusModel = new StatusModel(),
      public salaoId: string = '',
      public setorId: string = '',
      public pedidoId: string = '',
      public produtoId: string = '',
      public processoSetorId: string = '',
      public systemInsertUser: string = '',
      public systemInsertUserId: string = '',
      public systemInsertDate: string = '',
      public systemUpdateUser: string = '',
      public systemUpdateUserId: string = '',
      public systemUpdateDate: string = '',
   ) { }
}