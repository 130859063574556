import { DefaultIconProps } from './default-icon/default-icon-props';
import { DefaultIcon } from './default-icon/default-icon';

export const PontosHorizontalIcon = (props: DefaultIconProps) => {
  return (
    <>
      <DefaultIcon
        tipo={props.tipo}
        class={props.class}
        style={props.style}
        fill={props.fill}
        onClick={props.onClick}
      >
        <path fill-rule="evenodd" clip-rule="evenodd" d="M12.5 20.8334C10.2083 20.8334 8.33332 22.7084 8.33332 25C8.33332 27.2917 10.2083 29.1667 12.5 29.1667C14.7917 29.1667 16.6667 27.2917 16.6667 25C16.6667 22.7084 14.7917 20.8334 12.5 20.8334ZM37.5 20.8334C35.2083 20.8334 33.3333 22.7084 33.3333 25C33.3333 27.2917 35.2083 29.1667 37.5 29.1667C39.7917 29.1667 41.6667 27.2917 41.6667 25C41.6667 22.7084 39.7917 20.8334 37.5 20.8334ZM20.8333 25C20.8333 22.7084 22.7083 20.8334 25 20.8334C27.2917 20.8334 29.1667 22.7084 29.1667 25C29.1667 27.2917 27.2917 29.1667 25 29.1667C22.7083 29.1667 20.8333 27.2917 20.8333 25Z" />
      </DefaultIcon>
    </>
  );
};
