import { Grid, Typography } from "@material-ui/core";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { useGetProcessoProdutoSetor } from "../../../../data/api/gestao/processo-produto-setor/get-processo-produto-setor";
import { ProcessoProdutoSetorModel } from "../../../../model/api/processo-produto-setor/processo-produto-setor-model";
import { ProcessoSetorModel } from "../../../../model/api/processo-setor/processo-setor-model";
import { KdsStorageKeys, useKdsStorage, useToastSaurus } from "../../../../services/app";
import { useSessaoAtual } from "../../../../services/app/providers/sessao-atual-provider";
import { CircularLoading } from "../../../components";
import { PublicPageHeader } from "../../../components/headers/header-public-page/header-public-page";
import { Navbar } from "../../../components/nav";
import { ConcluidosCanceladosProcessosList } from "./components/concluidos-cancelados-processos-list/concluidos-cancelados-processos-list";
import { useStyles } from "./concluidos-cancelados-styles"
import { AdicionarRemoverProcessos } from "../../../../model/app/forms/adicionar-remover-processos/adicionar-remover-processos";
import { useGetProcessoSetor } from "../../../../data/api/gestao/processo-setor/get-processo-setor";
import { isEmpty } from "lodash";

interface ConcluidosCanceladosPageProps {
   setProcessosSelecionados: React.Dispatch<React.SetStateAction<AdicionarRemoverProcessos[]>>
}

const ConcluidosCanceladosPage = (props: ConcluidosCanceladosPageProps) => {

   //AUX
   const { showToast } = useToastSaurus()
   const classes = useStyles();
   const history = useHistory()

   //PROVIDERS
   const { getRegistro } = useKdsStorage()
   const { getEmpresaSelecionada } = useSessaoAtual()
   const setoresStorage = getRegistro(KdsStorageKeys.ProcessosSetores, false) as ProcessoSetorModel[]
   const setoresLocalStorage: ProcessoSetorModel[] = useMemo(() => isEmpty(setoresStorage) ? [] : setoresStorage, [setoresStorage])

   //CHAMADAS DA API
   const { getProcessoProdutoSetor, carregando: carregandoProcessoProdutoSetor } = useGetProcessoProdutoSetor();
   const { getProcessoSetor, carregando: carregandoGetProcessoSetor } = useGetProcessoSetor()

   const carregando = carregandoGetProcessoSetor || carregandoProcessoProdutoSetor;

   //STATES E REFS
   const carregouRef = useRef<boolean>(false)
   const [produtos, setProdutos] = useState<Array<ProcessoProdutoSetorModel>>([])
   const [setores, setSetores] = useState<ProcessoSetorModel[]>([])

   const getProcessoSetorWrapper = useCallback(async () => {
      const res = await getProcessoSetor(getEmpresaSelecionada()?.Id!);

      if (res.erro) throw res.erro

      return setSetores(res.resultado?.data.list)
   }, [getEmpresaSelecionada, getProcessoSetor])

   useEffect(() => {
      if (isEmpty(setoresLocalStorage)) {
         history.push('/selecionar-setor')
         showToast('info', 'Selecione os setores para visualizar a página.')
      }
   }, [history, setores, setoresLocalStorage, setoresLocalStorage.length, showToast])

   const getProcessoProdutoSetorWrapper = useCallback(async () => {
      try {
         const query = setores.map(item => item.processos.filter(item => item.processoProducao >= 9000).map(item => `${item.id};`).toString().replaceAll(',', ''))
         const queryString = '&processosIds=' + query.map(item => item).toString().replaceAll(',', '')
         const res = await getProcessoProdutoSetor(getEmpresaSelecionada()?.Id ?? '', queryString + '&pageSize=-999999')

         if (res.erro) throw res.erro

         setProdutos(res.resultado?.data.list as ProcessoProdutoSetorModel[])

      } catch (e: any) {
         showToast('error', e.message)
      }
   }, [getEmpresaSelecionada, getProcessoProdutoSetor, setores, showToast])

   useEffect(() => {
      if (!carregouRef.current) {
         getProcessoProdutoSetorWrapper()
         getProcessoSetorWrapper();
      }
      carregouRef.current = true
   }, [getProcessoProdutoSetorWrapper, getProcessoSetorWrapper])

   useEffect(() => {
      const interval = setInterval(() => {
         getProcessoProdutoSetorWrapper();
         getProcessoSetorWrapper();
      }, 30000)

      return () => clearInterval(interval)
   }, [getProcessoProdutoSetorWrapper, getProcessoSetorWrapper])



   return (
      <>
         <PublicPageHeader topOpacity="0.8" />
         {carregando && (
            <div className={classes.loading}>
               <CircularLoading tipo="FULLSIZED" />
            </div>
         )}

         <div className={classes.root}>
            <Navbar titulo="Concluídos e Cancelados" />
            <Grid container className={classes.spacing}>
               {setoresLocalStorage.map((setor) => {

                  const ids = setores.filter(x => x.setor.id === setor.setor.id);
                  const produtosSetor = produtos.filter(item => ids.filter(x => x.setor.id === item.setorId)).filter(i => i.processoProducaoAtual >= 9000)

                  const processos = setores?.filter(x => x.setor.id === setor.setor.id)[0]?.processos

                  const concluidosCancelados = processos?.filter(x => x.processoProducao >= 9000)

                  return (
                     <>
                        <Grid item xs={12}>
                           <Typography className={classes.title}>
                              {setor?.setor.descricao}
                           </Typography>
                           <div className={classes.divider} />
                           <Grid container spacing={2}>
                              <ConcluidosCanceladosProcessosList
                                 getProcessoProdutoSetorWrapper={getProcessoProdutoSetorWrapper}
                                 processos={concluidosCancelados}
                                 produtosSetor={produtosSetor}
                                 setProcessosSelecionados={props.setProcessosSelecionados}
                              />
                           </Grid>
                        </Grid>
                     </>
                  )
               })}
            </Grid>
         </div>
      </>
   )
};

export default ConcluidosCanceladosPage