import { Box, Button, Card, Checkbox, ClickAwayListener, Fade, Grid, Tooltip, Typography } from "@material-ui/core"
import { isEmpty } from "lodash";
import { useCallback, useState } from "react";
import { ProcessoProdutoSetorModel } from "../../../../model/api/processo-produto-setor/processo-produto-setor-model";
import { EnumStatusPedido } from "../../../../model/enums/enum-status-pedido";
import { AvancarIcon, InformacaoIcon, VoltarIcon } from "../../icons";
import { useStyles } from "./card-item-pedido-styles";
import { useThemeQueries } from "../../../theme";
import { AdicionarRemoverProcessos } from "../../../../model/app/forms/adicionar-remover-processos/adicionar-remover-processos";
import { CancelIcon } from "../../icons/cancel-icon";
import CardInfoProdutoSubItem from "../card-info-produto-subitem/card-info-produto-subitem";

type Props = {
   pedidoFinalizado?: string,
   botaoFinalizar?: boolean,
   finalizado?: boolean,
   model: ProcessoProdutoSetorModel;
   processosSelecionados: AdicionarRemoverProcessos[];
   setFinalizado?: (valor: boolean) => void;
   proximo: (id: string[]) => Promise<void>
   anterior: (id: string[]) => Promise<void>
   onClickAtt?: () => Promise<void>
   estaCancelado: (id: string[]) => Promise<void>
   setProcessosSelecionados: React.Dispatch<React.SetStateAction<AdicionarRemoverProcessos[]>>
}

export const CardItemPedido = ({
   model,
   botaoFinalizar,
   processosSelecionados,
   proximo,
   anterior,
   onClickAtt,
   estaCancelado,
   setProcessosSelecionados
}: Props) => {
   const classes = useStyles();
   const [openTooltip, setOpenTooltip] = useState<boolean>(false)

   const { theme } = useThemeQueries()

   const expiryTimestamp = useCallback(() => new Date(model.systemInsertDate), [model.systemInsertDate])
   expiryTimestamp().setSeconds(expiryTimestamp().getSeconds() + model.minutosDePreparo * 60)

   // const temProximo = model.processos.map(processo => processo.processoProducao).filter(item => item > model.processoProducaoAtual)
   const isCancelado = model.status.codigo >= EnumStatusPedido.SolicitacaoCancelamentoAtendimento
   const isConcluido = model.status.codigo === EnumStatusPedido.Finalizado

   const alterarCorStatus = useCallback(() => {
      const horaAgora = new Date()
      if (horaAgora > expiryTimestamp()) {
         if (isConcluido) {
            return '#10C822'
         } else if (model.minutosDePreparo !== null) {
            return "#1F9CE4"
         }
      } else if (horaAgora < expiryTimestamp()) {
         if (isConcluido) {
            return '#10C822'
         } if (isCancelado) {
            return "#EBA6A0"
         }
         return "#1F9CE4"
      }
   }, [expiryTimestamp, isCancelado, isConcluido, model.minutosDePreparo])

   const alterarCorStatusSemMinutos = useCallback(() => {
      //Se o status for igual a 1 (PRODUÇÃO)
      if (model.status.codigo === 1) {
         return "#1F9CE4"
      }
      else if (model.status.codigo === 2) {
         return "#1F9CE4"
      }
      else if (isConcluido) {
         return "#10C822"
      }
      else if (isCancelado) {
         return "#EBA6A0"
      }
   }, [isCancelado, isConcluido, model.status.codigo])

   const corCancelado = useCallback(() => {
      if (isCancelado && model.processoProducaoAtual < 9000)
         return "#FFDFDF"
   }, [isCancelado, model.processoProducaoAtual])

   const handleCloseTooltip = useCallback(() => setOpenTooltip(false), [])

   const handleOpenTooltip = useCallback(() => setOpenTooltip(prev => !prev), [])

   const adicionarRemoverProcessos = useCallback(() => {
      const processos = processosSelecionados
      const selecionado = processos.find(x => x.produtoId === model.id)
      const processosDiferentes = processos.filter(x => x.produtoId !== model.id).filter(x => x.groupId !== selecionado?.codigoReferencia);

      if (isEmpty(selecionado)) {
         const subItens = [...model.produto.inclusos, ...model.produto?.adicionais ?? []]
         const select = [{ produtoId: model.id, groupId: null, codigoReferencia: model.produto.codigoReferencia, pedidoId: model.pedidoId }, ...subItens.map(x => ({
            produtoId: x.codigoReferencia,
            groupId: x.groupId,
            codigoReferencia: x.codigoReferencia,
            pedidoId: x.pedidoId,
         }))]

         return setProcessosSelecionados(prev => [...prev, ...select]);
      }

      setProcessosSelecionados!([...processosDiferentes])

   }, [model, processosSelecionados, setProcessosSelecionados])

   const botaoAnterior = !(model.status.codigo <= EnumStatusPedido.AguardandoInicio) && !(model.status.codigo >= EnumStatusPedido.SolicitacaoCancelamentoAtendimento);

   const botaoAvancar = model.status.codigo < EnumStatusPedido.Finalizado;

   const botaoCancelar = model.status.codigo === EnumStatusPedido.SolicitacaoCancelamentoAtendimento;

   const mostrarCorCard = [
      EnumStatusPedido.SolicitacaoCancelamentoAtendimento,
      EnumStatusPedido.SolicitacaoCancelamentoProducao
   ].includes(model.status.codigo)

   return (
      <>
         <Fade in timeout={900}>
            <Grid container style={{
               display: 'flex',
               padding: '8px'
            }}>
               <Grid item xs={12}>
                  <Grid container spacing={2}>
                     {botaoAnterior && (
                        <Grid item xs={2} style={{
                           display: 'flex',
                           justifyContent: 'center'
                        }}>
                           <Button
                              variant="contained"
                              color="primary"
                              onClick={async () => await anterior([model.id, ...(model.produto?.adicionais ?? []).map(x => x.produtoId), ...model.produto.inclusos.map(x => x.produtoId)])}
                              style={{
                                 height: '40px',
                                 width: '40px',
                                 backgroundColor: (model.minutosDePreparo !== 0) ?
                                    alterarCorStatus() : alterarCorStatusSemMinutos(),
                              }}
                           >
                              <span style={{ display: 'flex', alignItems: 'center' }}>
                                 <VoltarIcon tipo="BUTTON_PRIMARY" style={{ marginRight: '0px' }} />
                              </span>
                           </Button>
                        </Grid>
                     )}

                     <Grid item xs={
                        !botaoAnterior && botaoAvancar
                           ? 10
                           : botaoAnterior && botaoAvancar
                              ? 8
                              : botaoCancelar
                                 ? 10
                                 : 12
                     }>
                        <Card
                           className={classes.prodsCheked}
                           style={{
                              backgroundColor: mostrarCorCard ? corCancelado() : '',
                           }}
                        >

                           <div style={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'space-between'
                           }}>
                              <div style={{
                                 display: 'flex',
                                 justifyContent: 'flex-start',
                                 alignItems: 'center'
                              }}>
                                 {model.status.codigo === EnumStatusPedido.AguardandoRetirada && (
                                    <Checkbox
                                       onClick={adicionarRemoverProcessos}
                                       color="primary"
                                       checked={!isEmpty(processosSelecionados.find(x => x.produtoId === model.id))}
                                    />
                                 )}
                                 <Typography
                                    variant="body2"
                                    color="textPrimary"
                                 >
                                    <Box fontWeight={500}>
                                       {model.produto.quantidade}x
                                    </Box>
                                 </Typography>
                                 <Typography
                                    variant="body2"
                                    color="textPrimary"
                                    style={{
                                       paddingLeft: '8px'
                                    }}
                                 >
                                    {model.produto.descricao}
                                 </Typography>
                              </div>

                              <div style={{
                                 display: 'flex',
                                 justifyContent: 'flex-end'
                              }}>
                                 <ClickAwayListener onClickAway={handleCloseTooltip}>
                                    <Tooltip
                                       disableFocusListener
                                       disableHoverListener
                                       disableTouchListener
                                       title={`Vendedor: ${model.produto.vendedor}`}
                                       open={openTooltip}
                                       onClose={handleCloseTooltip}
                                       placement="top-end"
                                       arrow
                                    >
                                       <Box display="flex" alignItems="center" className={classes.iconInfo}>
                                          <InformacaoIcon
                                             tipo="BUTTON"
                                             fill={mostrarCorCard ? '#666' : theme.palette.text.secondary}
                                             onClick={handleOpenTooltip} />
                                       </Box>
                                    </Tooltip>
                                 </ClickAwayListener>
                              </div>
                           </div>

                           {!isEmpty(model.produto.observacao) && (
                              <Typography
                                 variant="body2"
                                 className={classes.obsPedidos}
                              >
                                 {`Obs: ${model.produto.observacao}`}
                              </Typography>
                           )}

                           {model.produto.inclusos.map(item => {
                              return (
                                 <>
                                    <CardInfoProdutoSubItem
                                       produto={item}
                                       qtdPai={model.produto.quantidade}
                                    />
                                 </>
                              )
                           })}

                           {model.produto.adicionais && model.produto.adicionais.length > 0 && (
                              <Box pl={2}>
                                 <Typography
                                    variant="caption"
                                    color="textSecondary"
                                 >
                                    <Box fontWeight={700}>
                                       Adicionais
                                    </Box>
                                 </Typography>
                                 <Box pl={.5}>
                                    {model.produto.adicionais.map(item => {
                                       return (
                                          <CardInfoProdutoSubItem
                                             produto={item}
                                             qtdPai={model.produto.quantidade}
                                          />
                                       )
                                    })}
                                 </Box>
                              </Box>
                           )}
                        </Card>
                     </Grid>
                     {botaoAvancar && (
                        <Grid item xs={2} style={{
                           display: 'flex',
                           justifyContent: 'center'
                        }}>
                           <Button
                              variant="contained"
                              color="primary"
                              onClick={async () => await proximo([model.id, ...(model.produto?.adicionais ?? []).map(x => x.produtoId), ...model.produto.inclusos.map(x => x.produtoId)])}
                              style={{
                                 height: '40px',
                                 width: '40px',
                                 backgroundColor: (model.minutosDePreparo !== 0) ?
                                    alterarCorStatus() : alterarCorStatusSemMinutos(),
                              }}
                           >
                              <span style={{ display: 'flex', alignItems: 'center' }}>
                                 <AvancarIcon tipo="BUTTON_PRIMARY" style={{ marginRight: '0px' }} />
                              </span>
                           </Button>
                        </Grid>
                     )}

                     {botaoCancelar && (
                        <Grid item xs={2} style={{
                           display: 'flex',
                           justifyContent: 'center'
                        }}>
                           <Button
                              variant="contained"
                              onClick={async () => await estaCancelado([model.id, ...(model.produto?.adicionais ?? []).map(x => x.produtoId), ...model.produto.inclusos.map(x => x.produtoId)])}
                              style={{
                                 height: '40px',
                                 width: '40px',
                                 background: theme.palette.error.main,
                              }}
                           >
                              <span style={{ display: 'flex', alignItems: 'center' }}>
                                 <CancelIcon tipo="BUTTON_PRIMARY" style={{ marginRight: '0px' }} />
                              </span>
                           </Button>
                        </Grid>
                     )}

                  </Grid>
               </Grid>
            </Grid>
         </Fade>
      </>
   )
}

