import { guidEmpty } from "../../../utils/guid-empty";
import { DadosIntegracaoModel } from "../cliente/dados-integracao-model";
import { StatusPedidoModel } from "./status-pedido-model";
import { StatusSituacaoModel } from "./status-situacao-model";
import { TipoPedidoModel } from "./tipo-pedido-model";

export class PedidoEnderecoModel {
    constructor(
        public logradouro: string = '',
        public numero: string = '',
        public bairro: string = '',
        public municipio: string = '',
        public uf: string = '',
        public cep: string = '',
        public complemento: string = '',
        public enderecoCompleto: string = '',
        public pais: string = '',
        public pontoReferencia: string = '',
        public codigoMunicipio: string = '',
        public latitude: number = 0,
        public longitude: number = 0,
    ) { }
}

export class PedidoResumoModel {
    constructor(
        public id: string = guidEmpty(),
        public mesaId: string = guidEmpty(),
        public mesaCodigo: string = '',
        public salaoId: string = guidEmpty(),
        public salao: string = '',
        public comandaId: string = guidEmpty(),
        public codigoComanda: string = '',
        public tipoPedido: TipoPedidoModel = new TipoPedidoModel(),
        public statusPedido: StatusPedidoModel = new StatusPedidoModel(),
        public statusAutomacao: StatusSituacaoModel = new StatusSituacaoModel(),
        public dataCriacao: Date = new Date(),
        public systemUpdateDate: Date = new Date(),
        public dataAgendamentoFinal: string = '',
        public dataAgendamentoInicial: string = '',
        public codigoPedido: string = '',
        public identificador: string = '',
        public codigoReferencia: string = '',
        public documentoLoja: string = '',
        public quantidadePessoas: number = 0,
        public dadosIntegracao: DadosIntegracaoModel = new DadosIntegracaoModel(),
        public enderecoEntrega: PedidoEnderecoModel = new PedidoEnderecoModel(),
        public operadorId: string = '',
        public operador: string = '',
        public valorTotalFrete: number = 0,
        public valorTotalPedido: number = 0,
        public valorTotalServico: number = 0,
        public valorTotalDesconto: number = 0,
        public valorTotalAcrescimo: number = 0,
        public valorTotalUnitarioItens: number = 0
    ) { }
}