import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    closeButton: {
        color: theme.palette.grey[500],
        padding: 0
    },
    dialogPaper: {
        "& .MuiDialog-paper": {
            border: `2px ${theme.palette.primary.main} solid`,
            borderRadius: 20,
            boxShadow: "0px 0px 10.6406px rgba(0, 0, 0, 0.15)"
        }
    }
}));
