import { EnumStatusSalao } from "../../enums/enum-status-salao";
import { BalcaoModel } from "../balcao/balcao-model";

export class StatusSalaoModel {
   constructor (
      public codigo: EnumStatusSalao = EnumStatusSalao.ATIVO,
      public descricao: string = 'Ativo',
   ) { }
}

export class SalaoModel {
   constructor(
      public id: string = '',
      public descricao: string = '',
      public status: StatusSalaoModel = new StatusSalaoModel(),
      public balcao: BalcaoModel = new BalcaoModel()
   ) { }
}