import { useCallback } from 'react';
import { IdentidadeAutenticarEmailProps, usePostIdentidadeAutenticarEmail } from '../../../data/api/gestao/identidade/post-autenticar-email';
import { IdentidadeAutenticarApelidoProps, usePostIdentidadeAutenticarApelido } from '../../../data/api/gestao/identidade/post-autenticar-apelido';
import { RetornoApiModel } from '../../../model';
import { validarEmail } from '../../../utils/valida-email';

interface AuthProps {
  usuario: string;
  senha: string;
  contratoId: string;
  integracao?: boolean
}

export const useAuth = () => {
  const {
    postIdentidadeAutenticarEmail,
    carregando: carregandoAutenticacaoEmail
  } = usePostIdentidadeAutenticarEmail();
  const {
    postIdentidadeAutenticarApelido,
    carregando: carregandoAutenticacaoApelido
  } = usePostIdentidadeAutenticarApelido();

  const carregando =
    carregandoAutenticacaoEmail || carregandoAutenticacaoApelido;

  const authEmail = useCallback(
    async (email: string, senha: string, contratoId: string) => {
      return await postIdentidadeAutenticarEmail(
        new IdentidadeAutenticarEmailProps(email, senha, {
          contratoId: contratoId
        })
      );
    },
    [postIdentidadeAutenticarEmail]
  );

  const authApelido = useCallback(
    async (apelido: string, senha: string, contratoId: string) => {
      return await postIdentidadeAutenticarApelido(
        new IdentidadeAutenticarApelidoProps(apelido, senha)
      );
    },
    [postIdentidadeAutenticarApelido]
  );

  const auth = useCallback(
    async ({ usuario, senha, contratoId }: AuthProps) => {
      let retApi: RetornoApiModel;

      if (validarEmail(usuario)) {
        retApi = await authEmail(usuario, senha, contratoId);
      } else {
        retApi = await authApelido(usuario, senha, contratoId);
      }

      return retApi
    },
    [authApelido, authEmail]
  );

  return {
    auth,
    carregando
  };
};
