import { Button, Card, Divider, Fade, Grid, Typography } from "@material-ui/core"
import { useCallback, useState } from "react"
import { EnumStatusPedido } from "../../../../model/enums/enum-status-pedido"
import { TextFieldSaurus } from "../../controles/inputs"
import { BalcaoIcon, CanceladosIcon } from "../../icons"
import { CardPedido } from "../card-pedido"
import { CardProcessoProps } from "./card-processo-props"
import { useStyles } from './card-processo-style'
import { useThemeQueries } from "../../../theme"
import { isEmpty } from "lodash"
import { CodeBarIcon } from "../../icons/code-bar-icon"
import { MotoboyIcon } from "../../icons/motoboy-icon"

export const CardProcesso = (
    {
        processo,
        getProcessoProdutoSetorWrapper,
        count,
        processosqtd,
        produtosProcessos,
        pedidos,
        isMonitoramento = false,
        setAbrirQrCode,
        processosSelecionados,
        setProcessosSelecionados,
    }: CardProcessoProps) => {

    const classes = useStyles()
    const { theme } = useThemeQueries();

    const [pesquisa, setPesquisa] = useState<string>('')

    let divisoes = parseInt((12 / processosqtd).toString());
    if (divisoes < 3)
        divisoes = 3;

    const isConcluidoCancelados = processo.status.codigo >= EnumStatusPedido.Finalizado
    const isConcluido = processo.status.codigo === EnumStatusPedido.Finalizado
        || processo.status.codigo === EnumStatusPedido.AguardandoRetirada

    const produtosProcessosFiltrados = produtosProcessos.filter(item => {
        if (isMonitoramento) {
            return item
        }
        if (pesquisa.length === 0) {
            return item
        }
        if (
            item?.comandaCodigo?.toLowerCase()?.includes(pesquisa.toLowerCase()) ||
            item?.mesaCodigo?.toLowerCase()?.includes(pesquisa.toLowerCase())
        ) {
            return item;
        }

        return false
    })

    const produtosUnicos = Array.from(new Set<string>(produtosProcessosFiltrados.filter(item => {

        const duasHorasMais = new Date(item.systemUpdateDate).setHours(new Date(item.systemUpdateDate).getHours() + 2)
        if (isMonitoramento) {
            return item
        }
        if (item.processoProducaoAtual === 9999) {
            return true
        }

        if (item.processoProducaoAtual === 9900 && new Date().getTime() <= duasHorasMais) {
            return true
        }

        return false
    }).map(item => item.pedidoId)))

    const abrirQrCodeClick = useCallback(async () => {
        setAbrirQrCode!(true)
    }, [setAbrirQrCode])

    const isRetirada = produtosProcessosFiltrados.filter(x => x.status.codigo === EnumStatusPedido.AguardandoRetirada);

    const isEntrega = produtosProcessosFiltrados.filter(x => x.status.codigo === EnumStatusPedido.EmEntrega);

    const processos = produtosProcessosFiltrados.filter(item => produtosUnicos.filter(x => x === item.pedidoId));

    const filtro = processos.filter(x => x.status.codigo >= EnumStatusPedido.Finalizado && !isEmpty(pesquisa) ? (x.comandaCodigo === pesquisa || x.mesaCodigo === pesquisa) : null);

    const resultadoFiltro = !isEmpty(pesquisa) && isEmpty(filtro);

    return (
        <>
            <Fade in timeout={600}>
                <Card className={classes.divContainer}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} style={{
                                    display: 'flex',
                                    alignItems: 'center'
                                }}>
                                    <Typography
                                        variant="h6"
                                        color="textPrimary"
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center'
                                        }}
                                    >
                                        {isConcluidoCancelados && (
                                            <>
                                                {isConcluido ?
                                                    <BalcaoIcon tipo="BUTTON_FAB" fill={theme.palette.primary.main} style={{
                                                        marginRight: '8px'
                                                    }} />
                                                    : isEntrega ?
                                                        <MotoboyIcon tipo="BUTTON_FAB" fill={theme.palette.primary.main} style={{
                                                            marginRight: '8px'
                                                        }} />
                                                        : <CanceladosIcon />}
                                            </>
                                        )}
                                        {processo.descricao}
                                    </Typography>
                                    {processo.processoProducao === 9900 && (
                                        <Typography
                                            variant="caption"
                                            color='textPrimary'
                                        >
                                            (Últimas 2 horas)
                                        </Typography>
                                    )}

                                </Grid>
                                {isConcluidoCancelados && (
                                    <Grid item xs={12}>
                                        <TextFieldSaurus
                                            label="Mesa/Comanda"
                                            size="small"
                                            fullWidth
                                            allowSubmit
                                            value={pesquisa}
                                            onChange={ev => setPesquisa(ev.target.value)}
                                        />
                                    </Grid>
                                )}
                                <Grid item xs={12}>
                                    <Divider variant="fullWidth" style={{ background: theme.palette.grey[300] }} />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} className={classes.divPedido}>
                            {produtosUnicos.length > 0 ? (
                                <Grid container spacing={2}>
                                    {!resultadoFiltro ? (
                                        produtosUnicos.map((id) => {
                                            const pedido = pedidos?.find(x => x.id === id);

                                            const processos = produtosProcessosFiltrados.filter(item => item.pedidoId === id)

                                            const filtroPesquisado = processos.filter(x => x.status.codigo >= EnumStatusPedido.Finalizado && !isEmpty(pesquisa) ? (x.comandaCodigo === pesquisa || x.mesaCodigo === pesquisa) : null);

                                            return (
                                                <>
                                                    <Grid item xs={12} sm={6} md={count.length === 1 ? 6 : 12} lg={count.length === 1 ? 4 : count.length === 2 ? 6 : 12}>
                                                        <CardPedido
                                                            pesquisa={pesquisa}
                                                            pedido={pedido}
                                                            filtroPesquisa={filtroPesquisado}
                                                            onClickAtt={getProcessoProdutoSetorWrapper}
                                                            modelProdutos={processos}
                                                            processosSelecionados={processosSelecionados}
                                                            setProcessosSelecionados={setProcessosSelecionados}
                                                        />
                                                    </Grid>
                                                </>
                                            )
                                        })
                                    ) : (
                                        <>
                                            <Grid item xs={12} style={{
                                                display: 'flex',
                                                justifyContent: 'center'
                                            }}>
                                                <Typography
                                                    className={classes.nadaParaExibir}
                                                    style={{
                                                        display: 'flex',
                                                        textAlign: 'center'
                                                    }}
                                                >
                                                    {`Nenhum pedido encontrado com o item "${pesquisa}".`}
                                                </Typography>
                                            </Grid>
                                        </>
                                    )}
                                </Grid>
                            ) : (
                                <Grid>
                                    <Typography className={classes.nadaParaExibir}>
                                        Não há pedido nesse processo
                                    </Typography>
                                </Grid>
                            )}
                        </Grid>

                        {!isEmpty(isRetirada) && (
                            <Grid item xs={12}>
                                <>
                                    <Button
                                        variant="contained"
                                        fullWidth={true}
                                        color="primary"
                                        startIcon={<CodeBarIcon tipo="BUTTON_PRIMARY" />}
                                        onClick={abrirQrCodeClick}
                                        disabled={processosSelecionados?.filter(x => !isEmpty(produtosProcessosFiltrados.find(y => y.pedidoId === x.pedidoId))).length === 0 ? true : false}
                                    >
                                        Abrir QR Code
                                    </Button>
                                </>
                            </Grid>
                        )}
                    </Grid>
                </Card>
            </Fade>
        </>
    )
}