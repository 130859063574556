import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { ExpiredTokenRoute } from './components/expired-token-route';
import { useMemo } from 'react';
import LogoutPage from '../views/pages/public/logout/logout-page';
import { SessaoAtualProvider } from '../services/app/providers/sessao-atual-provider/sessao-atual-provider';
import SelecionarEmpresaPage from '../views/pages/private/selecionar-empresa/selecionar-empresa-page';
import LoginPage from '../views/pages/public/login/login-page';
import RouterMain from './components/router-main/router-main';
import { GlobalStatesProvider } from '../services/app/providers/global-states-provider/global-states-provider';
import { RecoilRoot } from 'recoil';
import SelecionarContratoPage from '../views/pages/public/selecionar-contrato/selecionar-contrato-page';
import PendenciasPage from '../views/pages/public/pendencias/pendencias-page';
import SelecaoModuloPage from '../views/pages/private/selecao-modulo/selecao-modulo-page';

export const RouterApp = () => {
  const switchApp = useMemo(() => (
    <Switch>
      <Route exact path="/logout" component={LogoutPage} />
      <Route exact path="/login/:parametro?" component={LoginPage} />
      <Route exact path="/selecionar-empresa" component={SelecionarEmpresaPage} />
      <Route exact path="/selecionar-contrato" component={SelecionarContratoPage} />
      <Route exact path="/selecionar-modulo" component={SelecaoModuloPage} />
      <Route exact path="/pendencias" component={PendenciasPage} />
      <ExpiredTokenRoute>
        <RouterMain />
      </ExpiredTokenRoute>
    </Switch>
  ), []);

  return (
    <GlobalStatesProvider>
      <RecoilRoot>
        <SessaoAtualProvider>
          <BrowserRouter>
            {switchApp}
          </BrowserRouter>
        </SessaoAtualProvider>
      </RecoilRoot>
    </GlobalStatesProvider>
  );
};
