import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  appBar: {
    width: '100%',
    top: 0,
    display: 'flex',
    flexDirection: 'column',
    zIndex: 1
  },
  appBarShift: {
    width: '100%',
    position: 'relative',
    display: 'flex',
  },
  hide: {
    display: 'none',
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  drawerPaper: {
    width: '350px',
    height: '100%',
    zIndex: 2,
    minWidht: '350px'
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    justifyContent: 'flex-end',
    [theme.breakpoints.down("xl")]: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
    },
  },
  root: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
  },
  drawer: {
    maxWidth: '100px',
    width: '100%',
    flexShrink: 0
  },
  nomeUsuario: {
    color: '#1F9CE4',
    fontSize: '18px',
    fontWeight: 550,
    marginLeft: '8px'
  },
  divider: {
    width: '100%',
    height: '1px!important',
    background: '#e0e0e0'
  },
  defaultContainer: {
    position: "relative",
    width: "100%",
    height: "100%",
    overflowY: "auto",
    overflowX: "auto"
  },
  text: {
    fontSize: '22px',
  },
}))