import { Typography } from '@material-ui/core';
import { useCallback, useEffect, useRef } from 'react';
import { useStyles } from './timer-styles';



export const Time = (props: {
  range: number,
  inserted: Date
}) => {
  const classes = useStyles();

  const inicio = new Date().getTime();
  const fim = ((props.inserted).getTime() + (props.range * 60000))
  const refTempo = useRef<HTMLDivElement>(null);
  const atrasado: boolean = fim < inicio;
  const tempo = ((fim - inicio) < 0 ? inicio - fim : fim - inicio);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => startTimer(), [])

  const startTimer = useCallback(() => {
    let interval: any = null;
    const startTime = +tempo;

    let counter = startTime;
    if (refTempo.current)
      refTempo.current.innerHTML = convertMsToTime(startTime, atrasado);

    if (interval) clearInterval(interval);

    interval = setInterval(() => {
      if (refTempo.current) {
        if (atrasado)
          refTempo.current.innerHTML = convertMsToTime(counter += 1000, atrasado).toString();
        else
          refTempo.current.innerHTML = convertMsToTime(counter -= 1000, atrasado).toString();
      }

    }, 1000);
  }, [atrasado, tempo])
  return (
    <>
      <Typography
        className={classes.time}
        variant='body2'
      >
        <div ref={refTempo}>  {convertMsToTime(tempo, atrasado)} </div>
      </Typography>
    </>
  )
}

function convertMsToTime(milliseconds: number, atraso: boolean): string {
  let seconds = Math.floor(milliseconds / 1000);
  let minutes = Math.floor(seconds / 60);
  let hours = Math.floor(minutes / 60);

  seconds = seconds % 60;
  minutes = minutes % 60

  return fitTime(seconds, minutes, hours, atraso);

}

const fitTime = (seconds: number, minutes: number, hours: number, atraso: boolean): string => {

  let time: any = "0";
  let dias = Math.ceil((hours / 24));
  let semanas = Math.ceil((dias / 7))

  if (hours < 1)
    time = `${hours}h ${minutes}m`
  if (hours > 0)
    time = `${hours}h ${minutes}m`
  if (hours >= 10)
    time = `${hours}h`
  if (hours === 24)
    time = `${dias} dia`
  if (hours > 24)
    time = `${dias} dias`
  if (dias >= 7)
    time = `${semanas} sem`

  return atraso ? `${time} ` : time.toString()
}