import { useStyles } from './menu-principal-perfil-styles';
import { Box, Grid, Typography } from '@material-ui/core';
import { MenuPrincipalPerfilProps } from './menu-principal-perfil-props';
import { useState, useEffect, useCallback } from 'react';
import classNames from 'classnames';
import React from 'react';
import UsuarioSemImagem from '../../../../../../../assets/img/usuario-sem-imagem.png';
import { KdsStorageKeys, useKdsStorage, useSessaoAtual } from '../../../../../../../services/app';
import { SelectSaurus } from '../../../../../../components/controles/selects/select-saurus/select-saurus';
import { KeyValueModel } from '../../../../../../../model';
import { useConfirm } from 'material-ui-confirm';
import { useHistory } from 'react-router-dom';
import { useSetores } from '../../../../../../../services/app/providers/setores-provider';
import { useMenuPrincipal } from '../../../../../../../services/app/providers/menu-principal-provider';

export const MenuPrincipalPerfil = (props: MenuPrincipalPerfilProps) => {
  const classes = useStyles();
  const history = useHistory();
  const confirm = useConfirm();
  const { abrirSetores } = useSetores();
  const { fecharMenu } = useMenuPrincipal();
  const { delRegistro } = useKdsStorage();
  const {
    usuario,
    getEmpresaSelecionada,
    selecionarEmpresa
  } = useSessaoAtual();
  const [nomeUsuario, setNomeUsuario] = useState(
    usuario?.saudacao || 'Visitante',
  );
  const [empresaAtual, setEmpresaAtual] = useState<string>(
    getEmpresaSelecionada()?.Id || '',
  );
  const [imagemAtual, setImagemAtual] = useState(UsuarioSemImagem);

  const EmpresaListMock = React.useMemo(() => {
    return usuario!.empresa.map((item) => {
      return new KeyValueModel(item.Id, item.Descricao);
    });
  }, [usuario]);

  useEffect(() => {
    let imagem = '';
    try {
      if ((usuario?.imagem || '').indexOf('http') > -1) {
        imagem = usuario?.imagem!;
      } else {
        imagem = (usuario?.imagem || '').length > 0 ? atob(usuario?.imagem || '') : '';
      }
    } catch { }
    setImagemAtual(imagem);
    setNomeUsuario(usuario?.saudacao || 'Visitante');
  }, [usuario]);

  const addDefaultSrc = useCallback((e: any) => {
    setImagemAtual(UsuarioSemImagem);
  }, []);

  const handleEmpresa = React.useCallback(
    async (id: string) => {
      selecionarEmpresa(id);
      setEmpresaAtual(id);
      delRegistro(KdsStorageKeys.ProcessosSetores, false);
      fecharMenu();
      history.push('/selecionar-setor');
      return abrirSetores();
    },
    [abrirSetores, delRegistro, fecharMenu, history, selecionarEmpresa],
  );

  const component = React.useMemo(() => {
    return (
      <>
        <div
          className={classNames(
            classes.drawerResumoPerfilContainer,
            props.modeloAtual === 'Completo' && !props.isMobile
              ? classes.drawerPerfilCompletoNotMobile
              : undefined,
            props.modeloAtual === 'Mini' ? classes.drawerPerfilMini : undefined,
          )}
        >
          <div className="fotoPerfilContainer">
            {imagemAtual !== UsuarioSemImagem && (
              <img src={imagemAtual} onError={addDefaultSrc} alt="" />
            )}
            {imagemAtual === UsuarioSemImagem && (
              <img src={imagemAtual} alt="" />
            )}
          </div>
          <div>
            {props.modeloAtual === 'Completo' && (
              <div className="nomeContainer">
                <div className={classes.containerNomeEPlano}>
                  <Typography className={classes.usuarioNome}>
                    {nomeUsuario}
                  </Typography>
                </div>
              </div>
            )}
            {usuario!.empresa.length > 1 && (
              <Box mt={"4px"}>
                <Grid>
                  <SelectSaurus
                    allowSubmit
                    size="small"
                    conteudo={EmpresaListMock}
                    variant="standard"
                    className={classes.selectEmpresa}
                    onChange={(event) => {
                      if (event) {
                        const newStatus = EmpresaListMock.filter(
                          (item) => item.Key === event.target.value,
                        )[0]?.Key;

                        if (newStatus !== empresaAtual) {
                          confirm({
                            title: 'Selecionar Empresa',
                            description:
                              'Você tem certeza que quer trocar de empresa. Isso irá remover os processos já selecionados ?',
                            cancellationText: 'Voltar',
                            cancellationButtonProps: {
                              color: 'default',
                            },
                            confirmationText: 'Selecionar',
                          }).then(() => {
                            return handleEmpresa(newStatus);
                          })
                        }
                      }
                    }}
                    value={empresaAtual}
                  />
                </Grid>
              </Box>
            )}
          </div>
        </div>
      </>
    );
  }, [EmpresaListMock, addDefaultSrc, classes.containerNomeEPlano, classes.drawerPerfilCompletoNotMobile, classes.drawerPerfilMini, classes.drawerResumoPerfilContainer, classes.selectEmpresa, classes.usuarioNome, confirm, empresaAtual, handleEmpresa, imagemAtual, nomeUsuario, props.isMobile, props.modeloAtual, usuario]);

  return component;
};
