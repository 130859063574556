import { createContext, useCallback, useContext, useState } from "react"
import { DialogProcessoSetor } from "../../../../views/components/dialog/dialog-processo-setor/dialog-processo-setor";

interface SetoresContextValues {
   fecharSetores: () => void,
   abrirSetores: () => void

}

const SetoresContext = createContext<SetoresContextValues>({
   fecharSetores: () => true,
   abrirSetores: () => true,

})
export interface SetoresProviderProps {
   children: React.ReactNode;
}

export const useSetores = () => useContext(SetoresContext)
export const SetoresProvider = ({ children }: SetoresProviderProps) => {
   const [aberto, setAberto] = useState(false)


   const fecharSetores = useCallback(() => {
      setAberto(false);
   }, [setAberto])

   const abrirSetores = useCallback(() => {
      setAberto(true);
   }, [setAberto])


   return (
      <SetoresContext.Provider value={{
         fecharSetores,
         abrirSetores,
      }}>
         <>
            {aberto && (
               <DialogProcessoSetor openned={aberto} />
            )}
            {children}
         </>
      </SetoresContext.Provider>
   )

}