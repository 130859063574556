import { createStyles, makeStyles } from '@material-ui/styles';
import Adicionar from '../icons/icon-img/adicionarSetorIcon.png'

export const AdicionarIcon = () => {
  const useStyles = makeStyles(() => 
    createStyles({
      img: {
        width: '28px',
        height: '28px',
        maxWidth: '30px',
        margin: '10px',
      },
    })
  )
  const classes = useStyles();
  return (
    <>
      <img
      className={classes.img}
      src={Adicionar} 
      alt="" 
      />
    </>
  );
};
