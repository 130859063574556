import React, { useState } from 'react';
import { useCallback } from 'react';
import { usePostIdentidadeRefreshToken } from '../../../../data/api/gestao/identidade/post-refresh-token';
import { PerfilModel } from '../../../../model/api/gestao/master/perfil';
import { useGetPerfilById } from '../../../../data/api/gestao/perfil/get-perfil-by-id';
import { PlanoAtualModel } from '../../../../model/api/gestao/plano/plano-atual-model';
import { useGetPlanoAtual } from '../../../../data/api/gestao/plano/get-plano-atual';
import { useGetConfigNfceExists } from '../../../../data/api/gestao/empresa/get-config-emissor-exists';
import { useGetPoliticaValidar } from '../../../../data/api/gestao/politica/get-politica-validar';
import { ApiError } from '../../../../model/app/errors/api-erros';
import { TokenKdsEmpresaModel } from '../../../../model/api/gestao/master/token-kds-empresa';
import { KdsStorageKeys, useKdsStorage } from '../../use-cases';
import { TokenKdsModel } from '../../../../model/api/gestao/master/token-kds';
import { useKdsToken } from "../../../app";
import { MultiContratoModel } from '../../../../model/api/multi-contrato/multi-contrato';
import { isPlanoControleProducao } from '../../../../utils/plano-utils';
import { useAuth } from '../../use-cases/auth';

interface SessaoAtualContextValue {
  usuario?: TokenKdsModel;
  plano?: PlanoAtualModel | undefined;
  getEmpresaSelecionada: () => TokenKdsEmpresaModel | undefined;
  getPerfil: () => Array<PerfilModel>;
  carregando: boolean;
  logar: (
    usuario: string,
    senha: string,
    contratoId: string,
    manterConectado: boolean,
  ) => Promise<string>;
  deslogar: (usuarioId: string) => void;
  setarUser: (token: string, manterConectado: boolean) => Promise<void>;
  carregarDadosUsuario: (user: TokenKdsModel) => Promise<void>;
  refreshUser: (refreshToken: string) => Promise<boolean>;
  sessaoExpirada: () => boolean;
  converterToken: (token: string) => TokenKdsModel | undefined;
  validaUsuarioConectado: () => boolean;
  termosDeUsoAtivo: () => boolean;
  selecionarEmpresa: (empresaId: string) => Promise<void>;
  getIsEmpresaFiscal: () => boolean;
  setIsEmpresaFiscal: (fiscalEnabled: boolean) => void;
  verificarSeEmpresaTemNFCe: (empresaId: string) => Promise<void>;
  refreshPlanoUsuario: () => Promise<void>;
}

const SessaoAtualContext = React.createContext<SessaoAtualContextValue>({
  usuario: undefined,
  carregando: false,
  getEmpresaSelecionada: () => undefined,
  getPerfil: () => new Array<PerfilModel>(),
  sessaoExpirada: () => false,
  logar: (usuario: string, senha: string, contratoId: string, manterConectado: boolean) => {
    return new Promise<string>(() => true);
  },
  converterToken: (token: string) => {
    return undefined;
  },
  setarUser: async () => { },
  deslogar: (usuarioId: string) => { },
  carregarDadosUsuario: (user: TokenKdsModel) => {
    return new Promise<void>(() => true);
  },
  refreshUser: (refreshToken: string) => {
    return new Promise<boolean>(() => true);
  },
  termosDeUsoAtivo: () => false,

  selecionarEmpresa: (empresaId: string) => new Promise<void>(() => { }),
  validaUsuarioConectado: () => false,
  refreshPlanoUsuario: () => {
    return new Promise<void>(() => true);
  },
  getIsEmpresaFiscal: () => {
    return false;
  },
  setIsEmpresaFiscal: (fiscalEnabled: boolean) => {
    return;
  },
  verificarSeEmpresaTemNFCe: (empresaId: string) => {
    return new Promise<void>(() => true);
  },
});

export interface SessaoAtualProviderProps {
  children: React.ReactNode;
}

export const useSessaoAtual = () => React.useContext(SessaoAtualContext);

export const SessaoAtualProvider = ({ children }: SessaoAtualProviderProps) => {
  // PROVIDERS
  const {
    getTokenFromStorage,
    convertToken,
    getConnectedUsers,
    persistTermosDeUso,
    getEmpresaAtual,
    persistEmpresaSelecionada,
    getTermosDeUso,
    getPerfilPermissoes,
    persistPerfilPermissoes,
    isTokenValid,
    persistToken,
    addConnectedUser,
    removeConnectedUser,
    getPlanoUsuario,
    persistPlanoUsuario,
  } = useKdsToken();
  const { delRegistro, setRegistro, getRegistro } = useKdsStorage();
  const { auth, carregando: carregandoAuth } = useAuth()
  // STATES E REFS
  const [loadingManual, setLoadingManual] = useState(false);
  const [usuario, setarUsuario] = React.useState(getTokenFromStorage());
  const [plano, setPlano] = React.useState(getPlanoUsuario());

  // CHAMDAS API
  const { getPerfilById, carregando: carregarPerfilById } = useGetPerfilById();
  const { postIdentidadeRefreshToken, carregando: carregandoRefreshToken } =
    usePostIdentidadeRefreshToken();
  useGetPoliticaValidar();
  const { getPlanoAtual, carregando: carregandoPlanoAtual } =
    useGetPlanoAtual();
  const { getConfigNfceExists, carregando: carregandoEmissorExists } =
    useGetConfigNfceExists();
  // AUX
  const loading =
    [
      carregandoRefreshToken,
      carregarPerfilById,
      loadingManual,
      carregandoEmissorExists,
      carregandoPlanoAtual,
      carregandoAuth,
    ].includes(true)


  const sessaoExpirada = React.useCallback((): boolean => {
    return !isTokenValid(usuario);
  }, [isTokenValid, usuario]
  );

  const converterToken = React.useCallback(
    (token: string): TokenKdsModel | undefined => {
      return convertToken(token);
    },
    [convertToken],
  );

  const termosDeUsoAtivo = React.useCallback((): boolean => {
    return getTermosDeUso();
  }, [getTermosDeUso]
  );

  const limparVariaveis = useCallback(() => {
    persistPlanoUsuario(undefined);
    persistTermosDeUso(undefined, undefined);
    setPlano(undefined);
    persistPerfilPermissoes(undefined);
    delRegistro(KdsStorageKeys.IsEmpresaFiscal, false)

    sessionStorage.clear();
  }, [delRegistro, persistPerfilPermissoes, persistPlanoUsuario, persistTermosDeUso]
  );

  const deslogar = React.useCallback(
    (usuarioId: string) => {
      removeConnectedUser(usuarioId || '');
      persistToken(undefined);
      setarUsuario(undefined);
      limparVariaveis();
    },
    [removeConnectedUser, persistToken, limparVariaveis],
  );

  const refreshPlanoUsuario = useCallback(async (): Promise<void> => {
    //Pega o plano atual
    try {
      const retPlano = await getPlanoAtual();
      if (retPlano.erro) {
        throw retPlano.erro;
      }

      if (retPlano.statusCode === 204) {
        persistPlanoUsuario(new PlanoAtualModel());
        setPlano(new PlanoAtualModel());
      } else {
        persistPlanoUsuario(retPlano.resultado?.data as PlanoAtualModel);
        setPlano(retPlano.resultado?.data as PlanoAtualModel);
      }
    } catch (e: any) {
      throw e;
    }
  }, [getPlanoAtual, persistPlanoUsuario, setPlano]
  );

  const validaUsuarioConectado = useCallback(() => {
    const users = getConnectedUsers();
    return users.filter((x) => x.usuarioId === usuario?.usuarioId).length > 0;
  }, [getConnectedUsers, usuario?.usuarioId]
  );

  const carregarDadosUsuario = React.useCallback(
    async (user: TokenKdsModel) => {
      try {
        setLoadingManual(true);
        const perfilIds = Array<string>();
        //PREENCHO OS PERFIS DELE (DISTINCT)
        if (user?.empresa) {
          for (let i = 0; i < user?.empresa.length; i++) {
            if (perfilIds.indexOf(user?.empresa[i]?.Perfil?.Id) === -1) {
              perfilIds.push(user?.empresa[i]?.Perfil?.Id);
            }
          }
        }

        const perfis = new Array<PerfilModel>();
        //FACO A BUSCA NA API PELOS PERFIS DO USUARIO
        for (let i = 0; i < perfilIds.length; i++) {
          const retApiPerfil = await getPerfilById(perfilIds[i]);
          if (retApiPerfil.erro) {
            throw retApiPerfil.erro;
          }
          perfis.push(retApiPerfil.resultado?.data);
        }

        //SALVA OS PERFIS!
        persistPerfilPermissoes(perfis);

        setLoadingManual(false);
      } catch (e: any) {
        throw e;
      } finally {
      }
    },
    [persistPerfilPermissoes, getPerfilById],
  );

  const refreshUser = React.useCallback(
    async (refreshToken: string): Promise<boolean> => {
      try {
        const retRefresh = await postIdentidadeRefreshToken(refreshToken);

        if (retRefresh?.resultado?.data?.accessToken) {
          const newuser = convertToken(
            retRefresh?.resultado?.data?.accessToken,
          );
          if (newuser) {
            if (validaUsuarioConectado()) {
              addConnectedUser(newuser);
            }

            persistToken(newuser);
            await carregarDadosUsuario(newuser);
            setarUsuario(newuser);
            return true;
          }
        }
      } catch (e: any) {
        persistToken(undefined);
        persistPerfilPermissoes(undefined);
        persistPlanoUsuario(undefined);
        persistTermosDeUso(undefined, undefined);
        setPlano(undefined);
        setarUsuario(undefined);
        throw e;
      }
      return false;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const setIsEmpresaFiscal = React.useCallback(
    (fiscalEnabled: boolean) => {
      setRegistro(KdsStorageKeys.IsEmpresaFiscal, fiscalEnabled, false);
    },
    [setRegistro],
  );

  const vendaNFCE = React.useCallback(
    async (empresaId: string): Promise<boolean> => {
      const emissor = await getConfigNfceExists(empresaId || '');
      if (emissor.erro) {
        new Error('Erro ao consultar a situação de NFC-e.');
        return false;
      }

      if ((emissor.resultado?.data || false) === true) {
        return true;
      }

      return false;
    },
    [getConfigNfceExists],
  );

  const getEmpresaSelecionada = useCallback(():
    | TokenKdsEmpresaModel
    | undefined => {
    const emp = usuario?.empresa.filter((x) => x.Id === getEmpresaAtual());
    if (emp === null || emp?.length === 0) return undefined;
    if (emp) return emp[0];

    return undefined;
  },
    [getEmpresaAtual, usuario?.empresa]
  );

  const verificarSeEmpresaTemNFCe = useCallback(
    async (empresaId: string) => {
      const nfce = await vendaNFCE(empresaId);
      setIsEmpresaFiscal(nfce);
    },
    [setIsEmpresaFiscal, vendaNFCE],
  );

  const selecionarEmpresa = useCallback(
    async (empresaId: string): Promise<void> => {
      persistEmpresaSelecionada(empresaId);
    },
    [persistEmpresaSelecionada],
  );

  const getIsEmpresaFiscal = React.useCallback(() => {
    const isNFCE = getRegistro(KdsStorageKeys.IsEmpresaFiscal, false);
    return isNFCE;
  }, [getRegistro]
  );

  const setarUser = useCallback(
    async (token: string, manterConectado: boolean) => {
      const user = convertToken(token);


      const empresaAtual = getRegistro(KdsStorageKeys.EmpresaAtual, false)
      const documentoContrato = getRegistro(KdsStorageKeys.DocumentoContrato) as string | undefined

      let mesmaEmpresa = user?.empresa.find(empresas => empresas.Id === empresaAtual)?.Id

      if (mesmaEmpresa !== empresaAtual) {
        delRegistro(KdsStorageKeys.EmpresaAtual, false)
        delRegistro(KdsStorageKeys.ProcessosSetores, false)
      }

      if (!isTokenValid(user))
        throw new Error(
          'O usuário informado não é válido ou expirou seu acesso. (InvalidToken)',
        );
      //PERSISTE NO STORAGE
      persistToken(user);

      //FAZEMOS A CONSULTA DOS DADOS NECESSÁRIOS DO USUARIO
      if (user) {
        await carregarDadosUsuario(user);
      }
      if (manterConectado) {
        if (user !== undefined) addConnectedUser(user);
      }

      //SETAMOS O USUARIO
      setarUsuario(user);

      if (user?.empresa) {
        if (user.empresa.filter(x => x.Documento === documentoContrato).length > 0) {
          await selecionarEmpresa(user.empresa.filter(x => x.Documento === documentoContrato)[0].Id)
          delRegistro(KdsStorageKeys.DocumentoContrato)
        } else {
          delRegistro(KdsStorageKeys.DocumentoContrato)
        }
        if (user.empresa.length === 1) {
          await selecionarEmpresa(user?.empresa[0].Id);
        }
      }
    },
    [convertToken, getRegistro, isTokenValid, persistToken, delRegistro, carregarDadosUsuario, addConnectedUser, selecionarEmpresa],
  );

  const logar = React.useCallback(
    async (
      usuario: string,
      senha: string,
      contratoId: string,
      manterConectado: boolean,
    ): Promise<string | any> => {
      try {
        setLoadingManual(true);
        const retAuth = await auth({ usuario, senha, contratoId })

        //Usuario não confirmado está sem autorização
        if (retAuth.statusCode === 401) {
          throw new ApiError(retAuth.statusCode, retAuth.erro.message);
        }

        if (retAuth.statusCode === 300) {
          return retAuth.resultado?.data as MultiContratoModel
        }

        if (retAuth.statusCode === 402) {
          return {
            status: retAuth.statusCode,
            data: retAuth.resultado?.data
          }
        }

        if (retAuth.erro) {
          throw retAuth.erro;
        }

        const retPlano = await getPlanoAtual(retAuth.resultado?.data.accessToken)

        if (retPlano.erro) {
          throw retPlano.erro
        }

        const planoModel = retPlano.resultado?.data as PlanoAtualModel

        if (!isPlanoControleProducao(planoModel.plano)) {
          throw new Error('Seu plano não tem acesso a este sistema.')
        }

        await setarUser(retAuth?.resultado?.data?.accessToken, manterConectado);
        return retAuth?.resultado?.data?.accessToken
      } catch (e) {
        throw e;
      } finally {
        setLoadingManual(false);
      }
    },
    [auth, getPlanoAtual, setarUser]
  );

  return (
    <SessaoAtualContext.Provider
      value={{
        usuario,
        plano,
        getEmpresaSelecionada,
        getPerfil: getPerfilPermissoes,
        carregando: loading,
        deslogar,
        setarUser,
        carregarDadosUsuario,
        refreshUser,
        sessaoExpirada,
        logar,
        converterToken,
        validaUsuarioConectado,
        termosDeUsoAtivo,
        selecionarEmpresa,
        getIsEmpresaFiscal,
        setIsEmpresaFiscal,
        verificarSeEmpresaTemNFCe,
        refreshPlanoUsuario,
      }}
    >
      {children}
    </SessaoAtualContext.Provider>
  );
};