import { Badge, Button, Checkbox, Grid } from "@material-ui/core"
import { CardProdutosEntregaProps } from "./card-produtos-entrega-props"
import { CardProduto } from "./components/card-produto/card-produto"
import { useStyles } from "./card-produtos-entrega-styles"
import { isEmpty } from "lodash"
import { useCallback, useRef } from "react"
import { GarcomIcon } from "../../icons/garcom-icon"
import { useHistory } from "react-router-dom"
import { ConfirmarEntregaModel } from "../../../../model/app/forms/confirmar-entrega/confirmar-entrega-model"
import { KdsStorageKeys, useKdsStorage } from "../../../../services/app"
import { EnumModulo } from "../../../../model/enums/enum-modulo"

export const CardProdutosEntrega = (props: CardProdutosEntregaProps) => {
    const classes = useStyles();
    const history = useHistory();
    const { setRegistro } = useKdsStorage()

    const confirmarBtn = useRef<HTMLButtonElement | null>(null)

    const agruparProdutosSubitens = () => {
        return props.model.map(x => {


            if (x.produto.subItens && x.produto.subItens.length > 0) {
                const inclusos = x.produto.subItens.filter(subitem => isEmpty(subitem.adicionalId));
                const adicionais = x.produto.subItens.filter(subitem => !isEmpty(subitem.adicionalId))
                return {
                    ...x,
                    produto: {
                        ...x.produto,
                        adicionais: adicionais,
                        inclusos: inclusos,
                    }
                }
            }

            return {
                ...x,
                produto: {
                    ...x.produto,
                    adicionais: [],
                    inclusos: [],
                }
            }
        })
    }

    const produtos = agruparProdutosSubitens();


    const entrega = useCallback(() => {
        const confirmarEntrega = new ConfirmarEntregaModel();

        if (props.tipoTrabalho.modulo === EnumModulo.EntregaDelivery) {

            produtos.map((x) => {
                confirmarEntrega.codigoPedido = x.codigoPedido;
                confirmarEntrega.salaoId = x.salaoId;
                confirmarEntrega.processoAtual = x.processoProducaoAtual;
                confirmarEntrega.codigoComanda = x.comandaCodigo;
                confirmarEntrega.codigoMesa = x.mesaCodigo;
                confirmarEntrega.nomeCliente = x.cliente;
                confirmarEntrega.documentoClinte = x.clienteDocumento;
                confirmarEntrega.dataPedido = x.systemInsertDate;
                confirmarEntrega.nomeSalao = x.salao;
                confirmarEntrega.processosProdutosIds.push(x.id);
                confirmarEntrega.produtos.push(x.produto);
                confirmarEntrega.pedido = props.pedido;

                return confirmarEntrega
            })

            setRegistro(KdsStorageKeys.PedidoConfirmarEntrega, confirmarEntrega, false);

            return history.push(`/confirmar-entrega`)
        }

        props.processosSelecionados.map((x) => {
            confirmarEntrega.codigoPedido = x.codigoPedido;
            confirmarEntrega.salaoId = x.salaoId;
            confirmarEntrega.processoAtual = x.processoProducaoAtual;
            confirmarEntrega.codigoComanda = x.comandaCodigo;
            confirmarEntrega.codigoMesa = x.mesaCodigo;
            confirmarEntrega.nomeCliente = x.cliente;
            confirmarEntrega.documentoClinte = x.clienteDocumento;
            confirmarEntrega.dataPedido = x.systemInsertDate;
            confirmarEntrega.nomeSalao = x.salao;
            confirmarEntrega.processosProdutosIds.push(x.id);
            confirmarEntrega.produtos.push(x.produto);
            confirmarEntrega.pedido = props.pedido;

            return confirmarEntrega;
        })

        setRegistro(KdsStorageKeys.PedidoConfirmarEntrega, confirmarEntrega, false);

        return history.push(`/confirmar-entrega`)
    }, [history, produtos, props.pedido, props.processosSelecionados, props.tipoTrabalho.modulo, setRegistro])

    const selectAll = useCallback(() => {
        if (props.processosSelecionados.length === produtos.length) {
            props.setProcessosSelecionados([]);
        } else {
            props.setProcessosSelecionados(produtos);
        }
    }, [produtos, props]);


    function eUltimoElemento<T>(array: T[], elemento: T): boolean {
        const ultimoIndice = array.length - 1;
        const indiceDoElemento = array.indexOf(elemento);

        return indiceDoElemento === ultimoIndice;
    }

    const validarButtonEntregar = props.tipoTrabalho.modulo === EnumModulo.EntregaLocal
        ? props.processosSelecionados
            .filter(x => !isEmpty(produtos.find(y => y.pedidoId === x.pedidoId))).length === 0
            ? true
            : false
        : false

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} className={classes.cardProduto}>
                {produtos.map((item) => {

                    const ultimoIndex = eUltimoElemento(produtos, item)

                    return (
                        <CardProduto
                            model={item}
                            ultimoIndex={ultimoIndex}
                            confirmarBtn={confirmarBtn}
                            tipoTrabalho={props.tipoTrabalho}
                            processosSelecionados={props.processosSelecionados}
                            setProcessosSelecionados={props.setProcessosSelecionados}
                        />
                    )
                })}
            </Grid>

            <Grid item xs={12}>
                <Grid container spacing={1}>
                    {props.tipoTrabalho.modulo === EnumModulo.EntregaLocal && (
                        <Grid item xs={6}>
                            <Button
                                variant="outlined"
                                className={classes.buttonCard}
                                fullWidth
                                onClick={selectAll}
                                style={{
                                    background: '#FFF'
                                }}
                            >
                                <Checkbox
                                    color="primary"
                                    style={{
                                        padding: '0px',
                                        marginRight: '8px'
                                    }}
                                    checked={props.processosSelecionados.length === produtos.length && props.processosSelecionados.filter(x => !isEmpty(produtos.find(y => y.pedidoId === x.pedidoId))).length > 0 ? true : false}
                                />
                                Sel. Todos
                            </Button>
                        </Grid>
                    )}
                    <Grid item xs={props.tipoTrabalho.modulo === EnumModulo.EntregaDelivery ? 12 : 6}>
                        <Button
                            variant="contained"
                            color="primary"
                            className={classes.buttonCard}
                            fullWidth
                            onClick={entrega}
                            disabled={validarButtonEntregar}
                        >
                            <Badge
                                badgeContent={props.processosSelecionados.filter(x => !isEmpty(produtos.find(y => y.pedidoId === x.pedidoId && x.produto.groupId === null))).length}
                                color="error"
                                style={{
                                    display: 'flex',
                                    alignItems: 'center'
                                }}
                            >
                                <GarcomIcon tipo="BUTTON_PRIMARY"></GarcomIcon>
                                Entregar
                            </Badge>
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}