import { useCallback } from "react";
import { useApiBase } from "../../base/api-base";
import { VariaveisAmbiente } from "../../../../config";

export function useGetMesa() {
    const { invocarApi, ...outrasPropriedades } = useApiBase();

    const getMesa = useCallback(
        (empresaId: string, query?: string) => {
            return invocarApi({
                url: `/${VariaveisAmbiente.pedidosVersion}/empresa/${empresaId}/salao/mesa?${query}`,
                method: "GET",
                enviarTokenUsuario: true,
                baseURL: `${VariaveisAmbiente.apiPedidosUrl}/api`,
            });
        },
        [invocarApi]
    );
    return {
        ...outrasPropriedades,
        getMesa,
    };
}