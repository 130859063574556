import { makeUseAxios, Options } from "axios-hooks";
import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { useCallback, useEffect, useState } from "react";
import { isEmpty, merge } from "lodash";
import LRU from "lru-cache";
import { VariaveisAmbiente } from "../../../config";
import { ApiBaseProps } from "./api-base-props";
import { ApiBaseResponse, EnumRetornoApiBase } from './api-base-response';
import { useHistory } from 'react-router';
import { RetornoApiModel } from "../../../model";
import { useSessaoAtual } from '../../../services/app/providers/sessao-atual-provider/sessao-atual-provider';
import { KdsStorageKeys, useKdsStorage, useKdsToken } from "../../../services/app";
import { consoleDev } from "../../../utils/console-dev";

type RequestParams = AxiosRequestConfig & {
  enviarTokenUsuario: boolean;
  tokenUsuario?: string | null
};

class ExpiredError extends Error {
  public response: Object;
  constructor(message: string, statusCode: number) {
    super(message)
    this.response = {
      status: statusCode
    }
  }
}

const defaultTimeout = 10 * 1000; /* 10 Segundos */
const useAxios = makeUseAxios({
  axios: axios.create({
    baseURL: `${VariaveisAmbiente.apiUrl}/api`,
    timeout: defaultTimeout,
    timeoutErrorMessage:
      "O tempo da requisição foi excedido. Verifique sua conexão com internet e tente novamente.",
  }),
  cache: new LRU({ max: 10 }),
});


export function useApiBase(props?: ApiBaseProps) {

  const { refreshUser, deslogar, converterToken, validaUsuarioConectado } = useSessaoAtual();
  const history = useHistory();

  const { getRegistro } = useKdsStorage();
  const { isTokenExpired } = useKdsToken();
  const { config, opcoes } = props || {};
  const [{ loading: carregandoAxios }, invocarAxios] = useAxios<any>(
    config || "",
    merge({ manual: true }, opcoes)
  );

  const [carregandoInterno, setCarregandoInterno] = useState(false);

  useEffect(() => {
    setCarregandoInterno(carregandoAxios);
  }, [carregandoAxios]);

  const invocarApi = useCallback(async (cfg?: RequestParams, options?: Options, tentarNovamente: boolean = true): Promise<RetornoApiModel> => {
    const objRet = { tipoRetorno: EnumRetornoApiBase.Servidor, statusCode: 0 };
    let resultado: AxiosResponse<any> | undefined;
    let erro: Error | undefined;

    resultado = undefined;
    erro = undefined;

    const tokenStorage = getRegistro(KdsStorageKeys.Token, false);
    const userToken = converterToken(tokenStorage);

    try {

      if (cfg?.enviarTokenUsuario && !isEmpty(userToken) && isTokenExpired(userToken)) {
        throw new ExpiredError('Token Expirado', 401)
      }

      const tokenEnvio = (!cfg?.tokenUsuario && isEmpty(cfg?.tokenUsuario)) && cfg?.enviarTokenUsuario
        ? `Bearer ${tokenStorage}`
        : cfg?.tokenUsuario
          ? cfg.tokenUsuario
          : null;
      resultado = await invocarAxios(
        {
          ...cfg,
          headers: {
            ...(!cfg?.enviarTokenUsuario ? null : { Authorization: tokenEnvio }),
            'FrontUrl': VariaveisAmbiente.frontUrl,
            ...cfg?.headers,
          },
        },
        options
      );

      const { tipoRetorno: tpretorno, statusCode: retStatus } = ApiBaseResponse(undefined, resultado);
      objRet.statusCode = retStatus;
      objRet.tipoRetorno = tpretorno;

    } catch (e: Error | any) {
      if (VariaveisAmbiente.isDev) consoleDev(e);
      erro = e;
      const { tipoRetorno: tpretorno, statusCode: retStatus } = ApiBaseResponse(e, e.response);
      objRet.statusCode = retStatus;
      objRet.tipoRetorno = tpretorno;

      //TRATAMENTO DE ERRO DE API
      if (e.response && e.response?.data?.title) {
        if (e.response?.data?.errors) {
          if (Object.keys(e.response?.data?.errors).length > 1) {
            let errosArr: string[] = [];

            Object.keys(e.response?.data?.errors)?.forEach((key) => {
              e.response?.data?.errors[key]?.forEach((erro: string) => {
                errosArr.push(`${key}: ${erro}. `);
              });
            });

            erro = new Error(errosArr.join('').trim());
          } else {
            const firstError = Object.entries(e.response?.data?.errors)
            erro = new Error(firstError.length > 0 ? firstError[0].join(' ') : e.response?.data?.title);
          }
        } else {
          erro = new Error(e.response?.data?.title);
        }
      }

      switch (objRet.tipoRetorno) {
        case EnumRetornoApiBase.Local:
          let msg = erro?.message?.toString() || "";
          if (msg.indexOf("timeout") > -1 && msg.indexOf("exceeded") > -1) {
            erro = new Error(
              `O servidor demorou muito para processar a requisição (${(cfg?.timeout || defaultTimeout) / 1000
              }s).`
            );
          }
          else if (msg.toLowerCase().indexOf("network error") > -1) {
            erro = new Error(
              `Não foi possível conectar ao servidor. Verifique sua conexão com internet e tente novamente.` +
              (msg.length > 0 ? `Detalhe: ${msg}` : ``)
            );
          }
          break;
        case EnumRetornoApiBase.Api:
          if (retStatus === 401) {
            if (cfg?.enviarTokenUsuario) {
              // TRATAMENTO DO REFRESHTOKEN AUTOMÁTICO
              if (validaUsuarioConectado() && tentarNovamente) {
                const retRefresh = await refreshUser(userToken!.originalToken);
                if (retRefresh) {
                  return await invocarApi(cfg, options);
                }
                else {
                  erro = new Error(
                    `Ocorreu um problema ao executar novamente. Realize o login novamente.`
                  );
                  deslogar(userToken?.usuarioId || '');
                  history.push('/login/expirou');
                }

              } else {
                erro = new Error(
                  `Sua sessão expirou. Realize o login novamente para continuar. (401)`
                );
                deslogar(userToken?.usuarioId || '');
                history.push('/login/expirou');
              }

            }
          }
          if (retStatus === 402) {
            resultado = e.response
          }
          break;
        case EnumRetornoApiBase.Servidor:
          erro = new Error(
            `O procedimento solicitado causou um erro no servidor. Tente novamente em alguns instantes. Detalhe: ${erro?.message}`
          );
          break;
      }

      resultado = e.response
    }

    setCarregandoInterno(false);

    return {
      resultado,
      erro,
      tipoRetorno: objRet.tipoRetorno,
      statusCode: objRet.statusCode,
    };
  },
    [getRegistro, converterToken, isTokenExpired, invocarAxios, validaUsuarioConectado, refreshUser, deslogar, history]
  );

  return {
    invocarApi,
    carregando: carregandoInterno,
  };
}
