import { Box, Button, Grid } from "@material-ui/core";
import { CardInfoConfirmarEntrega } from "../../../../components/cards/card-info-confirmar-entrega/card-info-confirmar-entrega";
import { KdsStorageKeys, useKdsStorage, useSessaoAtual, useToastSaurus } from "../../../../../services/app";
import { ConfirmarEntregaModel } from "../../../../../model/app/forms/confirmar-entrega/confirmar-entrega-model";
import { useHistory } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";
import { useGetEmpresaConfig } from "../../../../../data/api/gestao/empresa/get-empresa-by-id";
import { ConfigEmpresaModel } from "../../../../../model/api/gestao/master/config-empresa-model";
import { EnumAssinatura } from "../../../../../model/enums/enum-assinatura";
import { CircularLoading } from "../../../../components";
import { usePutConfirmarRecebimento } from "../../../../../data/api/gestao/processo-produto-setor/put-confirmar-recebimento";
import { ConfirmarRecebimentoModel } from "../../../../../model/api/processo-produto-setor/confirmar-recebimento";
import { isEmpty } from "lodash";
import { useThemeQueries } from "../../../../theme";
import { VoltarIcon } from "../../../../components/icons";
import { useGetMesa } from "../../../../../data/api/gestao/mesa/get-mesa";
import { useGetSalao } from "../../../../../data/api/gestao/salao/get-salao";
import { useGetComanda } from "../../../../../data/api/gestao/comanda/get-comanda";
import { SalaoModel } from "../../../../../model/api/salao/salao-model";
import { MesasModel } from "../../../../../model/api/mesa/mesa-model";
import { ComandasModel } from "../../../../../model/api/comanda/comanda-model";
import { ConfiguracaoMesaEComanda } from "../../../../../model/api/Configuracao-mesa-comanda/configuracao-mesa-e-comanda";
import { EnumMesas } from "../../../../../model/enums/enum-mesas";
import { EnumStatusSalao } from "../../../../../model/enums/enum-status-salao";
import { EnumComandas } from "../../../../../model/enums/enum-comandas";
import { EnumTipoTrabalho } from "../../../../../model/enums/enum-tipo-trabalho";
import { ConfirmarIcon } from "../../../../components/icons/confirmar-icon";
import { ModuloModel } from "../../../../../model/app/forms/master/modulo-model";
import { EnumModulo } from "../../../../../model/enums/enum-modulo";

export const ConfirmarEntregaData = () => {

    const { getRegistro } = useKdsStorage();
    const history = useHistory();
    const { showToast } = useToastSaurus();
    const { isMobile } = useThemeQueries();
    const { setRegistro } = useKdsStorage();

    const [configMesaComanda, setConfigMesaComanda] = useState<ConfiguracaoMesaEComanda>(new ConfiguracaoMesaEComanda());

    const { getMesa, carregando: carregandoMesa } = useGetMesa();
    const { getSalao, carregando: carregandoSalao } = useGetSalao();
    const { getComanda, carregando: carregandoComanda } = useGetComanda();
    const { getEmpresaConfig, carregando: carregandoEmpresa } = useGetEmpresaConfig()
    const { getEmpresaSelecionada, carregando: carregandoSessaoAtual } = useSessaoAtual();
    const { putConfirmarRecebimento, carregando: carregandoAlterarProcesso } = usePutConfirmarRecebimento();

    const carregando = carregandoEmpresa || carregandoSessaoAtual || carregandoAlterarProcesso || carregandoMesa || carregandoSalao || carregandoComanda;

    const model = getRegistro(KdsStorageKeys.PedidoConfirmarEntrega, false) as ConfirmarEntregaModel;
    const tipoTrabalho = getRegistro(KdsStorageKeys.SelecaoModulo, false) as ModuloModel;

    const getComandasWrapper = useCallback(async () => {
        const res = await getComanda(getEmpresaSelecionada()?.Id!);

        if (res.erro)
            throw res.erro

        return res.resultado?.data.list as ComandasModel[]
    }, [getComanda, getEmpresaSelecionada])

    const getMesasWrapper = useCallback(async () => {
        const res = await getMesa(getEmpresaSelecionada()?.Id!);

        if (res.erro)
            throw res.erro

        return res.resultado?.data.list as MesasModel[]
    }, [getEmpresaSelecionada, getMesa])

    const getSalaoWrapper = useCallback(async () => {
        const res = await getSalao(getEmpresaSelecionada()?.Id!);

        if (res.erro)
            throw res.erro

        return res.resultado?.data.list as SalaoModel[]
    }, [getEmpresaSelecionada, getSalao])

    const getEmpresaByIdWrapper = useCallback(async () => {
        const res = await getEmpresaConfig(getEmpresaSelecionada()?.Id!);

        if (res.erro)
            throw res.erro

        return res.resultado?.data as ConfigEmpresaModel[]
    }, [getEmpresaConfig, getEmpresaSelecionada])

    const putConfirmarRecebimentoWrapper = useCallback(async () => {
        const processos = new ConfirmarRecebimentoModel();

        processos.ids = model.processosProdutosIds;
        processos.imagemDados = ''

        const proximoProcesso = model.processoAtual + 1;

        const res = await putConfirmarRecebimento(getEmpresaSelecionada()?.Id!, proximoProcesso, processos);

        if (res.erro)
            throw res.erro

        return showToast('success', 'Pedido foi entregue com sucesso.')
    }, [model.processosProdutosIds, model.processoAtual, putConfirmarRecebimento, getEmpresaSelecionada, showToast])

    const configuracaoMesaEComanda = useCallback(async () => {
        const saloes = await getSalaoWrapper();
        const comandas = await getComandasWrapper();
        const mesas = await getMesasWrapper();

        const saloesCadastrados = saloes.filter(x => x.status.codigo === EnumStatusSalao.ATIVO);
        const comandasCadastradas = comandas.filter(x => x.status.codigo === EnumComandas.ATIVO).length;

        if (tipoTrabalho.modulo === EnumModulo.EntregaDelivery) {
            return history.push('/assinatura')
        }

        const mesasCadastradas = mesas.filter(x => {
            if (saloesCadastrados.filter(y => y.id === x.salaoId).length > 0) {
                if (x.status.codigo === EnumMesas.ATIVO) {
                    return true
                }
            }
            return false;
        }).length;

        if (!isEmpty(model.codigoComanda) && comandasCadastradas === 0) {
            return showToast('error', 'Verifique se você possui uma comanda ativa ou cadastrada para poder prosseguir na finalização da entrega.')
        }

        const configuracoesMesaComanda = new ConfiguracaoMesaEComanda(
            undefined,
            saloesCadastrados.length,
            mesasCadastradas,
            comandasCadastradas,
        );

        if (comandasCadastradas > 0 && !isEmpty(model.codigoComanda)) {
            setConfigMesaComanda({
                ...configuracoesMesaComanda,
                tipoTrabalho: EnumTipoTrabalho.COMANDA
            })

            return history.push('/ler-comanda')
        }

        setConfigMesaComanda({
            ...configuracoesMesaComanda,
            tipoTrabalho: EnumTipoTrabalho.MESA
        })

        return history.push('/ler-mesa');
    }, [getComandasWrapper, getMesasWrapper, getSalaoWrapper, history, model.codigoComanda, showToast, tipoTrabalho.modulo])

    useEffect(() => {
        setRegistro(KdsStorageKeys.ConfiguracaoMesaEComanda, configMesaComanda, false);
    }, [configMesaComanda, setRegistro])

    const ConfirmarEntrega = useCallback(async () => {
        try {
            const configs = await getEmpresaByIdWrapper();

            const config = configs.filter(x => x.grupoConfig === '04-KDS')[0]

            if (Number(config.vConfig) === EnumAssinatura.PermiteAssinatura)
                return configuracaoMesaEComanda();

            await putConfirmarRecebimentoWrapper();
            return history.push('/pedidos-para-entrega')
        } catch (err: any) {
            showToast('error', err.message);
        }
    }, [configuracaoMesaEComanda, getEmpresaByIdWrapper, history, putConfirmarRecebimentoWrapper, showToast])

    return (
        <>
            {carregando && <CircularLoading tipo="FULLSIZED" />}
            <Grid container style={{
                padding: '16px',
                display: 'flex',
                justifyContent: 'center',
                height: '100%'
            }}>
                <Grid item xs={12} style={{
                    maxWidth: '600px',
                    height: '100%',
                    display: 'flex',
                    alignItems: 'center'
                }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} style={{
                            display: 'flex',
                            justifyContent: 'center'
                        }}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <CardInfoConfirmarEntrega
                                        model={model}
                                    />
                                </Grid>
                                {!isMobile && (
                                    <Grid item xs={6}>
                                        <Button
                                            variant="outlined"
                                            fullWidth
                                            color="primary"
                                            size="large"
                                            startIcon={<VoltarIcon tipo="BUTTON_LOGOUT" />}
                                            onClick={() => history.push('/pedidos-para-entrega')}
                                        >
                                            <Box fontWeight={700}>
                                                Voltar
                                            </Box>
                                        </Button>
                                    </Grid>
                                )}
                                <Grid item xs={isMobile ? 12 : 6}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        size="large"
                                        fullWidth
                                        startIcon={<ConfirmarIcon tipo="BUTTON_PRIMARY" />}
                                        onClick={ConfirmarEntrega}
                                    >
                                        <Box fontWeight={700}>
                                            Confirmar Entrega
                                        </Box>
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}