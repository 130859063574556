import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
   cardContainer: {
      padding: theme.spacing(1),
      boxShadow: '0px 0.5px 5px rgba(0, 0, 0, 0.25)',
   },
   cardDiv: {
      paddingLeft: '8px',
      paddingRight: '8px',
   },
   divBotaoETime: {
      display: 'flex',
      justifyContent: 'end',
      alignItems: 'end',
      width: '100%',
   },
   time: {
      maxWidth: '10px',
      color: '#545454',
      width: '70%',
      border: '1px solid #545454',
      height: '31px',
      display: 'flex',
      alignItems: 'center',
      marginLeft: '6px',
      borderRadius: '5px',
      justifyContent: 'center',
      // minha tela do notbook
      [theme.breakpoints.down("xl")]: {
         width: '70%',
      },
      //tela a parte
      [theme.breakpoints.down("lg")]: {
         width: '70%',
      },
      // telas menores
      [theme.breakpoints.down("md")]: {
         width: '50%',
         height: '31px',
         maxWidth: '10px',
      },
   },
   botao: {
      backgroundColor: '#EF8E47',
      color: '#fff',
      fontSize: '11px',
      fontWeight: 600,
      '&:hover': {
         backgroundColor: '#d89563'
      },
   },
   titleContainer: {
      display: 'flex',
      position: 'relative',
   },
   jss01: {
      fontSize: '10px',
      marginRight: '71px',
      marginTop: '7px'
   },
   titleCodigo: {
      color: '#545454',
      fontWeight: 400,
      fontSize: '12px',
      whiteSpace: 'nowrap',
   },
   labelTop: {
      flex: 1,
      display: 'flex',
      justifyContent: 'space-between'
   },
   dataLabel: {
      display: 'flex',
      justifyContent: 'flex-end'
   },
   labelDiv: {
      width: '100px',
      borderRadius: '7px',
      padding: '4px 0',
      [theme.breakpoints.down("xl")]: {
         width: '90px',
      },
      [theme.breakpoints.down("lg")]: {
         width: '90px',
      },
      [theme.breakpoints.down("md")]: {
         width: '100px',
      },
   },
   label: {
      color: '#FFFFFF',
      fontSize: '11px',
      textAlign: 'center',
      fontWeight: 700,
      [theme.breakpoints.down("xl")]: {
         fontSize: '10px',
      },
      [theme.breakpoints.down("lg")]: {
         fontSize: '10px'
      },
      [theme.breakpoints.down("md")]: {
         fontSize: '11px'
      },
   },
   subTitleNome: {
      color: '#808080',
      fontWeight: 600,
      fontSize: '15px',
   },
   cancelado: {
      width: '100%',
      maxWidth: 150,
      backgroundColor: '#4F4F4F'
   },

   divBotao: {
      margin: '11px 0 0 8px',
      display: 'flex',
      maxWidth: '37px',
      maxHeight: '37px',
      [theme.breakpoints.down("xl")]: {
         margin: '11px 0 0 8px',
      },
      [theme.breakpoints.down("lg")]: {
         margin: '11px 0 0 8px',
      },
      [theme.breakpoints.down("md")]: {
         margin: '11px 0 0 10px',
      },
   },
   botaoFinalizar: {
      backgroundColor: '#FFAA6C',
      width: '100%',
      height: '100%',
      borderRadius: '8px',
      textDecoration: 'line-through',
      '&:hover': {
         backgroundColor: '#333'
      },
   },
   divider: {
      backgroundColor: '#E6E6E6',
      marginTop: 4,
      width: "100%",
      height: '1px'
   },
   insertDate: {
      fontWeight: 600,
      color: theme.palette.primary.main
   }
}));