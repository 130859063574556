import { useCallback } from "react";
import { VariaveisAmbiente } from "../../../../config";
import { useApiBase } from "../../base/api-base";

export function useGetPedido() {
    const { invocarApi, ...outrasPropriedades } = useApiBase();

    const getPedido = useCallback(
        (empresaId: string, query?: string) => {
        return invocarApi({
            url: `/${VariaveisAmbiente.pedidosVersion}/empresa/${empresaId}/pedido-salao`,
            method: "GET",
            baseURL: `${VariaveisAmbiente.apiPedidosUrl}/api`,
            enviarTokenUsuario: true,
        });
    },
        [invocarApi]
    );

    return {
        ...outrasPropriedades,
        getPedido,
    };
}