import { Box, Button, Card, Checkbox, Divider, Fade, Grid, Tooltip, Typography } from "@material-ui/core"
import { isEmpty } from "lodash";
import { useCallback, useMemo } from "react";
import { usePutAlterarProcesso } from "../../../../data/api/gestao/processo-produto-setor/put-processo-produto-setor";
import { ProcessoProdutoSetorModel } from "../../../../model/api/processo-produto-setor/processo-produto-setor-model";
import { CardItemPedido } from "../card-item-pedido";
import { useStyles } from "./card-pedido-styles";
import { CircularLoading } from "../../utils";
import { Time } from "./components/timer/time";
import { EnumStatusPedido } from "../../../../model/enums/enum-status-pedido";
import { useSessaoAtual } from "../../../../services/app/providers/sessao-atual-provider";
import { useThemeQueries } from "../../../theme";
import { AdicionarRemoverProcessos } from "../../../../model/app/forms/adicionar-remover-processos/adicionar-remover-processos";
import { toDateString } from "../../../../utils/to-date";
import { PedidoEnderecoModel, PedidoModel } from "../../../../model/api/pedidos/pedido-model";
import { pegaHora } from "../../../../utils/pegar-data";

type Props = {
   pesquisa: string;
   filtroPesquisa: ProcessoProdutoSetorModel[];
   modelProdutos: ProcessoProdutoSetorModel[],
   processosSelecionados?: AdicionarRemoverProcessos[],
   pedido?: PedidoModel;
   onClickAtt: () => Promise<void>,
   setProcessosSelecionados: React.Dispatch<React.SetStateAction<AdicionarRemoverProcessos[]>>
}
export const CardPedido = ({
   pesquisa,
   filtroPesquisa,
   modelProdutos,
   pedido,
   processosSelecionados,
   onClickAtt,
   setProcessosSelecionados
}: Props) => {

   //AUX
   const classes = useStyles();
   const { theme } = useThemeQueries()

   const model = modelProdutos[0] ?? new ProcessoProdutoSetorModel()

   //PROVIDERS
   const { getEmpresaSelecionada } = useSessaoAtual();

   //CHAMADAS DA API
   const { putAlterarProcesso, carregando: carregandoPutProcesso } = usePutAlterarProcesso();

   const expiryTimestamp = useCallback(() => new Date(model?.systemInsertDate), [model?.systemInsertDate])
   expiryTimestamp().setSeconds(expiryTimestamp().getSeconds() + model?.minutosDePreparo * 60)

   const isCancelado = model.status.codigo === EnumStatusPedido.SolicitacaoCancelamentoAtendimento || model.status.codigo === EnumStatusPedido.Cancelado

   const isConcluido = model.status.codigo === EnumStatusPedido.Finalizado

   const alterarCorStatus = useCallback(() => {
      const horaAgora = new Date()
      if (isCancelado) {
         return theme.palette.error.main
      }
      if (horaAgora > expiryTimestamp()) {
         if (isConcluido) {
            return theme.palette.success.main
         } else if (model.minutosDePreparo !== null) {
            return theme.palette.primary.main
         }
      } else if (horaAgora < expiryTimestamp()) {
         if (isConcluido) {
            return theme.palette.success.main
         }
         return theme.palette.primary.main
      }
   }, [expiryTimestamp, isCancelado, isConcluido, model.minutosDePreparo, theme.palette.error.main, theme.palette.primary.main, theme.palette.success.main])

   const alterarTextoStatusSemMinutos = useCallback(() => {
      //Se o status for igual a 1 (Aguardando inicio)
      if (model.status.codigo === 1) {
         return 'Aguardando'
      }
      else if (model.status.codigo === 2) {
         return "Produção"
      }
      else if (model.status.codigo === 9) {
         return "Retirada"
      }
      else if (model.status.codigo === 10) {
         return "Entrega"
      }
      else if (isConcluido) {
         return "Concluído"
      }

      else if (isCancelado) {
         return "Cancelado"
      }
   }, [isCancelado, isConcluido, model.status.codigo])

   const alterarCorStatusSemMinutos = useCallback(() => {
      //Se o status for igual a 1 (PRODUÇÃO)
      if (model.status.codigo === 1) {
         return theme.palette.primary.main
      }
      else if (model.status.codigo === 2) {
         return theme.palette.primary.main
      }
      else if (model.status.codigo === 9) {
         return theme.palette.warning.main
      }
      else if (model.status.codigo === 10) {
         return theme.palette.success.main
      }
      else if (isConcluido) {
         return theme.palette.success.main
      }
      else if (isCancelado) {
         return theme.palette.error.main
      }
   }, [isCancelado, isConcluido, model.status.codigo, theme.palette.error.main, theme.palette.primary.main, theme.palette.success.main, theme.palette.warning.main])

   const alterarTextoStatusTodosSemMinutos = useCallback(() => {
      if (model.status.codigo === EnumStatusPedido.AguardandoInicio) {
         return "Avançar Todos"
      }
      else if (model.status.codigo === EnumStatusPedido.EmProducao) {
         return "Avançar Todos"
      }
      else if (isConcluido) {
         return "Concluído"
      }
      else if (model.status.codigo === EnumStatusPedido.SolicitacaoCancelamentoAtendimento) {
         return "Confirmar Cancelamento"
      }
   }, [isConcluido, model.status.codigo])

   const alterarTextoStatusTodos = useCallback(() => {
      const horaAgora = new Date()
      if (horaAgora < expiryTimestamp()) {
         if (isConcluido) {
            return 'Finalizado'
         } if (isCancelado) {
            return "Confirmar Cancelamento"
         }
         return "Produção"
      } else if (horaAgora === null) {
         if (isConcluido) {
            return 'Finalizado'
         } if (isCancelado) {
            return "Confirmar Cancelamento"
         }
         return "Produção"
      }

   }, [expiryTimestamp, isCancelado, isConcluido])

   const estaCancelado = useCallback(
      async (id: string[]) => {
         try {
            const ret = await putAlterarProcesso(id, getEmpresaSelecionada()?.Id || '', 9999)
            if (ret.erro) {
               throw ret.erro
            }
            await onClickAtt()
         } catch (e: any) {
            new ProcessoProdutoSetorModel()
         }
      }, [getEmpresaSelecionada, onClickAtt, putAlterarProcesso])

   const proximoWrapper = useCallback(
      async (id: string[]) => {
         try {
            let ret: any = ''
            ret = await putAlterarProcesso(id, getEmpresaSelecionada()?.Id || '', model.processoProducaoAtual + 1)

            if (ret.erro) {
               throw ret.erro
            }
            await onClickAtt()
         } catch (e: any) {
            new ProcessoProdutoSetorModel()
         }
      }, [getEmpresaSelecionada, model?.processoProducaoAtual, onClickAtt, putAlterarProcesso])

   const anteriorWrapper = useCallback(
      async (id: string[]) => {
         try {
            let ret: any = ''
            ret = await putAlterarProcesso(id, getEmpresaSelecionada()?.Id || '', model.processoProducaoAtual - 1)

            if (ret.erro) {
               throw ret.erro
            }
            await onClickAtt()
         } catch (e: any) {
            new ProcessoProdutoSetorModel()
         }
      }, [getEmpresaSelecionada, model?.processoProducaoAtual, onClickAtt, putAlterarProcesso])

   const agruparProdutosSubitens = () => {
      return modelProdutos.map(x => {

         if (x.produto?.subItens && x.produto.subItens.length > 0) {
            const inclusos = x.produto.subItens.filter(subitem => isEmpty(subitem.adicionalId));
            const adicionais = x.produto.subItens.filter(subitem => !isEmpty(subitem.adicionalId))
            return {
               ...x,
               produto: {
                  ...x.produto,
                  adicionais: adicionais,
                  inclusos: inclusos,
               }
            }
         }

         return {
            ...x,
            produto: {
               ...x.produto,
               adicionais: [],
               inclusos: [],
            }
         }
      })
   }

   const produtos = agruparProdutosSubitens()

   const adicionarRemoverProcessos = useCallback(() => {

      const selecionados = processosSelecionados?.filter(x => produtos.filter(y => y.id === x.produtoId));
      const isProdsSelecionados = selecionados?.filter(x => {
         return produtos.some(y => y.id === x.produtoId);
      });
      const isProdsDiferentesSelecionados = selecionados?.filter(x => {
         return produtos.every(y => y.id !== x.produtoId);
      });


      if (isEmpty(selecionados)) {
         const select = [...produtos.map(x => ({
            produtoId: x.id,
            pedidoId: x.pedidoId,
            groupId: x.produto.groupId,
            codigoReferencia: x.produto.codigoReferencia,
         }))]

         return setProcessosSelecionados(prev => [...prev, ...select]);
      }
      else if (isProdsSelecionados?.length! > 0) {
         return setProcessosSelecionados(isProdsDiferentesSelecionados!);
      }
      else {
         const select = [...produtos.map(x => ({
            produtoId: x.id,
            pedidoId: x.pedidoId,
            groupId: x.produto.groupId,
            codigoReferencia: x.produto.codigoReferencia,
         }))]

         return setProcessosSelecionados(prev => [...prev, ...select]);
      }

   }, [processosSelecionados, produtos, setProcessosSelecionados])

   const retornaEndereco = useMemo(() => {
      const { bairro, municipio, numero, complemento, logradouro, uf } = pedido?.enderecoEntrega ?? new PedidoEnderecoModel()

      if (isEmpty(pedido?.enderecoEntrega)) {
         return ''
      }

      return `${logradouro ?? ''}, ${numero ?? 0} - ${bairro ?? ''}${municipio ? `, ${municipio}` : ''}${uf ? ` - ${uf}` : ''}${complemento ? `, ${complemento}` : ''}`
   }, [pedido?.enderecoEntrega])

   return (
      <>
         {(!isEmpty(pesquisa) ? filtroPesquisa.length > 0 : isEmpty(filtroPesquisa)) && (
            <>
               {carregandoPutProcesso && <CircularLoading tipo="FULLSIZED" />}
               <Fade in>
                  <Card className={classes.cardContainer}>
                     <Grid container spacing={2}>
                        <Grid item xs={12}>
                           <Grid container spacing={1} className={classes.labelTop} style={{ marginBottom: '0px' }}>
                              <Grid item xs={7}>
                                 <Box display={"flex"} flexWrap="wrap" alignItems={"center"}>
                                    {!isEmpty(model.mesaCodigo) && (
                                       <Typography
                                          variant="body2"
                                          color="textPrimary"
                                       >
                                          {`Mesa ${model.mesaCodigo}`}
                                       </Typography>
                                    )}
                                    {!isEmpty(model.mesaCodigo) && !isEmpty(model.comandaCodigo) && (
                                       <Typography
                                          variant="body2"
                                          color="textPrimary"
                                          style={{
                                             paddingLeft: '8px',
                                             paddingRight: '8px'
                                          }}
                                       >
                                          /
                                       </Typography>
                                    )}
                                    {!isEmpty(model.comandaCodigo) && (
                                       <Typography
                                          variant="body2"
                                          color="textPrimary"
                                       >
                                          {`Comanda ${model.comandaCodigo}`}
                                       </Typography>
                                    )}
                                 </Box>
                              </Grid>
                              <Grid item xs={5}>
                                 <Box display={"flex"} alignItems="center" justifyContent={"flex-end"}>
                                    <Tooltip title="Data de Inserção" placement="top" arrow>
                                       <Box paddingX={"6px"}>
                                          <Typography
                                             variant="caption"
                                             className={classes.insertDate}
                                             style={{
                                                color: (model.minutosDePreparo !== 0) ?
                                                   alterarCorStatus() : alterarCorStatusSemMinutos()
                                             }}
                                          >
                                             {toDateString(new Date(model.systemInsertDate))} - {pegaHora(model.systemInsertDate)}
                                          </Typography>
                                       </Box>
                                    </Tooltip>
                                 </Box>
                              </Grid>
                              <Grid item xs={7} className={classes.labelDiv} >
                                 <Typography
                                    variant="body1"
                                    color="textPrimary"
                                    style={{
                                       display: 'flex',
                                       alignItems: 'center'
                                    }}
                                 >
                                    <Box fontWeight={600}>
                                       {!isEmpty(model.cliente) ? model.cliente : 'Consumidor'}
                                    </Box>
                                 </Typography>
                              </Grid>
                              <Grid item xs={5} style={{
                                 display: 'flex',
                                 justifyContent: 'flex-end',
                                 alignItems: 'center'
                              }}>
                                 <Box fontWeight={700}>
                                    <Typography
                                       variant="caption"
                                       style={{
                                          color: '#FFF',
                                          borderRadius: '5px',
                                          textAlign: 'center',
                                          padding: '4px',
                                          backgroundColor: (model.minutosDePreparo !== 0) ?
                                             alterarCorStatus() : alterarCorStatusSemMinutos()
                                       }}
                                    >
                                       {alterarTextoStatusSemMinutos()}
                                    </Typography>
                                 </Box>
                              </Grid>

                              {!isEmpty(pedido?.enderecoEntrega?.logradouro) &&
                                 <Grid item xs={12}>
                                    <Typography
                                       variant="body1"
                                       color="textPrimary"
                                    >
                                       {retornaEndereco}
                                    </Typography>
                                 </Grid>
                              }

                              <Grid item xs={12}>
                                 <Divider
                                    variant="fullWidth"
                                    style={{
                                       background: theme.palette.grey[300]
                                    }}
                                 />
                              </Grid>
                           </Grid>
                        </Grid>

                        <Grid item xs={12}>
                           {produtos.map(item => {
                              return (
                                 <>
                                    <Fade in>
                                       <CardItemPedido
                                          model={item}
                                          proximo={async (valor) => await proximoWrapper(valor)}
                                          anterior={async (valor) => await anteriorWrapper(valor)}
                                          estaCancelado={async (valor) => await estaCancelado(valor)}
                                          processosSelecionados={processosSelecionados!}
                                          setProcessosSelecionados={setProcessosSelecionados}
                                       />
                                    </Fade>
                                 </>
                              )
                           })}
                        </Grid>

                        <Grid item xs={12}>
                           {(model.status.codigo < EnumStatusPedido.Finalizado) ? (
                              <>
                                 <Grid container spacing={1} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                    {modelProdutos.length > 1 && (
                                       <Grid item xs={8}>
                                          <Button
                                             style={{ backgroundColor: alterarCorStatus() }}
                                             onClick={async () => await proximoWrapper(modelProdutos.map(item => item.id))}
                                             variant="contained"
                                             color="primary"
                                             size="medium"
                                             fullWidth
                                          >
                                             {!isEmpty(model.minutosDePreparo !== 0) ? alterarTextoStatusTodos() : alterarTextoStatusTodosSemMinutos()}
                                          </Button>
                                       </Grid>
                                    )}

                                    <Grid item xs={4}>
                                       <Time
                                          inserted={new Date(model.systemUpdateDate)}
                                          range={model.minutosDePreparo}
                                       />
                                    </Grid>
                                 </Grid>
                              </>
                           ) : (model.status.codigo === EnumStatusPedido.SolicitacaoCancelamentoAtendimento) && (
                              <>
                                 <Button
                                    style={{ backgroundColor: alterarCorStatus() }}
                                    onClick={async () => await estaCancelado(modelProdutos.map(item => item.id))}
                                    variant="contained"
                                    color="primary"
                                    fullWidth
                                    size="medium"
                                 >
                                    {!isEmpty(model.minutosDePreparo !== 0) ? alterarTextoStatusTodos() : alterarTextoStatusTodosSemMinutos()}
                                 </Button>
                              </>
                           )}

                           {model.status.codigo === EnumStatusPedido.AguardandoRetirada && (
                              <Grid item xs={12} style={{
                                 display: 'flex',
                                 justifyContent: 'flex-end',
                                 paddingRight: '8px'
                              }}>
                                 <Button
                                    variant="outlined"
                                    size="small"
                                    onClick={adicionarRemoverProcessos}
                                 >
                                    <Checkbox
                                       color="primary"
                                       checked={(() => {
                                          const produtosSize = produtos.length
                                          if (produtosSize < 1) return false
                                          const processosPedido = processosSelecionados?.filter(x => produtos[0] && x.pedidoId === produtos[0].pedidoId)

                                          return processosPedido && produtosSize <= processosPedido.length
                                       })()}
                                    />
                                    Selecionar Todos
                                 </Button>
                              </Grid>
                           )}
                        </Grid>
                     </Grid>
                  </Card>
               </Fade>
            </>
         )}
      </>
   )
}