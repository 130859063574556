import { DefaultIconProps } from './default-icon/default-icon-props';
import { DefaultIcon } from './default-icon/default-icon';
export const ArrowDownIcon = (props: DefaultIconProps) => {
  return (
    <>
      <DefaultIcon
        tipo={props.tipo}
        class={props.class}
        style={props.style}
        fill={props.fill}
      >
        <path d="M14.5833 20.8333L25 31.25L35.4167 20.8333H14.5833Z" />
      </DefaultIcon>
    </>
  );
};
