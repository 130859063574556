import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useRef } from 'react';
import { useStyles } from './assinatura-styles';
import SignaturePad from "react-signature-canvas";
import { AssinaturaProps } from './assinatura-props';
import SignatureCanvas from "react-signature-canvas";

export const Assinatura = forwardRef((props: AssinaturaProps, ref) => {

    const classes = useStyles();

    const innerRef = useRef<SignatureCanvas>(null);

    const clear = useCallback(() => {
        innerRef.current?.clear();
    }, [])

    useImperativeHandle(ref, () => ({
        getValue: () => {
            return innerRef?.current;
        },
        clear: clear
    }));

    const trim = useCallback(() => {
        const url = innerRef?.current?.getTrimmedCanvas().toDataURL("image/jpg");
        if (url) props.setDataUrl(url);
    }, [props])

    useEffect(() => {
        trim();
    })

    return (
        <>
            <div style={{
                height: '100%'
            }}>
                <SignaturePad ref={innerRef} canvasProps={{ className: classes.sigCanvas }} />
            </div>
        </>
    );
})