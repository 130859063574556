import { useRecoilValue, useSetRecoilState } from "recoil";
import { useGlobalStates } from "../../providers/global-states-provider/global-states-provider";

export function useGlobalStateTools() {
    const { menuGlobalState } = useGlobalStates();
    const changeMenuState = useSetRecoilState(menuGlobalState!);
    const getValue = useRecoilValue;

    return {
        changeMenuState,
        getValue,
    };
}