import { AppBar, CssBaseline, IconButton, Toolbar, Typography } from "@material-ui/core"
import { useStyles } from "./navbar-styles";
import { useRef, useState } from "react";
import { CardLogout } from "../cards/card-logout";
import { CardLogoutRef } from "../cards/card-logout/card-logout";
import { useKdsToken } from "../../../services/app";
import { PublicPageHeader } from "../headers/header-public-page/header-public-page";
import clsx from 'clsx';
import MenuIcon from '@material-ui/icons/Menu';
import { useMenuPrincipal } from "../../../services/app/providers/menu-principal-provider";
import { VoltarIcon } from "../icons";

export type NavbarProps = {
  titulo?: string,
  mobile?: boolean,
  voltar?: () => void;
}

export const Navbar = ({ titulo, mobile, voltar }: NavbarProps) => {
  const classes = useStyles();
  const { getTokenFromStorage } = useKdsToken();
  const { abrirMenu } = useMenuPrincipal()
  const cardlogoutRef = useRef<CardLogoutRef>(null);
  const [open] = useState(false);
  
  return (
    <>
      <PublicPageHeader topOpacity="0.8" />
      <CardLogout ref={cardlogoutRef} />
      <div>
        <CssBaseline />
        <AppBar position="relative" className={clsx(classes.appBar, { [classes.appBarShift]: open, })}
        >
          <Toolbar>
            <IconButton
              aria-label="menu"
              color="inherit"
              onClick={mobile ? voltar : abrirMenu}
              edge="start"
              className={clsx(classes.menuButton, open && classes.hide)}
            >
              {mobile ? (<VoltarIcon tipo="BUTTON_PRIMARY" />) : (<MenuIcon />)} 
            </IconButton>
            <Typography variant="h6" noWrap>
              {titulo && titulo.length > 0 ? titulo : getTokenFromStorage()?.saudacao}
            </Typography>
          </Toolbar>
        </AppBar>
      </div>
      <div>
      </div>
    </>


  );
};

