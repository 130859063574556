import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    card: {
        backgroundColor: '#FFFFFF',
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
        width: '100%',
        height: '100%',
        background: '#FFFFFF',
        border: '2px dashed #C5C5C5',
        boxShadow: '0px 0px 10.6406px rgba(0, 0, 0, 0.08)',
        borderRadius: '20px',
    },
    divRoot: {
        height: '100%',
        width: '100%',
        display: 'flex',
        position: 'fixed',
        flexDirection: 'column'
    },
    root: {
        height: '100%',
        display: 'flex'
    },
    label: {
        fontStyle: 'normal',
        fontWeight: 500,
        fontFamily: 'Montserrat',
        fontSize: '30px',
        lineHeight: '40px',
        textAlign: 'center'
    },
    botao: {
        display: 'flex',
        fontSize: '15px',
        margin: 'auto',
    }
}));