import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    buttonKeyboardBackspaceContainer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        width: "100%",
        cursor: "pointer",
        outline: "none",
        userSelect: "none",

        "& *": {
            outline: "none",
            userSelect: "none",
            width: "10vw",
            [theme.breakpoints.up("md")]: {
                width: "5vw",
            },
        },
    },
}));