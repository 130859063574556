import { Tooltip, Typography } from '@material-ui/core';
import { DefaultCard, useDefaultCardStyles } from '../components/default-card';
import { useStyles } from './card-empresa-styles'
import { CardEmpresaConfigProps } from './card-empresa-config-props';
import { useThemeQueries } from '../../../theme';
import { AvancarIcon, EditarIcon, EmpresaIcon } from '../../icons';
import { CaracteresString } from '../../../../utils/caracteres-string';
import { formatarCPFCNPJ } from '../../../../utils/cpfcnpj-format'

export const CardEmpresaConfig = ({
    model,
    onClick,
    onCheck,
    selected,
    edicao
}: CardEmpresaConfigProps) => {
    const classes = useDefaultCardStyles();
    const classesCard = useStyles()
    const { xs } = useThemeQueries()

    return (
        <div className={classesCard.shadow}>
            <DefaultCard
                isSelected={selected}
                onClick={() => {
                    onClick(model.Id);
                }}
            >
                <div className={classes.cardContent}>
                    <div className={classes.celulaGrid}>
                        <EmpresaIcon tipo='BUTTON' />
                    </div>
                    <div className={classes.celulaGridFull}>
                        <Typography className={`celula-grid-value, ${classesCard.titleCard}`} color="primary">
                            {xs ? CaracteresString(model.Descricao, 26) : model.Descricao }
                        </Typography>
                        {formatarCPFCNPJ(model.Documento)}
                    </div>
                </div>
                <Tooltip arrow title="Selecionar empresa">
                    <div className={classesCard.cardRightButton}>
                        {!edicao ? (
                            <AvancarIcon tipo="BUTTON" />
                        ) : (
                            <EditarIcon tipo="BUTTON" />
                        )}
                    </div>
                </Tooltip>
            </DefaultCard>
        </div>
    );
};
