import { guidEmpty } from "../../../utils/guid-empty";
import { SalaoModel } from "../salao/salao-model";
import { SetorModel } from "../setor/setor-model";
import { SetorProcessoModel } from "./setor-processo/setor-processo-model";

export class ProcessoSetorModel {
   constructor(
      public id: string = guidEmpty(),
      public minutosDePreparo: number = 0,
      public setor: SetorModel = new SetorModel(),
      public salao: SalaoModel = new SalaoModel(),
      public processos: SetorProcessoModel[] = [],
   ) { }
}