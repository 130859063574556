import { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { SelecionarEmpresaListData } from "./selecionar-empresa-list-data";
import { PesquisaSelecionarEmpresa } from "../pesquisa-selecionar-empresa/pesquisa-selecionar-empresa";
import { ListaEmpresasListSearchProps } from "../../../lista-empresas/components/lista-empresas-list/lista-empresas-list-search-props";
import { Grid } from "@material-ui/core";
import { useStyles } from './selecionar-empresa-styles'
import { KdsStorageKeys, useKdsStorage, useKdsToken, useSessaoAtual } from "../../../../../../services/app";
import { CircularLoading } from "../../../../../components";
import { useGetEmpresas } from "../../../../../../data/api/gestao/empresa/get-empresas";

export const SelecionarEmpresaList = () => {

    const { carregando } = useGetEmpresas()
    const { selecionarEmpresa, usuario } = useSessaoAtual()
    const [selectedList, setSelectedList] = useState<Array<string>>([])
    const history = useHistory()
    const [searchProps, setSearchProps] = useState<ListaEmpresasListSearchProps>({
        termo: '',
    });
    const classes = useStyles()
    const { convertToken } = useKdsToken();
    const { getRegistro } = useKdsStorage()

    const temMultiplasEmpresas = useCallback(
        () => {
            let empresaAtual = getRegistro(KdsStorageKeys.EmpresaAtual, false)
            let token = convertToken(getRegistro(KdsStorageKeys.Token, false));

            let mesmaEmpresa = token?.empresa.find(empresas => empresas.Id === empresaAtual)
            if (mesmaEmpresa?.Id.length === 1) {
                return history.push('/');
            }

            if (!token)
                return false;
            else if (token?.empresa.length! > 1)
                return true;
            else return false

        }, [convertToken, getRegistro, history])

    useEffect(() => {
        if (!temMultiplasEmpresas())
            history.push('/')
        else return history.push('/selecionar-empresa');
    }, [history, temMultiplasEmpresas])


    const onCardSelected = async (id: string) => {
        await selecionarEmpresa(id)
        history.push('/')
    };

    const onCardChecked = (id: string) => {
        const aux = [...selectedList];
        aux.push(id);
        setSelectedList(aux);
    };

    const onPesquisar = useCallback(
        (props: ListaEmpresasListSearchProps) => {
            setSearchProps(props);
        },
        [setSearchProps]
    );

    // eslint-disable-next-line array-callback-return
    const pesquisa = usuario!.empresa.filter(item => {
        if (searchProps.termo === '') return item
        if (
            item.Descricao.toLowerCase().includes(searchProps.termo.toLowerCase()) ||
            item.Documento.toLowerCase().includes(searchProps.termo.toLowerCase())
        ) {
            return item
        }
    })

    return (
        <>
            {carregando && <CircularLoading tipo="FULLSIZED" />}
            <PesquisaSelecionarEmpresa onPesquisar={onPesquisar} />
            <Grid className={classes.listContainer}>
                <SelecionarEmpresaListData
                    list={pesquisa}
                    carregando={carregando}
                    onCardChecked={onCardChecked}
                    onCardSelected={onCardSelected}
                    selectedList={selectedList}
                />
            </Grid>
        </>
    )
}