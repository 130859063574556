import { DefaultIconProps } from "./default-icon/default-icon-props";
import { DefaultIcon } from "./default-icon/default-icon"

export const ConfirmarIcon = (props: DefaultIconProps) => {
  return (
    <>
      <DefaultIcon tipo={props.tipo} class={props.class} style={props.style} fill={props.fill}>
        <path d="M26.083 1C12.1988 1 1 12.1573 1 26.0138C1 39.8012 12.1988 50.9861 26.0415 51C39.7458 51.0138 50.9723 39.8012 51 26.1107C51.0138 12.268 39.8427 1.01384 26.083 1ZM25.9031 48.8267C13.4308 48.7852 3.14562 38.5138 3.15947 25.9585C3.18715 13.4169 13.5277 3.13178 26.0692 3.17331C38.5415 3.21484 48.9236 13.4862 48.7851 26.0415C48.882 38.5692 38.4861 48.882 25.9031 48.8267Z" stroke-width="0.5" />
        <path d="M24.8787 31.9524C28.0902 27.2182 31.2741 22.5255 34.4579 17.8466C34.9009 17.196 35.3438 16.5316 35.7868 15.881C36.3405 15.0781 36.8665 14.8981 37.4618 15.3134C38.057 15.7287 38.0985 16.3101 37.5725 17.0853C33.9734 22.3871 30.3743 27.7027 26.789 33.0045C26.5675 33.3367 26.346 33.6551 26.1246 33.9873C25.2525 35.2885 25.2525 35.2747 24.1312 34.2365C21.3488 31.6617 18.5803 29.087 15.7979 26.4984C15.4518 26.18 15.0504 25.8754 15.1057 25.3079C15.1473 24.8788 15.3688 24.5742 15.7563 24.3943C16.2685 24.1589 16.6838 24.3666 17.0576 24.7126C18.8848 26.4015 20.712 28.1041 22.5254 29.7929C23.3145 30.5128 24.0758 31.2187 24.8787 31.9524Z" stroke-width="0.5" />
      </DefaultIcon>
    </>
  );
};
