import { useCallback } from "react";

export enum KdsStorageKeys {
  Token,
  TermosDeUso,
  Plano,
  EmpresaAtual,
  EmpresaAnterior,
  PerfisDoUsuario,
  UsuariosConectados,
  ConfiguracoesMenu,
  IsEmpresaFiscal,
  UltimoLogin,
  ProcessosSetores,
  Setores,
  Produto,
  Pedido,
  PendenciaContrato,
  DocumentoContrato, 
  SelecaoModulo,
  PedidoConfirmarEntrega,
  ConfiguracaoMesaEComanda,
}

export function useKdsStorage() {

  const getRegistro = useCallback(
    (key: KdsStorageKeys, session: boolean = true) =>
      JSON.parse(
        (session
          ? sessionStorage.getItem(KdsStorageKeys[key].toString())
          : localStorage.getItem(KdsStorageKeys[key].toString())) || "{}"
      ),
    []
  );

  const exists = useCallback((key: KdsStorageKeys, session: boolean = true) => {
    if (session) return Boolean(sessionStorage.getItem(KdsStorageKeys[key].toString()));
    else return Boolean(localStorage.getItem(KdsStorageKeys[key].toString()));
  }, []);

  const setRegistro = useCallback((key: KdsStorageKeys, obj: any, session: boolean = true) => {
    if (session) {
      sessionStorage.setItem(KdsStorageKeys[key].toString(), JSON.stringify(obj));
    } else {
      localStorage.setItem(KdsStorageKeys[key].toString(), JSON.stringify(obj));
    }
  }, []);

  const delRegistro = useCallback((key: KdsStorageKeys, session: boolean = true) => {
    if (session) {
      sessionStorage.removeItem(KdsStorageKeys[key].toString());
    } else {
      localStorage.removeItem(KdsStorageKeys[key].toString());
    }
  }, []);
  return {
    getRegistro,
    setRegistro,
    delRegistro,
    exists,
  };
}
