import { PublicPageHeader } from "../../../components/headers/header-public-page/header-public-page"
import { Navbar } from "../../../components/nav"
import { useStyles } from "./leitor-comanda-page-styles";
import { KeyboardProvider } from "../../../../services/app/providers/keyboard-provider/keyboard-provider";
import { useCallback, useMemo, useRef } from "react";
import { KdsStorageKeys, useKdsStorage, useSessaoAtual, useToastSaurus } from "../../../../services/app";
import { CircularLoading } from "../../../components";
import { useHistory } from "react-router-dom";
import { useGetComanda } from "../../../../data/api/gestao/comanda/get-comanda";
import { ComandasModel } from "../../../../model/api/comanda/comanda-model";
import LeitorComanda from "./leitor-comanda-fragment/leitor-comanda-fragment";
import { useThemeQueries } from "../../../theme";
import { isEmpty } from "lodash";
import { ConfirmarEntregaModel } from "../../../../model/app/forms/confirmar-entrega/confirmar-entrega-model";

const LeitorComandaPage = () => {
    const classes = useStyles();
    const { showToast } = useToastSaurus();
    const history = useHistory();
    const { isMobile } = useThemeQueries();
    const { getRegistro } = useKdsStorage();

    const confirmarPedido = getRegistro(KdsStorageKeys.PedidoConfirmarEntrega, false) as ConfirmarEntregaModel;

    // REQUISIÇÕES
    const { getComanda, carregando: carregandoComandas } = useGetComanda();

    const { getEmpresaSelecionada } = useSessaoAtual();

    //STATES, REFS E PARAMS
    const codigo = useRef<string>('');

    const carregando = carregandoComandas;

    const textChanged = useCallback(
        async (text: string, formattedText: string) => {
            codigo.current = text;
            return true;
        },
        [],
    );

    const submit = useCallback(async () => {
        return false;
    }, []);

    const add = useCallback(async () => {
        return true;
    }, []);

    const getComandaWrapper = useCallback(async () => {
        const comandas = await getComanda(getEmpresaSelecionada()?.Id!);

        if (comandas.erro)
            throw comandas.erro

        return comandas.resultado?.data?.list as Array<ComandasModel>;
    }, [getComanda, getEmpresaSelecionada])

    const redirecionar = useCallback(async () => {
        try {
            const codigoComanda = codigo.current;
            const comandas = await getComandaWrapper();
            const comandaFiltrada = comandas.filter(x => x.codigoComanda === confirmarPedido.codigoComanda);

            if (isEmpty(comandaFiltrada))
                return showToast('error', 'Nenhuma comanda identificada. Verifique se é uma comanda válida.')

            const comandaPedido = comandaFiltrada.filter(x => x.codigoComanda === codigoComanda);

            if (isEmpty(comandaPedido))
                return showToast('error', 'Está comanda não existe neste pedido.')

            return history.push('/assinatura');
        } catch (err: any) {
            showToast('error', err.message)
        }
    }, [confirmarPedido.codigoComanda, getComandaWrapper, history, showToast])

    const fragmento = useMemo(() => {
        return <LeitorComanda handleConfime={() => redirecionar()} />;
    }, [redirecionar]);

    const voltarPagina = useCallback(() => {
        return history.push('/confirmar-entrega');
    }, [history])

    return (
        <>
            <PublicPageHeader topOpacity="0.8" />
            {carregando && <CircularLoading tipo="FULLSIZED" />}
            <div className={classes.divRoot}>
                <Navbar mobile={isMobile} voltar={voltarPagina} titulo="Leitor Comanda" />
                <div className={classes.root} style={{
                    overflow: 'hidden'
                }}>
                    <KeyboardProvider
                        maxLength={20}
                        floatCases={0}
                        isNumeric={false}
                        handleTextChanged={textChanged}
                        handleAdd={add}
                        handleSubmit={submit}
                    >
                        {fragmento}
                    </KeyboardProvider>
                </div>
            </div>
        </>
    )
}

export default LeitorComandaPage