import { useCallback } from "react";
import { VariaveisAmbiente } from "../../../../config";
import { useApiBase } from "../../base/api-base";

export function useGetSalao() {
    const { invocarApi, ...outrasPropriedades } = useApiBase();

    const getSalao = useCallback(
        (empresaId: string) => {
        return invocarApi({
            url: `/${VariaveisAmbiente.setoresVersion}/empresa/${empresaId}/salao`,
            method: "GET",
            baseURL: `${VariaveisAmbiente.apiSetoresUrl}/api`,
            enviarTokenUsuario: true,
        });
    },
        [invocarApi]
    );

    return {
        ...outrasPropriedades,
        getSalao,
    };
}
