import { Button, Grid, Typography } from '@material-ui/core';
import { useStyles } from './pendencias-page-styles';
import { isEmpty } from 'lodash';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { AutenticacaoPendenciaContratoModel } from '../../../../model/api/autenticacao-pendencia/autenticacao-pendencia';
import { KdsStorageKeys, useKdsStorage } from '../../../../services/app';
import { PublicPageHeader } from '../../../components/headers/header-public-page/header-public-page';
import { AtencaoIcon } from '../../../components/icons/atencao-icon';
import { AvancarIcon, VoltarIcon } from '../../../components/icons';

const PendenciasPage = () => {

  const { getRegistro } = useKdsStorage()
  const history = useHistory()

  const pendencia: AutenticacaoPendenciaContratoModel = isEmpty(getRegistro(KdsStorageKeys.PendenciaContrato)) ? new AutenticacaoPendenciaContratoModel() : getRegistro(KdsStorageKeys.PendenciaContrato)


  useEffect(() => {
    isEmpty(getRegistro(KdsStorageKeys.PendenciaContrato)) && history.push('/')
  }, [getRegistro, history])

  // AUX
  const classes = useStyles();

  return (
    <>
      <PublicPageHeader topOpacity="0.8" />
      <Grid className={classes.container}>
        <Grid className={classes.content}>
          <Grid style={{
            display: 'flex',
            flex: 1,
            alignItems: 'center'
          }}>
            <Grid className={classes.infoContainer}>
              <Grid container justifyContent='center'>
                <AtencaoIcon tipo="GERAL" style={{ maxWidth: '280px' }} />
              </Grid>
              <Typography className={classes.textTitle}>
                Acesso Bloqueado
              </Typography>
              <Grid
                style={{
                  display: 'flex',
                  width: '100%',
                  justifyContent: 'center',
                  flexDirection: 'column'
                }}
              >
                <Typography align='center'>
                  Para desbloqueá-lo, entre em contato com seu Representante para Regularizar a Situação.
                </Typography>
                <Typography align="center">
                  {pendencia.Status.mensagemUsuario && pendencia.Status.mensagemUsuario.length > 0 && (
                    <><b>Aviso:</b> {pendencia.Status.mensagemUsuario}</>
                  )}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid className={classes.containerButton}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={4}>
                <Button
                  variant="outlined"
                  fullWidth
                  color="primary"
                  size="large"
                  onClick={() => history.push('/')}
                >
                  <VoltarIcon tipo="BUTTON" />
                  Voltar
                </Button>
              </Grid>
              <Grid item xs={12} sm={8}>
                <Button
                  variant="contained"
                  fullWidth
                  color="primary"
                  size="large"
                  onClick={() => window.open(pendencia.LinkCobranca)}
                >
                  <AvancarIcon tipo="BUTTON_PRIMARY" />
                  Visualizar Pendências
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default PendenciasPage;
