
import { memo } from "react";
import { Produto } from "../../../../model/api/produto/produto-model";
import { Typography } from "@material-ui/core";

interface InfoProdInterface {
    produto: Produto;
    qtdPai: number
}

const CardInfoProdutoSubItem = ({ produto, qtdPai }: InfoProdInterface) => {

    return (
        <div style={{ marginLeft: '8px' }}>
            <Typography
                variant="body2"
                color="textSecondary"
            >
                {produto.quantidade <= 1 ? produto.quantidade : (produto.quantidade / qtdPai)}x {produto.descricao}
            </Typography>


            {produto?.subItens &&
                produto?.subItens.length > 0 &&
                produto.subItens.map((p, index) => (
                    <CardInfoProdutoSubItem
                        key={index}
                        produto={{ ...p, quantidade: produto.quantidade === 0 ? 0 : p.quantidade }}
                        qtdPai={qtdPai}
                    />
                ))}
        </div>
    );
};

export default memo(CardInfoProdutoSubItem)