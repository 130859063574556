import { Card, Divider, Fade, Grid, Typography } from '@material-ui/core'
import { CardItemPedidoPesquisa } from '../card-item-pedido-pesquisa'
import { CardPesquisaProps } from './card-pesquisa-props'
import { useStyles } from './card-pesquisa-styles'

export const CardPesquisa = ({ pedidos, processosSetores, onClickAtt }: CardPesquisaProps) => {

    const setorIdUnique = Array.from(new Set<string>(pedidos.map(item => item.setorId)))

    const classes = useStyles()

    return (
        <Fade in>
            <Card className={classes.cardContainer}>
                {setorIdUnique.map(setorId => {
                    return (
                        <>
                            <Grid container justifyContent="space-between" alignItems='center'>
                                <Typography variant='h6'>
                                    Setor: {pedidos.filter(pedido => pedido.setorId === setorId)[0].setor}
                                </Typography>
                                <Typography variant='caption'>
                                    Pedido N° {pedidos[0]?.codigoPedido}
                                </Typography>
                            </Grid>
                            <Divider />
                            {pedidos.sort((a, b) => Number(new Date(b.systemInsertDate)) - Number(new Date(a.systemInsertDate))).filter(item => item.setorId === setorId).map(pedido => {
                                return (
                                    <CardItemPedidoPesquisa onClickAtt={onClickAtt} processosSetor={processosSetores.filter(item => item.setor.id === setorId)[0]} model={pedido} />
                                )
                            })}
                        </>
                    )
                })}
            </Card>
        </Fade>
    )
}