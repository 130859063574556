import { Box, Button, Grid, Typography } from "@material-ui/core"
import { DialogSaurus } from "../dialog-saurus/dialog-saurus"
import { CircularLoading } from "../../utils"
import { useStyles } from './dialog-processo-setor-styles'
import { ProcessoSetorModel } from "../../../../model/api/processo-setor/processo-setor-model"
import { isEmpty, isEqual } from "lodash"
import { useCallback, useEffect, useState } from "react"
import { KdsStorageKeys, useKdsStorage, useSessaoAtual, useToastSaurus } from "../../../../services/app"
import { useSetores } from "../../../../services/app/providers/setores-provider"
import { useGetProcessoSetor } from "../../../../data/api/gestao/processo-setor/get-processo-setor"
import { useHistory } from "react-router-dom"
import { SetorProcessoModel } from "../../../../model/api/processo-setor/setor-processo/setor-processo-model"
import { EnumStatusPedido } from "../../../../model/enums/enum-status-pedido"
import { AccordionSetores } from "../../accordion"
import { ConfirmarIcon } from "../../icons/confirmar-icon"
import { DesfazerIcon } from "../../icons"

interface Props {
    openned: boolean
}

export const DialogProcessoSetor = ({ openned }: Props) => {

    const [setores, setSetores] = useState<ProcessoSetorModel[]>([])
    const classes = useStyles();
    const { getProcessoSetor, carregando } = useGetProcessoSetor()
    const history = useHistory();
    const { getEmpresaSelecionada } = useSessaoAtual()
    const [processosSetorStorage, setProcessosSetorStorage] = useState<ProcessoSetorModel[]>([])
    const { showToast } = useToastSaurus()
    const { setRegistro, getRegistro } = useKdsStorage()
    const { fecharSetores } = useSetores()

    const pegarRegistros = useCallback(() => {
        const registro = getRegistro(KdsStorageKeys.ProcessosSetores, false) as ProcessoSetorModel[]

        if (!isEmpty(registro)) {
            setProcessosSetorStorage([...registro])

        }
    }, [getRegistro])

    useEffect(() => {
        pegarRegistros()
    }, [pegarRegistros])

    const setorWrapper = useCallback(async () => {
        let res = await getProcessoSetor(getEmpresaSelecionada()?.Id ?? '')
        if (res.erro) throw res.erro
        if (isEmpty(res.resultado?.data.list)) {
            return []
        }
        return res.resultado?.data.list as ProcessoSetorModel[]
    }, [getEmpresaSelecionada, getProcessoSetor])
    
    useEffect(() => {
        setorWrapper()
            .then(res => {
                setSetores(res ?? [])
            })
            .catch(res => {
                showToast('error', res.message)
            })
    }, [getEmpresaSelecionada, setorWrapper, showToast])

    const validarStatus = useCallback((status: number) => {
        if (status < EnumStatusPedido.EmEntrega) {
            if (status === EnumStatusPedido.AguardandoRetirada) {
                return true;
            }
            if (status < EnumStatusPedido.Finalizado) {
                return true
            }

            return false;
        }
        else {
            return true
        }
    }, [])


    const setSetorProcesso = useCallback((setor: ProcessoSetorModel, processos: SetorProcessoModel[]) => {
        let processosSetores = processosSetorStorage

        const filterSalao = (item: ProcessoSetorModel) => {
            if (item.setor.id === setor.setor.id && isEqual(item.salao, setor.salao)) {
                return true
            }
            return false
        }

        const processoSetor = processosSetores.filter(filterSalao)
        const setorProcessoZerado: ProcessoSetorModel = { ...setor }

        setorProcessoZerado.processos = setorProcessoZerado.processos.filter(item => validarStatus(item.status.codigo))
        if (isEmpty(processoSetor)) {
            processosSetores = [...processosSetores, setorProcessoZerado]
        }

        for (let i = 0; i < processos.length; i++) {

            const temProcesso = processosSetores.filter(filterSalao)[0].processos.filter(item => item.id === processos[i].id)

            const setorPrev = processosSetores.filter(filterSalao)

            const naoSetorPrev = processosSetores.filter(item => item.setor.id !== setor.setor.id || !isEqual(item.salao, setor.salao))

            if (isEmpty(temProcesso)) {
                setorPrev[0].processos = [...setorPrev[0].processos, processos[i]]
            } else {
                if (isEmpty(processoSetor)) {
                    setorPrev[0].processos = [...setorPrev[0].processos.filter(item => item.id === processos[i].id)]
                }
                else {
                    setorPrev[0].processos = [...setorPrev[0].processos.filter(item => item.id !== processos[i].id)]
                }
            }
            processosSetores = [...naoSetorPrev, setorPrev[0]]
        }
        const pegarSetor = processosSetores.filter(filterSalao)
        const setorZerado = pegarSetor[0].processos.filter(item => validarStatus(item.status.codigo))
        if (isEmpty(setorZerado)) {
            processosSetores = processosSetores.filter(item => item.setor.id !== setor.setor.id || !isEqual(item.salao, setor.salao))
        }
        setProcessosSetorStorage(processosSetores)

    }, [processosSetorStorage, validarStatus])

    const submitStorage = useCallback(() => {
        if (processosSetorStorage.length < 1) {
            return;
        }
        setRegistro(KdsStorageKeys.ProcessosSetores, processosSetorStorage, false)

    }, [processosSetorStorage, setRegistro])

    return (
        <DialogSaurus aberto={openned} tamanho="md">
            <Box display="flex" justifyContent="center">
                <Box maxWidth="600px">
                    <div className={classes.titleStyle}>
                        <Typography className={classes.titlePrincipal}>Setores</Typography>
                        <Typography className={classes.subTittle}>
                            Selecione abaixo os setores que este terminal irá controlar:
                        </Typography>
                    </div>
                    {carregando && (
                        <div style={{
                            height: 300,
                            display: 'flex',
                            justifyContent: 'center',
                            width: '100%'
                        }}>
                            <CircularLoading tipo="FULLSIZED" />
                        </div>
                    )}

                    {!isEmpty(setores) ?
                        <Grid container spacing={2} className={classes.grid}>
                            {setores?.map((setor) => {
                                return (
                                    <Grid item xs={12}>
                                        <AccordionSetores
                                            setSetorProcesso={setSetorProcesso}
                                            model={setor}
                                            modelSelecionado={processosSetorStorage.filter(item => item.setor.id === setor.setor.id && isEqual(item.salao, setor.salao))[0] || new ProcessoSetorModel()}
                                        />
                                    </Grid>
                                )
                            })}
                        </Grid>
                        :
                        <Grid>
                            <Typography className={classes.nadaParaExibir}>
                                Essa empresa não possui nenhum Setor
                            </Typography>
                        </Grid>

                    }
                    <Grid item xs={12}>
                        <Box mt={3} mb={2}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Button
                                        startIcon={<ConfirmarIcon tipo="BUTTON_PRIMARY" />}
                                        variant="contained"
                                        color="primary"
                                        size="large"
                                        fullWidth
                                        disabled={processosSetorStorage.length < 1}
                                        onClick={() => {
                                            submitStorage()
                                            history.push('/monitoramento');
                                            fecharSetores()
                                        }}
                                    >
                                        <Typography
                                            className={classes.titleContinuar}>Continuar</Typography>
                                    </Button>
                                </Grid>
                                <Grid item xs={12}>
                                    <Button
                                        variant="outlined"
                                        color="primary"
                                        startIcon={<DesfazerIcon tipo="BUTTON" />}
                                        onClick={fecharSetores}
                                        size="large"
                                        fullWidth
                                    >
                                        <Typography className={classes.titleVoltar}>Voltar</Typography>
                                    </Button>
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                </Box>
            </Box>
        </DialogSaurus>
    )
}