import { Box, Checkbox, Grid, Typography } from "@material-ui/core";
import { CardProdutoProps } from "./card-produto-props"
import { useCallback } from "react";
import { isEmpty } from "lodash";
import { useStyles } from "./card-produto-entrega-style";
import { EnumModulo } from "../../../../../../model/enums/enum-modulo";
import CardInfoProdutoSubItem from "../../../card-info-produto-subitem/card-info-produto-subitem";
import { KdsStorageKeys, useKdsStorage } from "../../../../../../services/app";
import { ModuloModel } from "../../../../../../model/app/forms/master/modulo-model";

export const CardProduto = (props: CardProdutoProps) => {

    const classes = useStyles();
    const { getRegistro } = useKdsStorage();

    const tipoTrabalho = getRegistro(KdsStorageKeys.SelecaoModulo, false) as ModuloModel;

    const { model, processosSelecionados, setProcessosSelecionados } = props


    const adicionarRemoverProcessos = useCallback(() => {
        const processos = processosSelecionados
        const selecionado = processos.find(x => x.id === model.id)
        const processosDiferentes = processos.filter(x => x.id !== model.id)
        const pedidoDiferente = processos.filter(x => x.pedidoId !== model.pedidoId);

        if (props.tipoTrabalho.modulo === EnumModulo.EntregaDelivery) {
            return
        }

        if (!isEmpty(pedidoDiferente)) {
            return setProcessosSelecionados([model]);
        }

        if (isEmpty(selecionado)) {
            return setProcessosSelecionados([...processos, model])
        }

        setProcessosSelecionados([...processosDiferentes])
    }, [model, processosSelecionados, props.tipoTrabalho.modulo, setProcessosSelecionados])
    return (
        <>
            <Grid container onClick={adicionarRemoverProcessos}>
                <Grid item xs={12} style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center'
                }}>
                    <Grid container>
                        <Grid item xs={10} style={{
                            display: 'flex',
                            alignItems: 'center',
                            paddingTop: '8px',
                            paddingBottom: '8px',
                            paddingLeft: props.tipoTrabalho.modulo === EnumModulo.EntregaDelivery ? '8px' : '0px'
                        }}>
                            {props.tipoTrabalho.modulo === EnumModulo.EntregaLocal && (
                                <Checkbox
                                    color="primary"
                                    checked={!isEmpty(processosSelecionados.find(x => x.id === model.id))}
                                    style={{
                                        padding: '0px',
                                        marginRight: '8px'
                                    }}
                                />
                            )}
                            <Typography
                                variant="body1"
                                color="textPrimary"
                                className={classes.tresPontinhos}
                            >
                                <Box fontWeight={600}>
                                    {props.model.produto.descricao}
                                </Box>
                            </Typography>
                        </Grid>
                        <Grid item xs={2} style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'flex-end'
                        }}>
                            <Typography
                                variant="body1"
                                color="textPrimary"
                            >
                                <Box fontWeight={600}>
                                    {`${props.model.produto.quantidade} UN`}
                                </Box>
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>


                {props.model.produto.inclusos.length > 0 && (
                    <Grid item xs={12} style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        marginLeft: tipoTrabalho.modulo === EnumModulo.EntregaLocal ? '40px' : '8px',
                        paddingBottom: '16px'
                    }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                {model.produto.inclusos.map(item => {
                                    return (
                                        <>
                                            <Grid container spacing={2}>
                                                <Grid item xs={12} style={{
                                                    display: 'flex',
                                                    justifyContent: 'space-between'
                                                }}>
                                                    <CardInfoProdutoSubItem
                                                        produto={item}
                                                        qtdPai={model.produto.quantidade}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </>
                                    )
                                })}
                            </Grid>
                        </Grid>
                    </Grid>
                )}
                {model.produto.adicionais && model.produto.adicionais.length > 0 && (
                    <Grid item xs={12}>
                        <Typography
                            variant="body1"
                            color='textPrimary'
                            style={{
                                paddingBottom: '8px'
                            }}
                        >
                            Adicionais
                        </Typography>

                        {model.produto.adicionais.map(item => {
                            return (
                                <>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} style={{
                                            display: 'flex',
                                            justifyContent: 'space-between'
                                        }}>
                                            <Typography
                                                variant="body2"
                                                className={`${item.quantidade <= 0 ? classes.risquinho : ''}`}
                                                style={{
                                                    color: '#808080'
                                                }}
                                            >
                                                {item.descricao}
                                            </Typography>
                                            <Typography
                                                variant="body2"
                                                className={`${item.quantidade <= 0 ? classes.risquinho : ''}`}
                                                style={{
                                                    color: '#808080',
                                                }}
                                            >
                                                {`${item.quantidade} UN`}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </>
                            )
                        })}
                    </Grid>
                )}
            </Grid >
        </>
    )
}