import { useCallback } from "react"
import Scanner from "./scanner/scanner"
import { usePutAlterarProcesso } from "../../../../../data/api/gestao/processo-produto-setor/put-processo-produto-setor"
import { useSessaoAtual, useToastSaurus } from "../../../../../services/app";
import { useHistory } from "react-router-dom";
import { CircularLoading } from "../../../../components";
import { useGetProcessoProdutoSetor } from "../../../../../data/api/gestao/processo-produto-setor/get-processo-produto-setor";
import { ProcessoProdutoSetorModel } from "../../../../../model/api/processo-produto-setor/processo-produto-setor-model";
import { isEmpty } from "lodash";
import { EnumStatusPedido } from "../../../../../model/enums/enum-status-pedido";

export const AdicionarPedidoEntregaFragment = () => {

    const history = useHistory();
    const { showToast } = useToastSaurus();

    const { putAlterarProcesso, carregando: carregandoPut } = usePutAlterarProcesso();
    const { getEmpresaSelecionada, carregando: carregandoSessaoAtual } = useSessaoAtual();
    const { getProcessoProdutoSetor, carregando: carregandoGetProduto } = useGetProcessoProdutoSetor();

    const carregando = carregandoPut || carregandoSessaoAtual || carregandoGetProduto;

    const getProcessoProdutoSetorWrapper = useCallback(async () => {
        let query = '&pageSize=999999';
        const res = await getProcessoProdutoSetor(getEmpresaSelecionada()?.Id!, query);

        if (res.erro)
            throw res.erro;

        return res.resultado?.data.list as ProcessoProdutoSetorModel[];
    }, [getEmpresaSelecionada, getProcessoProdutoSetor])

    const buscarProdutos = useCallback(async (valores: string[]) => {
        const processos = await getProcessoProdutoSetorWrapper();

        return processos.filter(x => valores.filter(y => y)[0] === x.id)[0];
    }, [getProcessoProdutoSetorWrapper])

    const putAlterarProcessoWrapper = useCallback(async (valores: string[]) => {
        const processo = await buscarProdutos(valores);

        if (isEmpty(processo)) {
            return showToast('error', 'Erro ao tentar realizar a leitura.');
        }

        if (processo.status.codigo === EnumStatusPedido.EmEntrega) {
            return showToast('error', 'Este pedido já foi alterado para entrega. Selecione outros produtos para realizar a entrega.')
        }

        const proximoProcesso = processo.processoProducaoAtual + 1;

        const res = await putAlterarProcesso(valores, getEmpresaSelecionada()?.Id!, proximoProcesso);

        if (res.erro)
            throw res.erro

        history.push('/pedidos-para-entrega')
    }, [buscarProdutos, getEmpresaSelecionada, history, putAlterarProcesso, showToast])

    const mudarStatusPedido = useCallback(async (valores: string[]) => {
        try {
            await putAlterarProcessoWrapper(valores);
        }
        catch (err: any) {
            showToast('error', err.message);
        }
    }, [putAlterarProcessoWrapper, showToast])

    const codigoFormatado = useCallback((codigo: string) => {
        let codigoArray = codigo.split(';');

        return codigoArray;
    }, [])

    const leituraRealizada = useCallback(async (codigo: string) => {
        const valor = codigoFormatado(codigo);

        await mudarStatusPedido(valor);
    }, [codigoFormatado, mudarStatusPedido])

    return (
        <>
            {carregando && <CircularLoading tipo="FULLSIZED" />}
            <div style={{ overflow: 'auto', height: '100%' }}>
                <Scanner inicialOrSecundario={false} onScan={leituraRealizada} />
            </div>
        </>
    )
}