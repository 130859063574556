import { useStyles } from './button-keyboard-top-add-styles';
import { ButtonKeyboardNumber } from '../button-keyboard-number/button-keyboard-number';

interface ButtonKeyboardTopAddProps {
  handleButtonAddValue: (value: number) => void;
}

export const ButtonKeyboardTopAdd = ({
  handleButtonAddValue,
}: ButtonKeyboardTopAddProps) => {
  const classes = useStyles();

  return (
    <div className={classes.setarValorPadraoContainer}>
      <div className={classes.setarValorPadraoItem}>
        <ButtonKeyboardNumber
          textVariant="body1"
          prefix="+"
          label="1"
          clickFunc={handleButtonAddValue}
          value={100}
        />
      </div>
      <div className={classes.setarValorPadraoItem}>
        <ButtonKeyboardNumber
          textVariant="body1"
          prefix="+"
          label="5"
          clickFunc={handleButtonAddValue}
          value={500}
        />
      </div>
      <div className={classes.setarValorPadraoItem}>
        <ButtonKeyboardNumber
          textVariant="body1"
          prefix="+"
          label="10"
          clickFunc={handleButtonAddValue}
          value={1000}
        />
      </div>
      <div className={classes.setarValorPadraoItem}>
        <ButtonKeyboardNumber
          textVariant="body1"
          prefix="+"
          label="20"
          clickFunc={handleButtonAddValue}
          value={2000}
        />
      </div>
      <div className={classes.setarValorPadraoItem}>
        <ButtonKeyboardNumber
          textVariant="body1"
          prefix="+"
          label="50"
          clickFunc={handleButtonAddValue}
          value={5000}
        />
      </div>
    </div>
  );
};
