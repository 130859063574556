import { guidEmpty } from "../../../../utils/guid-empty";
import { EnumSituacaoContrato } from "../../../enums/enum-situacao-contrato";

export class PlanoContratadoModel {
    constructor(
        public dExpiracao: string = "",
        public dInserido: string = "",
        public dataUltimaFatura: string = "",
        public melhorDia: number = 0,
        public planoId: string = guidEmpty(),
        public tpFatura: number = 0,
        public avisoCobrancaRepresentante: string = '',
        public avisoCobranca: string = '',
        public status: EnumSituacaoContrato = EnumSituacaoContrato.OK
    ) { }
}
