import { Grid, Typography } from "@material-ui/core";
import { useCallback, useEffect, useRef, useState } from "react";
import { useGetProcessoProdutoSetor } from "../../../../data/api/gestao/processo-produto-setor/get-processo-produto-setor";
import { useGetProcessoSetor } from "../../../../data/api/gestao/processo-setor/get-processo-setor";
import { ProcessoProdutoSetorModel } from "../../../../model/api/processo-produto-setor/processo-produto-setor-model";
import { ProcessoSetorModel } from "../../../../model/api/processo-setor/processo-setor-model";
import { useToastSaurus } from "../../../../services/app";
import { useSessaoAtual } from "../../../../services/app/providers/sessao-atual-provider";
import { CircularLoading } from "../../../components";
import { CardPesquisa } from "../../../components/cards/card-pesquisa/card-pesquisa";
import { TextFieldSaurus } from "../../../components/controles/inputs";
import { PublicPageHeader } from "../../../components/headers/header-public-page/header-public-page";
import { Navbar } from "../../../components/nav";
import { useStyles } from "./pesquisa-avancada-styles";

const PesquisaAvancadaPage = () => {
   const [pedidos, setPedidos] = useState<Array<ProcessoProdutoSetorModel>>([])
   const { showToast } = useToastSaurus()
   const classes = useStyles();
   const [pesquisa, setPesquisa] = useState<string>('');
   const [processosSetor, setProcessosSetor] = useState<ProcessoSetorModel[]>([])
   const { getEmpresaSelecionada } = useSessaoAtual()
   const { getProcessoProdutoSetor, carregando: carregandoProdutoSetor } = useGetProcessoProdutoSetor()
   const { getProcessoSetor, carregando: carregandoProcessoSetor } = useGetProcessoSetor()
   const timer = useRef({} as NodeJS.Timeout);
   const pesquisou = useRef<boolean>(false)

   const carregando = carregandoProcessoSetor || carregandoProdutoSetor

   const getProcessoProdutoSetorWrapper = useCallback(async () => {
      try {
         if (pesquisa.length === 0) return []
         const res = await getProcessoProdutoSetor(getEmpresaSelecionada()?.Id ?? '', `&filtro=${pesquisa}`)

         if (res.erro) throw res.erro

         setPedidos(res.resultado?.data as ProcessoProdutoSetorModel[])

      } catch (e: any) {
         showToast('error', e.message)
      }
   }, [getEmpresaSelecionada, getProcessoProdutoSetor, pesquisa, showToast])

   const getSetores = useCallback(async () => {
      try {
         const res = await getProcessoSetor(getEmpresaSelecionada()?.Id ?? '')

         if (res.erro) throw res.erro

         const ret = res.resultado?.data as ProcessoSetorModel[]

         setProcessosSetor(ret)

         return ret

      } catch (err: any) {
         showToast('error', err.message)
      }
   }, [getEmpresaSelecionada, getProcessoSetor, showToast])

   useEffect(() => {
      getSetores()
   }, [getSetores])

   useEffect(() => {
      clearTimeout(timer.current);
      timer.current = setTimeout(() => {
         if (pesquisou.current) {
            pesquisou.current = false
            return
         }
         getProcessoProdutoSetorWrapper();
      }, 1000);
      return () => {
         clearTimeout(timer.current);
      };
   }, [getProcessoProdutoSetorWrapper]);

   const pedidosIdUnique = Array.from(new Set<string>(pedidos.map(item => item.pedidoId)))

   return (
      <>
         <PublicPageHeader topOpacity="0.8" />
         <div className={classes.root} style={{
            height: '100%'
         }}>
            {carregando && (
               <CircularLoading tipo="FULLSIZED" />
            )}
            <Navbar titulo="Pesquisa Avançada" />
            <div style={{
               height: '100%'
            }}>
               <Grid container className={classes.spacing}>
                  <Grid item xs={8}>
                     <Typography className={classes.title}>
                        Pesquisa
                     </Typography>
                  </Grid>
                  <Grid item xs={4}>
                     <TextFieldSaurus
                        searchable
                        size="small"
                        placeholder="Código do Pedido"
                        onSearch={() => {
                           getProcessoProdutoSetorWrapper()
                           getSetores()
                           pesquisou.current = true
                        }}
                        value={pesquisa}
                        onChange={ev => setPesquisa(ev.target.value)}
                     />
                  </Grid>
                  <Grid item xs={12}>
                     <div className={classes.divider} />
                  </Grid>
                  <Grid item xs={12}>
                     <div className={classes.divContainer}>
                        <Grid container spacing={2}>
                           {pedidosIdUnique.map(pedidoId => (
                              <Grid item xs={12} md={6}>
                                 <CardPesquisa onClickAtt={() => {
                                    getProcessoProdutoSetorWrapper()
                                    getSetores()
                                 }} processosSetores={processosSetor} pedidos={pedidos.filter(pedido => pedido.pedidoId === pedidoId)} />
                              </Grid>
                           ))}
                        </Grid>
                     </div>
                  </Grid>
               </Grid>
            </div>
         </div>

      </>
   )
};

export default PesquisaAvancadaPage