import { forwardRef, ReactNode } from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
  Fade,
  Grid,
  IconButton,
  Divider,
} from '@material-ui/core';
import { TransitionProps } from '@material-ui/core/transitions';
import { useStyles } from './dialog-saurus-styles';
import CloseIcon from '@material-ui/icons/Close';
import { CircularLoading } from '../../utils';
import { useThemeQueries } from '../../../theme';

export interface DialogSaurusProps {
  aberto?: boolean;
  children?: ReactNode;
  icone?: ReactNode;
  carregando?: boolean;
  titulo?: string;
  colorTitulo?: string;
  centralizarTitulo?: boolean;
  tamanho?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false;
  zIndex?: string;
  isButtonTitleClose?: boolean;
  isDivider?: boolean;
  handleClickClose?: () => void;
}
const Transition = forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement },
  ref: React.Ref<unknown>,
) {
  return <Fade timeout={500} ref={ref} {...props} />;
});

export const DialogSaurus = ({
  aberto,
  children,
  icone,
  carregando,
  centralizarTitulo,
  colorTitulo,
  titulo,
  tamanho,
  zIndex,
  isButtonTitleClose,
  isDivider,
  handleClickClose,
}: DialogSaurusProps) => {

  const classes = useStyles();
  const { theme } = useThemeQueries();

  return (
    <Dialog
      maxWidth={tamanho || 'lg'}
      open={aberto || false}
      fullWidth
      disableScrollLock
      keepMounted
      disableEscapeKeyDown
      TransitionComponent={Transition}
      className={classes.dialogPaper}
      ref={(el: any) => {
        if (el && (zIndex || '').length > 0) {
          el.style.setProperty('z-index', zIndex, 'important');
        }
      }}
    >
      <DialogTitle disableTypography style={{
        padding: '8px 24px'
      }}>
        {isButtonTitleClose ? (
          <Grid style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography variant="h6">
              <strong style={{ color: colorTitulo ? colorTitulo : '' }}>
                {titulo}
              </strong>
            </Typography>
            <IconButton className={classes.closeButton} onClick={handleClickClose}>
              <CloseIcon style={{ fontSize: 28 }} />
            </IconButton>
          </Grid>
        ) : (
          <Grid
            style={{
              display: 'flex',
              justifyContent: centralizarTitulo ? 'center' : 'flex-start',
              alignItems: centralizarTitulo ? 'center' : 'flex-start',
            }}
          >
            {icone ? icone : null}
            <Typography variant="h6">
              <strong style={{ color: colorTitulo ? colorTitulo : '' }}>
                {titulo}
              </strong>
            </Typography>
          </Grid>
        )}
      </DialogTitle>

      {isDivider && (
        <Divider 
          variant='fullWidth'
          style={{
            background: theme.palette.primary.main,
            marginLeft: '24px',
            marginRight: '24px'
          }}
        />
      )}

      <DialogContent>
        {carregando ? <CircularLoading tipo="FULLSIZED" /> : children}
      </DialogContent>
    </Dialog>
  );
};
