import { useCallback } from "react";
import { PublicPageHeader } from "../../../components/headers/header-public-page/header-public-page";
import { Navbar } from "../../../components/nav";
import { useThemeQueries } from "../../../theme";
import { useStyles } from "./assinatura-entrega-page-styles";
import { AssinaturaEntregaData } from "./components/assinatura-entrega-data";
import { useHistory } from "react-router";

const AssinaturaEntregaPage = () => {

    const classes = useStyles();
    const { isMobile } = useThemeQueries();
    const { push } = useHistory();

    const voltar = useCallback(() => {
        push('/confirmar-entrega')
    }, [push])

    return (
        <>
            <PublicPageHeader topOpacity="0.8" />
            <div className={classes.divRoot}>
                <Navbar
                    mobile={isMobile}
                    titulo={isMobile ? 'Coloque a Assinatura' : 'Assinatura'}
                    voltar={voltar}
                />
                <div className={classes.root}>
                    <AssinaturaEntregaData />
                </div>
            </div>
        </>
    )
}

export default AssinaturaEntregaPage;