import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
   time: {
      height: '35px',
      display: 'flex',
      justifyContent: 'center',
      padding: '8px',
      alignItems: 'center',
      color: '#545454',
      borderRadius: '5px',
      border: '1px solid #545454'
   },
}));