import { DefaultIconProps } from './default-icon/default-icon-props';
import { DefaultIcon } from './default-icon/default-icon';

export const BalcaoIcon = (props: DefaultIconProps) => {
  return (
    <>
      <DefaultIcon
        tipo={props.tipo}
        class={props.class}
        style={props.style}
        fill={props.fill}
      >
        <path d="M25.2498 13.95C26.3998 14 27.7748 13.8 29.1498 14.125C32.0498 14.8 34.0498 17.2 34.1748 20.175C34.2248 21.025 34.1748 21.875 34.1998 22.7C34.1998 23.075 34.0748 23.125 33.7498 23.15C32.3498 23.2 32.3498 23.2 32.3498 21.8C32.3498 21.1 32.3998 20.4 32.2998 19.725C31.9998 17.675 30.5248 16.1 28.4498 15.875C26.1248 15.625 23.7748 15.6 21.4498 15.9C19.2748 16.175 17.6998 18.1 17.6498 20.325C17.6498 21.1 17.6248 21.875 17.6498 22.65C17.6498 23 17.5998 23.15 17.1998 23.15C15.7998 23.175 15.7998 23.175 15.7998 21.775C15.7998 20.65 15.7498 19.5 16.1248 18.4C16.9998 15.725 19.3748 14 22.3248 13.975C23.2248 13.95 24.1248 13.95 25.2498 13.95Z" />
        <path d="M46.525 24.075C46.2 24.075 46.05 24.025 45.925 23.65C45.425 22.1 44.4 21.075 42.825 20.6C42.55 20.525 42.375 20.45 42.45 20.125C42.575 19.575 42.3 19.425 41.8 19.5C41.55 19.525 41.3 19.525 41.075 19.5C40.675 19.45 40.55 19.625 40.625 19.975C40.725 20.4 40.5 20.525 40.125 20.625C38.725 21.1 37.7 22.025 37.225 23.45C37.05 24 36.775 24.075 36.275 24.075C25.275 24.075 14.275 24.075 3.275 24.075C2.275 24.075 2 24.35 2 25.325C2 26.35 2 27.375 2 28.4C2 29.275 2.3 29.625 3.15 29.575C3.775 29.525 3.875 29.775 3.85 30.325C3.825 35.775 3.825 41.25 3.85 46.7C3.85 47.725 4.125 48 5.15 48C11.775 48 18.375 48 25 48C31.575 48 38.15 48 44.7 48C45.875 48 46.125 47.775 46.125 46.625C46.125 41.2 46.125 35.75 46.125 30.325C46.125 29.8 46.2 29.575 46.8 29.6C47.7 29.65 48 29.325 48 28.4C48 27.475 48 26.525 48 25.6C47.975 24.3 47.775 24.075 46.525 24.075ZM41.45 22.25C42.55 22.175 43.525 22.85 44.075 24.075C43.2 24.075 42.375 24.075 41.55 24.075C40.7 24.075 39.875 24.075 38.925 24.075C39.475 22.875 40.3 22.3 41.45 22.25ZM44.3 45.525C44.3 46.025 44.2 46.15 43.675 46.15C31.2 46.125 18.725 46.125 6.275 46.15C5.75 46.15 5.65 46 5.675 45.525C5.7 40.425 5.7 35.325 5.675 30.225C5.675 29.675 5.85 29.575 6.35 29.575C12.575 29.6 18.825 29.575 25.05 29.575C31.225 29.575 37.375 29.575 43.55 29.55C44.125 29.55 44.3 29.65 44.3 30.275C44.275 35.375 44.275 40.45 44.3 45.525ZM46.125 26.7C46.075 27.775 46.1 27.775 45.025 27.775C38.35 27.775 31.675 27.775 24.975 27.775C18.125 27.775 11.25 27.775 4.4 27.775C4 27.775 3.825 27.7 3.825 27.25C3.825 25.925 3.8 25.925 5.125 25.925C18.525 25.925 31.95 25.925 45.35 25.9C46.025 25.9 46.175 26.075 46.125 26.7Z" />
        <path d="M24.9752 2C21.9752 2 19.5002 4.475 19.4752 7.475C19.4502 10.525 21.9002 13 24.9502 13.05C27.9752 13.075 30.4752 10.6 30.4752 7.55C30.5002 4.5 28.0252 2 24.9752 2ZM24.9752 11.2C22.9502 11.2 21.3002 9.55 21.3002 7.5C21.3002 5.45 22.9252 3.85 25.0002 3.85C27.0252 3.85 28.6252 5.45 28.6502 7.5C28.6502 9.55 27.0252 11.2 24.9752 11.2Z" />
      </DefaultIcon>
    </>
  );
};
