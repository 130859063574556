import { DefaultIconProps } from "./default-icon/default-icon-props";
import { DefaultIcon } from "./default-icon/default-icon"
export const VoltarGrandeIcon = (props: DefaultIconProps) => {
    return (
        <>
            <DefaultIcon tipo={props.tipo} class={props.class} style={props.style} fill={props.fill}>
                <path d="M36.4792 8.0625L32.7709 4.375L12.1667 25L32.7917 45.625L36.4792 41.9375L19.5417 25L36.4792 8.0625Z" />
            </DefaultIcon>
        </>
    );
};