

export class ClienteModel {
    constructor(
        public referenceId: string = '',
        public nomeFantasia: string = '',
        public razaoSocial: string = '',
        public email: string = '',
        public cpfCnpj: string = '',
        public ieRg: string = '',
        public dataNascimento: string = '',
        public telefone: string = '',
    ) { }
}