import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    padding: '16px',
    paddingTop: 80,
    paddingBottom: '45px',
  },
  spacing: {
    "& .MuiGrid-spacing-xs-2": {
      marginBottom: 16,
    }
  },
  divPedido: {
    padding: '0 10px 0 10px',
    opacity: 1,
    overflowY: 'auto',
    height: 'calc(100vh - 370px)',
    margin: '10px'
  },
  divContainer: {
    minHeight: 'calc(100vh - 220px)',
    padding: '16px',
    margin: '15px 0 20px 0',
    background: '#F6F7F8',
    borderRadius: '8px',
    boxShadow: 'inset 0 0 10px rgba(0, 0, 0, 0.15)',
    animationName: 'focus-in-contract',
    animationDuration: '1s',
    animationTimingFunction: 'ease-in',
    animationDelay: '1.2s',
    animationIterationCount: '1',
    animationDirection: 'normal',
    animationFillMode: 'none',

    [theme.breakpoints.down("xl")]: {
      minHeight: 'calc(100vh - 220px)',
      padding: '16px',
      margin: '15px 0 20px 0',
      background: '#F6F7F8',
      borderRadius: '8px',
      boxShadow: 'inset 0 0 10px rgba(0, 0, 0, 0.15)',
    },
    //tela do munitor
    [theme.breakpoints.down("lg")]: {
      minHeight: 'calc(100vh - 220px)',
      padding: '16px',
      margin: '15px 0 20px 0',
      background: '#F6F7F8',
      borderRadius: '8px',
      boxShadow: 'inset 0 0 10px rgba(0, 0, 0, 0.15)',
    },
    // telas menores
    [theme.breakpoints.down("md")]: {
      minHeight: 'calc(100vh - 220px)',
      padding: '16px',
      margin: '15px 0 20px 0',
      background: '#F6F7F8',
      borderRadius: '8px',
      boxShadow: 'inset 0 0 10px rgba(0, 0, 0, 0.15)',
    },
  },

  titleM: {
    color: '#808080',
    height: '30px',
    padding: '0 0 0 20px',
    fontSize: '21px',
    fontWeight: 600,
    [theme.breakpoints.down("xl")]: {
      color: '#808080',
      height: '30px',
      padding: '0 0 0 20px',
      fontSize: '21px',
      fontWeight: '600'
    },
    [theme.breakpoints.down("md")]: {
      marginBottom: '8px',
      color: '#808080',
      height: '30px',
      fontWeight: 600,
      fontSize: '23px',
    },
  },
  title: {
    color: '#808080',
    display: 'inline-flex',
    fontSize: '30px',
    fontWeight: 600,
    [theme.breakpoints.down("xl")]: {
      color: '#808080',
      display: 'inline-flex',
      fontWeight: 600,
      fontSize: '30px',
    },
    [theme.breakpoints.down("md")]: {
      color: '#808080',
      display: 'inline-flex',
      fontWeight: 600,
      fontSize: '30px',
    },
  },
  divider: {
    backgroundColor: '#BDBDBD',
    marginBottom: '10px',
    width: "100%",
    height: '1px',
  },
  nadaParaExibir: {
    color: 'rgb(158 156 156)',
    padding: '6rem 0px 0rem 0',
    fontSize: '20px',
    textAlign: 'center'
  },
  loading: {
    position: 'fixed',
    right: 0,
    top: 60,
    '& > div': {
      width: 80,
      height: 80
    }
  }
}));