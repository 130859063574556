import { DefaultIconProps } from "./default-icon/default-icon-props";
import { DefaultIcon } from "./default-icon/default-icon"

export const BarcodeScanIcon = (props: DefaultIconProps) => {
    return (
        <>
            <DefaultIcon tipo={props.tipo} onClick={props.onClick} class={props.class} style={props.style} fill={props.fill}>
                <path d="M5 9.55556V18.6667H9.55556V9.55556H18.6667V5H9.55556C7.05 5 5 7.05 5 9.55556ZM9.55556 32.3333H5V41.4444C5 43.95 7.05 46 9.55556 46H18.6667V41.4444H9.55556V32.3333ZM41.4444 41.4444H32.3333V46H41.4444C43.95 46 46 43.95 46 41.4444V32.3333H41.4444V41.4444ZM41.4444 5H32.3333V9.55556H41.4444V18.6667H46V9.55556C46 7.05 43.95 5 41.4444 5Z" stroke="white" stroke-width="0.854167" />
                <rect x="10.125" y="20.375" width="1.70833" height="10.25" />
                <rect x="25.5" y="20.375" width="1.70833" height="10.25" />
                <rect x="34.0416" y="20.375" width="1.70833" height="10.25" />
                <rect x="37.4584" y="20.375" width="2.5625" height="10.25" />
                <rect x="13.5416" y="20.375" width="2.5625" height="10.25" />
                <rect x="28.9166" y="20.375" width="2.5625" height="10.25" />
                <rect x="18.6666" y="20.375" width="5.125" height="10.25" />
            </DefaultIcon>
        </>
    );
};


