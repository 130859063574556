import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
    visorCodigo: {
        height: '100px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column'
    },
    contentVisor: {
        flex: 1,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        width: 0,
        marginRight: '5px'
    },
    label: {
        fontSize: '14px',
        fontWeight: 600,
        lineHeight: '21px',
        textAlign: 'center',
    },
    textVisor: {
        fontSize: '26px',
        fontWeight: 600,
        lineHeight: '32px',
        textAlign: 'center',
        color: '#1F9CE4',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        width: '310px',
    },
    containerItemFooter: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        paddingRight: theme.spacing(1),

        "& .MuiBadge-badge": {
            "z-index": 0,
        }
    },
    textButton: {
        fontSize: '16px',
        fontWeight: 700,
        lineHeight: '33px',
        textAlign: 'center',
    },
    containerFooter: {
        height: '80px',
        display: 'flex',
        width: '100%',
        paddingLeft: theme.spacing(1)
    },
    content: {
        flex: 1,
        height: '60px',
        display: 'flex',
        justifyContent: 'space-around',
        alignItems: 'center',
        borderRadius: '0px 3px 3px 0px',
        marginBottom: theme.spacing(2),
    },
    button: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        marginBottom: theme.spacing(1),
        marginTop: theme.spacing(1),
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    containerPreviewInfo: {
        width: '100%',
        height: '60px',
        background: 'rgba(0, 0, 0, 0.6)',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'absolute',
        marginTop: '80px',
        paddingLeft: '10px',
        paddingRight: '10px',
        zIndex: 1,
    },
    textPreview: {
        color: '#fff',
        fontSize: '16px',
        lineHeight: '31px',
        fontWeight: 600
    },
}));

