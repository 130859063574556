import { Button, Grid, Typography } from "@material-ui/core";
import { useStyles } from "./selecao-modulo-page-styles";
import { PublicPageHeader } from "../../../components/headers/header-public-page/header-public-page";
import { FormModulo } from "../../../components/form/master/modo/form-modo";
import { useCallback, useEffect, useRef } from "react";
import { KdsStorageKeys, useKdsStorage } from "../../../../services/app";
import { DefaultFormRefs } from "../../../components/form/utils";
import { useHistory } from "react-router-dom";
import { ModuloModel } from "../../../../model/app/forms/master/modulo-model";

const SelecaoModuloPage = () => {

    const classes = useStyles();
    const history = useHistory()
    const { setRegistro } = useKdsStorage();

    const moduloRef = useRef<DefaultFormRefs<ModuloModel>>(null);

    useEffect(() => {
        if (moduloRef) moduloRef.current?.fillForm(new ModuloModel());
    }, []);

    const submitFormModo = useCallback((model: ModuloModel) => {
        setRegistro(KdsStorageKeys.SelecaoModulo, model, false);

        history.push('/')
    }, [history, setRegistro])

    return (
        <>
            <PublicPageHeader topOpacity="0.8" />
            <Grid className={classes.container}>
                <Grid className={classes.content}>
                    <Grid className={classes.infoContainer}>
                        <Typography className={classes.textTitle}>
                            Módulo de Uso
                        </Typography>
                        <Typography className={classes.textSubtitle}>
                            Escolha o seu módulo de trabalho no KDS.
                        </Typography>
                        <FormModulo
                            ref={moduloRef}
                            loading
                            onSubmit={submitFormModo}
                            showLoading={false}
                        />
                        <Button
                            color="primary"
                            variant="contained"
                            fullWidth
                            style={{
                                marginTop: '32px'
                            }}
                            onClick={() => {
                                moduloRef.current?.submitForm()
                            }}
                        >
                            Salvar
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
}

export default SelecaoModuloPage;