import { useCallback } from "react";
import { useApiBase } from "../../base/api-base";

export function useGetEmpresaConfig() {
    const { invocarApi, ...outrasPropriedades } = useApiBase();

    const getEmpresaConfig = useCallback(
        (empresaId: string) =>
            invocarApi({
                url: `v3/Empresa/${empresaId}/Configuracao/detalhado`,
                method: "GET",
                enviarTokenUsuario: true,
            }),
        [invocarApi]
    );
    return {
        ...outrasPropriedades,
        getEmpresaConfig,
    };
}
