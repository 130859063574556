import { createTheme } from "@material-ui/core";
import createBreakpoints from "@material-ui/core/styles/createBreakpoints";
import createPalette from "@material-ui/core/styles/createPalette";
import { Shadows } from "@material-ui/core/styles/shadows";

declare module "@material-ui/core/styles/createPalette" {
  interface Palette {
    teste?: Palette["primary"];
  }
  interface PaletteOptions {
    teste?: Palette["primary"];
  }
}

const breakpoints = createBreakpoints({});

const pallet = createPalette({
  primary: {
    main: "#151e45",
    contrastText: "#FFFFFF",
    light: "#2E729A"
  },
  common: {
    black: "#000",
    white: "#FEFEFE",
  },
  secondary: {
    main: "#c2ab6c",
    light: "#DDD",
  },

  success: {
    main: "#10C822",
  },
  error: {
    main: "#F44336",
    light: "rgba(244, 67, 54, 0.1);",
  },

  warning: {
    main: "#FFBC00",
    dark: "#EF9000",
  },

  info: {
    main: "#37ADF1",
  },

  text: {
    primary: "#666",
    secondary: "#999",
    disabled: "#999",
  },
  action: {
    disabled: "#F5F5F5"
  },
  divider: "rgba(85,85,85, 0.5)",
});

export const SaurusSafraTheme = createTheme({
  breakpoints: breakpoints,
  typography: {

    button: {
      textTransform: "none",
      fontWeight: 600,

    },
    fontFamily: `'Montserrat', sans-serif;`,
  },
  shadows: [
    "none",
    "1px 1px 2px rgba(0,0,0,0.1)",
    "2px 2px 3px rgba(0,0,0,0.1)",
    "2px 2px 5px rgba(0,0,0,0.1)",
    "3px 3px 5px rgba(0,0,0,0.1)",
    "4px 4px 5px rgba(0,0,0,0.1)",
    "1px 1px 2px rgba(0,0,0,0.2)",
    "2px 2px 3px rgba(0,0,0,0.2)",
    "2px 2px 5px rgba(0,0,0,0.2)",
    "3px 3px 5px rgba(0,0,0,0.2)",
    "4px 4px 5px rgba(0,0,0,0.2)",
    "1px 1px 2px rgba(0,0,0,0.3)",
    "2px 2px 3px rgba(0,0,0,0.3)",
    "2px 2px 5px rgba(0,0,0,0.3)",
    "3px 3px 5px rgba(0,0,0,0.3)",
    "4px 4px 5px rgba(0,0,0,0.3)",
    "1px 1px 2px rgba(0,0,0,0.4)",
    "2px 2px 3px rgba(0,0,0,0.4)",
    "2px 2px 5px rgba(0,0,0,0.4)",
    "3px 3px 5px rgba(0,0,0,0.4)",
    "4px 4px 5px rgba(0,0,0,0.4)",
    "1px 1px 2px rgba(0,0,0,0.5)",
    "2px 2px 3px rgba(0,0,0,0.5)",
    "2px 2px 5px rgba(0,0,0,0.5)",
    "3px 3px 5px rgba(0,0,0,0.5)"
  ] as Shadows,
  shape: {
    borderRadius: 4,
  },
  palette: pallet,
  overrides: {
    MuiCssBaseline: {
      "@global": {
        body: {
          backgroundColor:
            "linear-gradient(335.37deg, rgba(246, 248, 250, 0.6) -3.52%, rgba(252, 251, 252, 0.6) 117.74%);",
        },
        /* width */
        "*::-webkit-scrollbar": {
          width: "8px",
        },
        /* Track */
        "*::-webkit-scrollbar-track": {
          background: pallet.grey[200],
        },

        /* Handle */
        "*::-webkit-scrollbar-thumb": {
          background: pallet.grey[400],
        },

        /* Handle on hover */
        "*::-webkit-scrollbar-thumb:hover": {
          background: pallet.grey[500],
        },
      },
    },
    MuiButton: {
      root: {
        minWidth: "none"
      }
    },
    MuiTextField: {

      root: {
        "& input": {
          fontWeight: 0,
          "&::placeholder": {
            color: pallet.text.secondary,
            fontWeight: 0,
            opacity: 1,
          },
          "&:disabled": {
            color: pallet.text.disabled,
          },
        },
        "& .MuiInput-underline:before": {
          borderColor: pallet.text.disabled,
        },
      },
    },
    MuiFormControlLabel: {
      root: {
        color: pallet.text.primary,
      },
    },
    MuiFormLabel: {
      root: {
        color: pallet.text.disabled,
      },
    },
  },
  props: {
    MuiTextField: {
      InputLabelProps: {
        shrink: true,
      },
      variant: "outlined",

      fullWidth: true,
    },
  },
});
