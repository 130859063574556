import { useCallback } from "react";
import { VariaveisAmbiente } from "../../../../config";

import { useApiBase } from "../../base/api-base";

export function usePutAlterarProcesso() {
    const { invocarApi, ...outrasPropriedades } = useApiBase();

    const putAlterarProcesso = useCallback(
        (alterarProcesso: string[], empresaId: string, proximoProcesso: number) => {
            return invocarApi({
                url: `api/v5/empresa/${empresaId}/processo-produto/alterar-processo/${proximoProcesso}`,
                method: "PUT",
                headers: { "Content-Type": "application/json" },
                data: alterarProcesso,
                baseURL: VariaveisAmbiente.apiSetoresUrl,
                enviarTokenUsuario: true,
            });
        },
        [invocarApi]
    );

    return {
        ...outrasPropriedades,
        putAlterarProcesso,
    };
}