import { Button, Grid, Typography } from "@material-ui/core"
import { AssinaturaFragment } from "./assinatura-fragment/assinatura-fragment"
import { useCallback, useRef, useState } from "react";
import { usePutConfirmarRecebimento } from "../../../../../data/api/gestao/processo-produto-setor/put-confirmar-recebimento";
import { KdsStorageKeys, useKdsStorage, useSessaoAtual, useToastSaurus } from "../../../../../services/app";
import { ConfirmarEntregaModel } from "../../../../../model/app/forms/confirmar-entrega/confirmar-entrega-model";
import { ConfirmarRecebimentoModel } from "../../../../../model/api/processo-produto-setor/confirmar-recebimento";
import { useHistory } from "react-router-dom";
import { CircularLoading } from "../../../../components";
import { ConfirmarIcon } from "../../../../components/icons/confirmar-icon";
import SignatureCanvas from "react-signature-canvas";
import { LimparIcon } from "../../../../components/icons/limpar-icon";
import { useThemeQueries } from "../../../../theme";
import { VoltarIcon } from "../../../../components/icons";

export const AssinaturaEntregaData = () => {

    const { getRegistro } = useKdsStorage();
    const { showToast } = useToastSaurus();
    const history = useHistory();
    const { theme, isMobile } = useThemeQueries();

    const [dataURL, setDataURL] = useState<string | null>(null);
    const padRef = useRef<SignatureCanvas>(null);

    const model = getRegistro(KdsStorageKeys.PedidoConfirmarEntrega, false) as ConfirmarEntregaModel;

    const { putConfirmarRecebimento, carregando: carregandoConfirmarRecebimento } = usePutConfirmarRecebimento();
    const { getEmpresaSelecionada, carregando: carregandoSessaoAtual } = useSessaoAtual();

    const carregando = carregandoConfirmarRecebimento || carregandoSessaoAtual;

    const putConfirmarRecebimentoWrapper = useCallback(async () => {
        const confirmar = new ConfirmarRecebimentoModel();

        confirmar.ids = model.processosProdutosIds;
        confirmar.imagemDados = dataURL!;

        const proximoProcesso = model.processoAtual + 1;

        const res = await putConfirmarRecebimento(getEmpresaSelecionada()?.Id!, proximoProcesso, confirmar);

        if (res.erro)
            throw res.erro

        return showToast('success', 'Entrega realizada com sucesso.');
    }, [dataURL, getEmpresaSelecionada, model.processoAtual, model.processosProdutosIds, putConfirmarRecebimento, showToast])

    const confirmarRecebimento = useCallback(async () => {
        try {
            await putConfirmarRecebimentoWrapper();

            return history.push('/pedidos-para-entrega')
        } catch (err: any) {
            showToast('error', err.message);
        }
    }, [history, putConfirmarRecebimentoWrapper, showToast])

    const limparAssinatura = useCallback(() => {
        padRef?.current?.clear();
    }, [])

    return (
        <>
            {carregando && <CircularLoading tipo="FULLSIZED" />}
            <Grid container style={{
                padding: '8px',
                height: '100%'
            }}>
                <Grid item xs={12} style={{ height: '100%' }}>
                    <Grid container spacing={2} style={{ height: '100%' }}>
                        <Grid item xs={12} style={{
                            display: 'flex',
                            alignItems: 'center'
                        }}>
                            {!isMobile && (
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => history.push('/confirmar-entrega')}
                                >
                                    <VoltarIcon tipo="PRIVATE_HEADER" />
                                </Button>
                            )}

                            <Typography
                                variant="body1"
                                color="textPrimary"
                                style={{
                                    display: 'flex',
                                    justifyContent: isMobile ? 'center' : 'flex-start',
                                    textAlign: 'center',
                                    marginLeft: isMobile ? '0px' : '8px'
                                }}
                            >
                                Para confirmar a entrega do pedido solicidado,
                                é necessácio a assinatura do cliente.
                            </Typography>
                        </Grid>
                        <Grid item xs={12} style={{
                            height: isMobile ? '78%' : '75%'
                        }}>
                            <AssinaturaFragment
                                ref={padRef}
                                setDataUrl={setDataURL}
                            />
                        </Grid>
                        <Grid item xs={6} style={{
                            height: '10%',
                            display: 'flex',
                            justifyContent: 'center'
                        }}>
                            <Button
                                variant='outlined'
                                color={'primary'}
                                fullWidth={true}
                                onClick={() => limparAssinatura()}
                                startIcon={<LimparIcon tipo="BUTTON_PRIMARY" fill={theme.palette.primary.main} />}
                                style={{
                                    background: '#FFF'
                                }}
                            >
                                Limpar
                            </Button>
                        </Grid>
                        <Grid item xs={6} style={{
                            height: '10%',
                            display: 'flex',
                            justifyContent: 'center'
                        }}>
                            <Button
                                variant="contained"
                                color="primary"
                                fullWidth={true}
                                onClick={confirmarRecebimento}
                                startIcon={<ConfirmarIcon tipo="BUTTON_PRIMARY" />}
                                style={{
                                    padding: '8px'
                                }}
                            >
                                Confirmar
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}