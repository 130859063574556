import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    fePulse: {
        animationName: "$pulse",
        animationDuration: ".5s",
        animationIterationCount: "infinite",
    },
    risquinho: {
        textDecoration: 'line-through'
    },
    tresPontinhos: {
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
    }
}))