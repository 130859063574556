import {
  createContext,
  useCallback,
  useContext,
  useRef,
  useState,
} from 'react';
import React from 'react';
import { toDecimal, toDecimalString } from '../../../../utils/to-decimal';
interface KeyboardContextValue {
  text: string;
  versao: Date;
  getFormattedText: () => string;
  sendText: (text: string) => void;
  resetText: (text: string) => void;
  submitKey: () => void;
  addKey: () => void;
  addMultiply: (value: number) => void;
  addValue: (value: number) => void;
  backSpaceKey: () => void;
}

const KeyboardContext = createContext<KeyboardContextValue>({
  sendText: (text: string) => true,
  resetText: (text: string) => true,
  submitKey: () => true,
  addKey: () => true,
  backSpaceKey: () => true,
  getFormattedText: () => '',
  addMultiply: (value: number) => '',
  addValue: (value: number) => '',
  text: '',
  versao: new Date(),
});

export interface KeyboardProviderProps {
  children: React.ReactNode;
  isNumeric: boolean;
  floatCases: number;
  maxLength: number;
  handleTextChanged?: (
    value: string,
    formattedValue: string,
  ) => Promise<boolean>;
  handleAdd?: () => Promise<boolean>;
  handleSubmit?: () => Promise<boolean>;
}

export const useKeyboard = () => {
  return useContext(KeyboardContext);
};

export const KeyboardProvider = ({
  children,
  isNumeric,
  floatCases,
  maxLength,
  handleTextChanged,
  handleAdd,
  handleSubmit,
}: KeyboardProviderProps) => {
  const [versao, setVersao] = useState<Date>(new Date());
  const textoAtual = useRef<string>('');
  const digitado = useRef<boolean>(false);

  const getFormattedTextInterno = useCallback(
    (text: string): string => {
      if (!isNumeric) return text;
      if (isNaN(Number(text))) return Number(0).toFixed(floatCases);
      return toDecimalString(
        (Number(text) / Math.pow(10, floatCases)).toFixed(floatCases),
        floatCases,
      );
    },
    [floatCases, isNumeric],
  );

  const getFormattedText = useCallback(() => {
    return getFormattedTextInterno(textoAtual.current);
  }, [getFormattedTextInterno]);

  const tryChangeText = useCallback(
    async (text: string, digitou: boolean) => {
      if (text.length > maxLength) return;

      try {
        if (
          toDecimal(getFormattedTextInterno(textoAtual.current), 2) === 0 &&
          toDecimal(text, 2) === 0 &&
          isNumeric
        )
          return;
      } catch {}

      if (handleTextChanged) {
        if (await handleTextChanged(text, getFormattedTextInterno(text))) {
          textoAtual.current = text;
          digitado.current = digitou;
          setVersao(new Date());
        }
      } else {
        textoAtual.current = text;
        digitado.current = digitou;
        setVersao(new Date());
      }
    },
    [getFormattedTextInterno, handleTextChanged, isNumeric, maxLength],
  );

  const sendText = useCallback(
    async (text: string) => {
      await tryChangeText(
        (!digitado.current ? '' : textoAtual.current) + text,
        true,
      );
    },
    [tryChangeText],
  );

  const resetText = useCallback(
    async (text: string) => {
      let newvalue = text;
      if (isNumeric) {
        newvalue = (toDecimal(newvalue, floatCases) / Math.pow(10, floatCases))
          .toFixed(floatCases)
          .replaceAll(',', '')
          .replaceAll('.', '');
      }
      await tryChangeText(newvalue, false);
    },
    [floatCases, isNumeric, tryChangeText],
  );

  const addValue = useCallback(
    async (value: number) => {
      if (isNaN(Number(textoAtual.current))) return;

      const newvalue = (Number(textoAtual.current) + value).toFixed(0);
      await tryChangeText(newvalue, false);
    },
    [tryChangeText],
  );

  const addMultiply = useCallback(
    async (value: number) => {
      let newvalue = value + '*' + textoAtual.current;
      if (textoAtual.current.indexOf('*') > -1) {
        if (textoAtual.current.replace('*', '').length === 0) {
          newvalue = textoAtual.current.replace('*', '');
        } else {
          const novaqtde = parseFloat(textoAtual.current.replace('*', ''));

          newvalue =
            (Number(novaqtde) + Number(value)).toString().replace('.', ',') +
            '*' +
            textoAtual.current.substring(textoAtual.current.indexOf('*') + 1);
        }
      }
      await tryChangeText(newvalue, true);
    },
    [tryChangeText],
  );

  const backSpaceKey = useCallback(async () => {
    if (textoAtual.current.length > 0) {
      await tryChangeText(
        textoAtual.current.substring(0, textoAtual.current.length - 1),
        true,
      );
    }
  }, [tryChangeText]);

  const submitKey = useCallback(async () => {
    if (handleSubmit) {
      if (await handleSubmit()) {
        digitado.current = false;
        textoAtual.current = '';
        setVersao(new Date());
      }
    }
  }, [handleSubmit]);

  const addKey = useCallback(async () => {
    if (textoAtual.current.length === 0) return;

    if (handleAdd) {
      if (await handleAdd()) {
        digitado.current = false;
        textoAtual.current = '';
        setVersao(new Date());
      }
    }
  }, [handleAdd]);

  return (
    <KeyboardContext.Provider
      value={{
        text: textoAtual.current,
        versao,
        getFormattedText,
        sendText,
        resetText,
        addKey,
        submitKey,
        backSpaceKey,
        addValue,
        addMultiply,
      }}
    >
      {children}
    </KeyboardContext.Provider>
  );
};
