import { DefaultIconProps } from "./default-icon/default-icon-props";
import { DefaultIcon } from "./default-icon/default-icon"

export const ConcluidosCanceladosIcon = (props: DefaultIconProps) => {
    return (
        <>
            <DefaultIcon tipo={props.tipo} class={props.class} style={props.style} fill={props.fill}>
                <path d="M15.033 7.35217C11.2828 7.35217 7.75038 8.80387 5.11313 11.4653C2.45169 14.1025 1 17.635 1 21.3852C1 25.1354 2.45169 28.6679 5.11313 31.3051C7.77457 33.9666 11.2828 35.4183 15.033 35.4183C18.7832 35.4183 22.3157 33.9666 24.9529 31.3051C27.6144 28.6437 29.0661 25.1354 29.0661 21.3852C29.0661 17.635 27.6144 14.1025 24.9529 11.4653C22.3157 8.80387 18.7832 7.35217 15.033 7.35217ZM15.033 8.80387C18.3961 8.80387 21.5417 10.1102 23.9369 12.4813C26.3322 14.8524 27.6144 18.0221 27.6144 21.3852C27.6144 24.7483 26.308 27.8938 23.9369 30.2891C21.5417 32.6602 18.3961 33.9666 15.033 33.9666C11.6699 33.9666 8.52442 32.6602 6.12913 30.2891C3.73383 27.918 2.45169 24.7483 2.45169 21.3852C2.45169 18.0221 3.75803 14.8766 6.12913 12.4813C8.50023 10.086 11.6699 8.80387 15.033 8.80387ZM11.7818 17.3931C11.5974 17.3931 11.4159 17.4657 11.2828 17.6109C10.9925 17.9012 10.9925 18.3608 11.2828 18.6269L14.017 21.3852L11.2587 24.1194C10.9684 24.4098 10.9684 24.8693 11.2587 25.1354C11.4039 25.2806 11.5974 25.3533 11.7667 25.3533C11.9361 25.3533 12.1296 25.2806 12.2747 25.1354L15.033 22.4012L17.7673 25.1354C17.9124 25.2806 18.1059 25.3533 18.2752 25.3533C18.4446 25.3533 18.6381 25.2806 18.7832 25.1354C19.0736 24.8451 19.0736 24.3856 18.7832 24.1194L16.049 21.3852L18.7832 18.651C19.0736 18.3607 19.0735 17.9012 18.8073 17.6109C18.517 17.3206 18.0575 17.3206 17.7914 17.6109L15.033 20.3692L12.2988 17.6109C12.1537 17.4657 11.9663 17.3931 11.7818 17.3931Z" stroke={props.fill} stroke-width="0.212766" />
                <path d="M38.3124 18.9093C34.9217 18.9093 31.728 20.2218 29.3436 22.6281C26.9373 25.0125 25.6248 28.2063 25.6248 31.5969C25.6248 34.9876 26.9373 38.1813 29.3436 40.5657C31.7498 42.972 34.9217 44.2845 38.3124 44.2845C41.703 44.2845 44.8968 42.972 47.2812 40.5657C49.6875 38.1595 51 34.9876 51 31.5969C51 28.2063 49.6875 25.0125 47.2812 22.6281C44.8968 20.2218 41.703 18.9093 38.3124 18.9093ZM38.3124 20.2218C41.353 20.2218 44.197 21.4029 46.3626 23.5467C48.5283 25.6904 49.6875 28.5563 49.6875 31.5969C49.6875 34.6376 48.5064 37.4815 46.3626 39.6471C44.197 41.7909 41.353 42.972 38.3124 42.972C35.2717 42.972 32.4278 41.7909 30.2622 39.6471C28.0965 37.5034 26.9373 34.6376 26.9373 31.5969C26.9373 28.5563 28.1184 25.7123 30.2622 23.5467C32.4059 21.381 35.2717 20.2218 38.3124 20.2218Z" stroke={props.fill} stroke-width="0.212766" />
                <path d="M37.2208 34.6643C38.5291 32.8527 39.8261 31.057 41.1232 29.2667C41.3036 29.0177 41.4841 28.7634 41.6645 28.5145C41.8901 28.2073 42.1044 28.1384 42.3469 28.2973C42.5894 28.4562 42.6063 28.6787 42.392 28.9753C40.9258 31.0041 39.4596 33.0381 37.999 35.0669C37.9088 35.194 37.8185 35.3158 37.7283 35.4429C37.373 35.9409 37.373 35.9356 36.9162 35.5383C35.7827 34.553 34.6549 33.5678 33.5214 32.5773C33.3804 32.4554 33.2168 32.3389 33.2394 32.1217C33.2563 31.9575 33.3466 31.841 33.5045 31.7721C33.7131 31.6821 33.8823 31.7615 34.0345 31.894C34.7789 32.5402 35.5233 33.1917 36.2621 33.838C36.5835 34.1134 36.8937 34.3835 37.2208 34.6643Z" stroke={props.fill} stroke-width="0.804537" />
            </DefaultIcon>
        </>
    );
};
