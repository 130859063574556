import { DefaultIconProps } from "./default-icon/default-icon-props";
import { DefaultIcon } from "./default-icon/default-icon"

export const SelecionarSetoresIcon = (props: DefaultIconProps) => {
    return (
        <>
            <DefaultIcon tipo={props.tipo} class={props.class} style={props.style} fill={props.fill}>
                <path d="M44.6309 18.3117C40.5953 18.3117 36.5505 18.2932 32.515 18.3302C31.9119 18.3302 31.7728 18.1821 31.7728 17.5897C31.8006 13.535 31.7913 9.47104 31.7821 5.41636C31.7821 4.28698 31.4852 4 30.3627 4C26.0581 4 21.7535 4 17.4396 4C13.135 4 8.83044 4 4.51657 4C3.2456 4 3.03223 4.21292 3.03223 5.49042C3.03223 14.0626 3.03223 22.6256 3.03223 31.1978C3.03223 32.4291 3.29199 32.6883 4.53512 32.6883C8.57068 32.6883 12.6155 32.7068 16.6511 32.6698C17.2541 32.6698 17.3932 32.8179 17.3932 33.4103C17.3654 37.4372 17.3747 41.4734 17.3747 45.5003C17.3747 46.7315 17.6437 47 18.8776 47C27.4682 47 36.0495 47 44.6402 47C45.9111 47 46.1245 46.7871 46.1245 45.5096C46.1245 36.9374 46.1245 28.3744 46.1245 19.8022C46.1338 18.5709 45.874 18.3117 44.6309 18.3117ZM44.1299 44.2228C44.1299 44.8153 43.9908 44.9912 43.3785 44.9819C35.6506 44.9634 27.9228 44.9541 20.1949 44.9819C19.527 44.9819 19.4249 44.769 19.4249 44.1765C19.4528 40.3625 19.4342 36.5485 19.4435 32.7346C19.4435 32.5031 19.3971 32.2624 19.5084 32.031C19.6197 31.7903 20.13 31.6977 19.8424 31.3367C19.4899 30.9109 19.0538 30.5406 18.6456 30.161C18.6085 30.1333 18.4694 30.1518 18.4323 30.1981C17.9406 30.8738 17.217 30.6146 16.5768 30.6146C12.968 30.6332 9.35923 30.6146 5.75043 30.6424C5.18452 30.6424 5.03609 30.4943 5.04536 29.9389C5.0732 26.0693 5.05464 22.1998 5.05464 18.3302C5.05464 14.4885 5.0732 10.6467 5.04536 6.80495C5.04536 6.18471 5.18452 5.99957 5.83392 5.99957C13.5618 6.02734 21.2896 6.02734 29.0175 5.99957C29.6483 5.99957 29.7504 6.19397 29.7504 6.76792C29.7225 10.6097 29.7411 14.4515 29.7318 18.2932C29.7318 18.5061 29.7225 18.7191 29.6762 18.9227C29.6205 19.1912 29.0639 19.2837 29.3143 19.617C29.6483 20.0614 30.0844 20.4224 30.4925 20.8112C30.576 20.8945 30.7059 20.8667 30.7709 20.7742C31.1698 20.191 31.7728 20.3761 32.3109 20.3761C36.0032 20.3668 39.6955 20.3854 43.3785 20.3576C43.9815 20.3483 44.1299 20.5057 44.1299 21.1074C44.1114 28.8095 44.1114 36.5115 44.1299 44.2228Z" />
                <path d="M10.2035 12.7851C10.2035 14.9605 10.2128 17.1082 10.1942 19.2559C10.1942 19.6077 10.3798 20.1909 10.0736 20.2835C9.53554 20.4501 8.91398 20.376 8.32952 20.339C8.07903 20.3298 8.16253 20.0428 8.16253 19.8761C8.15325 16.4324 8.16253 12.9887 8.15325 9.55429C8.15325 9.20252 8.26458 9.09143 8.60783 9.09143C12.0589 9.10069 15.51 9.10069 18.9518 9.09143C19.3415 9.09143 19.4342 9.21178 19.4435 9.59132C19.4992 11.1558 19.5177 11.1465 17.9314 11.1558C15.9275 11.1558 13.9236 11.1651 11.9198 11.1743C11.9012 11.1743 11.8919 11.2113 11.7621 11.3965C12.5228 12.1186 13.3299 12.8406 14.0999 13.5997C16.2986 15.7752 18.4787 17.9784 20.6959 20.1446C21.1134 20.5519 21.1691 20.7648 20.7052 21.1907C19.7218 22.1071 19.7497 22.1349 18.822 21.2092C16.178 18.5709 13.534 15.9233 10.8807 13.2942C10.7137 13.1091 10.5839 12.8499 10.2035 12.7851Z" />
                <path d="M37.4411 39.6128C36.8752 39.1036 36.2908 38.6037 35.7434 38.0668C33.2942 35.6414 30.8729 33.1975 28.4145 30.7813C28.0434 30.4203 28.0248 30.2259 28.4052 29.8556C29.4257 28.8651 29.4071 28.8466 30.3998 29.8371C33.0252 32.4569 35.6599 35.0767 38.2853 37.6965C38.4616 37.8724 38.6564 38.0483 38.9626 38.3353C38.9626 35.9654 38.9997 33.7622 38.944 31.5589C38.9255 30.8091 39.0925 30.4666 39.881 30.6147C40.1129 30.661 40.3634 30.6425 40.6046 30.6147C40.9293 30.5869 41.0128 30.7258 41.0128 31.0313C41.0035 34.5028 41.0035 37.965 41.0128 41.4365C41.0128 41.7975 40.8829 41.8901 40.549 41.8901C37.0979 41.8808 33.6468 41.8808 30.1957 41.8901C29.7968 41.8901 29.7226 41.7419 29.7133 41.3809C29.6576 39.8257 29.6483 39.8257 31.2347 39.8257C33.2571 39.8257 35.2796 39.8257 37.2927 39.8257C37.3484 39.7609 37.3947 39.6868 37.4411 39.6128Z" />
                <path d="M27.8208 20.839C28.2754 21.2926 28.7485 21.6814 29.1103 22.1535C29.3515 22.4775 28.8506 22.5794 28.73 22.8108C28.7114 22.8571 28.6557 22.8848 28.6186 22.9219C27.4497 24.0883 27.4497 24.079 26.3643 22.8756C26.1509 22.6442 26.086 22.496 26.3643 22.2554C26.7632 21.9036 27.125 21.5148 27.5054 21.1352C27.5981 21.0519 27.6909 20.9593 27.8208 20.839Z" />
                <path d="M23.0987 28.5131C22.8482 28.7353 22.5792 28.9575 22.3379 29.1982C21.3638 30.1609 21.3824 30.1424 20.4083 29.2259C19.9908 28.8279 19.8795 28.5502 20.4083 28.2076C20.4918 28.1521 20.5567 28.0595 20.6402 27.9855C20.9835 27.6707 21.3453 26.9949 21.6514 27.0968C22.1524 27.2727 22.5142 27.8374 22.9317 28.2447C22.9873 28.3095 23.0152 28.3835 23.0987 28.5131Z" />
                <path d="M26.0952 25.3657C26.0766 25.5138 24.5552 27.0227 24.4624 26.9765C23.9151 26.6895 23.5069 26.2174 23.1544 25.736C22.9317 25.4305 23.4327 25.3009 23.5718 25.0695C23.6275 24.9769 23.711 24.9028 23.7945 24.8473C24.147 24.5788 24.3975 23.9216 24.7871 24.0604C25.2695 24.2363 25.6128 24.7825 26.0024 25.1713C26.0581 25.2268 26.0674 25.3102 26.0952 25.3657Z" />
            </DefaultIcon>
        </>
    );
}; 
