import { DefaultIconProps } from './default-icon/default-icon-props';
import { DefaultIcon } from './default-icon';

export const CancelIcon = (props: DefaultIconProps) => {
    return (
        <>
            <DefaultIcon tipo={props.tipo} class={props.class} style={props.style} fill={props.fill}>
                <path d="M25 0C18.319 0 12.0259 2.58621 7.32759 7.32759C2.58621 12.0259 0 18.319 0 25C0 31.681 2.58621 37.9741 7.32759 42.6724C12.069 47.4138 18.319 50 25 50C31.681 50 37.9741 47.4138 42.6724 42.6724C47.4138 37.931 50 31.681 50 25C50 18.319 47.4138 12.0259 42.6724 7.32759C37.9741 2.58621 31.681 0 25 0ZM25 2.58621C30.9914 2.58621 36.5952 4.91346 40.8624 9.13759C45.1296 13.3617 47.4138 19.0086 47.4138 25C47.4138 30.9914 45.0865 36.5952 40.8624 40.8624C36.5952 45.0865 30.9914 47.4138 25 47.4138C19.0086 47.4138 13.4048 45.0865 9.13759 40.8624C4.87035 36.6383 2.58621 30.9914 2.58621 25C2.58621 19.0086 4.91346 13.4048 9.13759 9.13759C13.3617 4.87035 19.0086 2.58621 25 2.58621ZM19.208 17.8879C18.8793 17.8879 18.556 18.0174 18.319 18.276C17.8017 18.7933 17.8017 19.6119 18.319 20.086L23.19 25L18.276 29.871C17.7588 30.3883 17.7588 31.2069 18.276 31.681C18.5347 31.9397 18.8793 32.0691 19.181 32.0691C19.4828 32.0691 19.8274 31.9397 20.086 31.681L25 26.81L29.871 31.681C30.1296 31.9397 30.4743 32.0691 30.776 32.0691C31.0778 32.0691 31.4224 31.9397 31.681 31.681C32.1983 31.1638 32.1983 30.3452 31.681 29.871L26.81 25L31.681 20.129C32.1983 19.6117 32.1981 18.7933 31.724 18.276C31.2067 17.7588 30.3881 17.7588 29.914 18.276L25 23.19L20.129 18.276C19.8704 18.0174 19.5366 17.8879 19.208 17.8879Z" />
            </DefaultIcon>
        </>
    );
};
