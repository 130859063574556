import { PublicPageHeader } from "../../../components/headers/header-public-page/header-public-page";
import { Navbar } from "../../../components/nav";
import { useStyles } from "./confirmar-entrega-page-style";
import { ConfirmarEntregaData } from "./components/confirmar-entrega-data";
import { useThemeQueries } from "../../../theme";
import { useCallback } from "react";
import { useHistory } from "react-router";

const ConfirmarEntregaPage = () => {
    const classes = useStyles();
    const { isMobile } = useThemeQueries();
    const { push } = useHistory();

    const voltarPagina = useCallback(() => {
        push('/')
    }, [push])

    return (
        <>
            <PublicPageHeader topOpacity="0.8" />
            <div className={classes.divRoot}>
                <Navbar
                    mobile={isMobile}
                    voltar={voltarPagina}
                    titulo="Confirmar Entrega"
                />
                <div className={classes.root}>
                    <ConfirmarEntregaData />
                </div>
            </div>
        </>
    )
}

export default ConfirmarEntregaPage;