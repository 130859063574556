import { Grid, GridSize } from "@material-ui/core"
import { CardProcesso } from "../../../../../components/cards/card-processo/card-processo"
import { ProcessosListProps } from "./concluidos-cancelados-processos-list-props"

export const ConcluidosCanceladosProcessosList = ({ processos, getProcessoProdutoSetorWrapper, produtosSetor, ...props }: ProcessosListProps) => {
    return (
        <>
            {processos?.sort((a, b) => a.processoProducao - b.processoProducao)?.map((processo, index, count) => {

                const produtosProcessos = produtosSetor.filter(produto => produto.processoProducaoAtual === processo.processoProducao)


                const processosqtd = processos?.length ?? 1;
                let divisoes = parseInt((12 / processosqtd).toString());
                if (divisoes < 3)
                    divisoes = 3;

                let size: GridSize = (divisoes as any);

                return (
                    <>
                        <Grid item xs={12} md={processosqtd === 1 ? 12 : 6} lg={size}>
                            <CardProcesso
                                count={count}
                                getProcessoProdutoSetorWrapper={getProcessoProdutoSetorWrapper}
                                processo={processo}
                                processosqtd={processosqtd}
                                produtosProcessos={produtosProcessos}
                                setProcessosSelecionados={props.setProcessosSelecionados}
                            />
                        </Grid>

                    </>
                )
            })}
        </>
    )
}