import { useCallback } from "react";
import { useApiBase } from "../../base";
import { VariaveisAmbiente } from "../../../../config";

export function useGetPerfilById() {
    const { invocarApi, ...outrasPropriedades } = useApiBase();

    const getPerfilById = useCallback(
        (id: string) =>
            invocarApi({
                url: `/${VariaveisAmbiente.gatewayVersion}/perfil/${id}`,
                method: "GET",
                enviarTokenUsuario: true,
            }),
        [invocarApi]
    );


    return {
        ...outrasPropriedades,
        getPerfilById,
    };

    
}
