import { useStyles } from './button-keyboard-top-multiply-styles';
import { ButtonKeyboardString } from '../button-keyboard-string/button-keyboard-string';

interface ButtonKeyboardTopMultiplyProps {
  handleButtonMultiplyValue: (value: string) => void;
}

export const ButtonKeyboardTopMultiply = ({
  handleButtonMultiplyValue,
}: ButtonKeyboardTopMultiplyProps) => {
  const classes = useStyles();

  return (
    <div className={classes.setarValorPadraoContainer}>
      <div className={classes.setarValorPadraoItem}>
        <ButtonKeyboardString
          prefix="x"
          textVariant="body1"
          label="2"
          clickFunc={handleButtonMultiplyValue}
          value="2"
        />
      </div>
      <div className={classes.setarValorPadraoItem}>
        <ButtonKeyboardString
          prefix="x"
          textVariant="body1"
          label="5"
          clickFunc={handleButtonMultiplyValue}
          value="5"
        />
      </div>
      <div className={classes.setarValorPadraoItem}>
        <ButtonKeyboardString
          prefix="x"
          textVariant="body1"
          label="10"
          clickFunc={handleButtonMultiplyValue}
          value="10"
        />
      </div>
      <div className={classes.setarValorPadraoItem}>
        <ButtonKeyboardString
          prefix="x"
          textVariant="body1"
          label="20"
          clickFunc={handleButtonMultiplyValue}
          value="20"
        />
      </div>
      <div className={classes.setarValorPadraoItem}>
        <ButtonKeyboardString
          prefix="x"
          textVariant="body1"
          label="50"
          clickFunc={handleButtonMultiplyValue}
          value="50"
        />
      </div>
    </div>
  );
};
