import { useCallback } from "react";
import { VariaveisAmbiente } from "../../../../config";
import { useApiBase } from "../../base/api-base";
import { PostPedidosResumoVarios } from "../../../../model/api/pedidos/post-pedido-resumo-varios";

export function usePostPedidoResumoVarios() {
    const { invocarApi, ...outrasPropriedades } = useApiBase();

    const postPedidoResumoVarios = useCallback(
        (empresaId: string, model: PostPedidosResumoVarios) => {
        return invocarApi({
            url: `/${VariaveisAmbiente.pedidosVersion}/empresa/${empresaId}/pedido-salao/resumo/varios`,
            method: "POST",
            baseURL: `${VariaveisAmbiente.apiPedidosUrl}/api`,
            data: model,
            enviarTokenUsuario: true,
        });
    },
        [invocarApi]
    );

    return {
        ...outrasPropriedades,
        postPedidoResumoVarios,
    };
}