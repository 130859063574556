import { DefaultIconProps } from './default-icon/default-icon-props';
import { DefaultIcon } from './default-icon/default-icon';
export const AddIcon = (props: DefaultIconProps) => {
  return (
    <>
      <DefaultIcon
        tipo={props.tipo}
        class={props.class}
        style={props.style}
        fill={props.fill}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M25 46C36.598 46 46 36.598 46 25C46 13.402 36.598 4 25 4C13.402 4 4 13.402 4 25C4 36.598 13.402 46 25 46ZM36.065 26.947C36.1177 26.8415 36.1177 26.7038 36.1177 26.4285V23.5715C36.1177 23.2962 36.1177 23.1585 36.065 23.053C36.0166 22.9561 35.9381 22.8776 35.8412 22.8292C35.7357 22.7765 35.598 22.7765 35.3227 22.7765H28.0186C27.7433 22.7765 27.6057 22.7765 27.5001 22.7237C27.4033 22.6753 27.3247 22.5968 27.2764 22.5C27.2236 22.3944 27.2236 22.2568 27.2236 21.9815V14.6774C27.2236 14.402 27.2236 14.2644 27.1709 14.1589C27.1225 14.062 27.044 13.9835 26.9471 13.9351C26.8416 13.8824 26.7039 13.8824 26.4286 13.8824H23.5716C23.2963 13.8824 23.1586 13.8824 23.0531 13.9351C22.9562 13.9835 22.8777 14.062 22.8293 14.1589C22.7766 14.2644 22.7766 14.402 22.7766 14.6774V21.9815C22.7766 22.2568 22.7766 22.3944 22.7238 22.5C22.6754 22.5968 22.5969 22.6753 22.5001 22.7237C22.3945 22.7765 22.2569 22.7765 21.9816 22.7765H14.6774C14.4021 22.7765 14.2645 22.7765 14.159 22.8292C14.0621 22.8776 13.9836 22.9561 13.9352 23.053C13.8824 23.1585 13.8824 23.2962 13.8824 23.5715V26.4285C13.8824 26.7038 13.8824 26.8415 13.9352 26.947C13.9836 27.0439 14.0621 27.1224 14.159 27.1708C14.2645 27.2235 14.4021 27.2235 14.6774 27.2235H21.9816C22.2569 27.2235 22.3945 27.2235 22.5001 27.2763C22.5969 27.3247 22.6754 27.4032 22.7238 27.5C22.7766 27.6056 22.7766 27.7432 22.7766 28.0185V35.3226C22.7766 35.598 22.7766 35.7356 22.8293 35.8411C22.8777 35.938 22.9562 36.0165 23.0531 36.0649C23.1586 36.1176 23.2963 36.1176 23.5716 36.1176H26.4286C26.7039 36.1176 26.8416 36.1176 26.9471 36.0649C27.044 36.0165 27.1225 35.938 27.1709 35.8411C27.2236 35.7356 27.2236 35.598 27.2236 35.3226V28.0185C27.2236 27.7432 27.2236 27.6056 27.2764 27.5C27.3247 27.4032 27.4033 27.3247 27.5001 27.2763C27.6057 27.2235 27.7433 27.2235 28.0186 27.2235H35.3227C35.598 27.2235 35.7357 27.2235 35.8412 27.1708C35.9381 27.1224 36.0166 27.0439 36.065 26.947Z"
        />
      </DefaultIcon>
    </>
  );
};
