import { useCallback } from 'react';
import { EnumMenu } from '../../../model';
import {
  ConcluidosCanceladosIcon,
  LogoutIcon,
  MonitoramentoIcon,
  ProcurarIcon,
  SelecionarSetoresIcon,
} from '../icons';
import { IconsHelperProps } from './icons-helper-props';
import { GarcomIcon } from '../icons/garcom-icon';

export const IconsHelper = (props: IconsHelperProps) => {
  const selectIcon = useCallback(() => {
    switch (props.codigoModulo) {
      case EnumMenu.MONITORAMENTO:
        return <MonitoramentoIcon {...props} />;
      case EnumMenu.CONFIGURARSETORES:
        return <SelecionarSetoresIcon {...props} />;
      case EnumMenu.CONCLUIDOSCANCELADOS:
        return <ConcluidosCanceladosIcon {...props} />;
      case EnumMenu.PESQUISAAVANCADA:
        return <ProcurarIcon {...props} />;
      case EnumMenu.ENTREGALOCAL:
        return <GarcomIcon {...props} />;
      case EnumMenu.ENTREGADELIVERY:
        return <GarcomIcon {...props} />;
      case EnumMenu.MASTER_SAIR:
        return <LogoutIcon {...props} />;
    }
  }, [props]);

  return <>{selectIcon()}</>;
};
