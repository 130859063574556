import { DefaultIconProps } from "./default-icon/default-icon-props";
import { DefaultIcon } from "./default-icon/default-icon"
export const EditarIcon = (props: DefaultIconProps) => {
    return (
        <>
            <DefaultIcon tipo={props.tipo} class={props.class} style={props.style} fill={props.fill}>
                <path d="M46.0875 11.7643C43.7758 9.50255 41.4974 7.22417 39.2357 4.91252C38.4541 4.11425 37.6225 3.41576 36.5748 3C36.3087 3 36.0426 3 35.7765 3C34.8951 3.29935 34.0802 3.66522 33.3984 4.34708C25.6319 12.1468 17.8321 19.9133 10.0657 27.713C9.58338 28.1953 9.20088 28.6942 8.98468 29.3428C7.0389 35.0139 4.31149 40.4022 3.08082 46.3226C2.78147 47.6863 3.31365 48.2185 4.67736 47.9192C5.8415 47.4701 7.10542 47.5367 8.26956 47.0876C11.7287 45.7406 15.1879 44.3769 18.6969 43.1961C21.2747 42.3313 23.3868 40.9676 25.2993 39.0052C32.1178 32.0203 39.0694 25.1519 46.0043 18.2502C46.8359 17.4353 47.5842 16.5872 48 15.4896C48 15.1403 48 14.7744 48 14.4252C47.5842 13.3775 46.8857 12.5459 46.0875 11.7643ZM20.3434 39.8201C17.3332 40.9676 14.3065 42.0486 11.2963 43.2127C10.4981 43.5287 10.0823 43.2792 9.4337 42.797C7.62097 41.4166 7.43803 39.9531 8.41924 37.9408C9.51686 35.6791 10.2486 33.251 11.1633 30.9061C11.313 30.507 11.3629 30.0413 11.9616 29.7087C12.2942 30.6566 12.1944 31.4549 11.9117 32.2532C11.762 32.6856 11.7121 33.118 12.1279 33.4672C12.5769 33.8497 13.0259 33.7832 13.4749 33.5005C14.2399 33.0182 15.0715 32.7188 15.9529 32.6357C17.4663 32.486 18.3144 33.2843 18.3144 34.781C18.3144 35.6791 18.0816 36.5272 17.6492 37.3255C17.4164 37.7579 17.1669 38.1903 17.5494 38.6726C17.9319 39.1549 18.4308 39.1549 18.9963 39.0218C19.728 38.8555 20.4764 38.7557 21.2081 39.0883C21.1583 39.7203 20.6593 39.7037 20.3434 39.8201Z" />
            </DefaultIcon>
        </>
    );
};
