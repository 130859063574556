import { Controller, useForm } from "react-hook-form";
import { makeUtilClasses } from "../../../../theme";
import { DefaultFormProps, DefaultFormRefs } from "../../utils";
import React from "react";
import { CircularLoading } from "../../../utils";
import { Button, Grid } from "@material-ui/core";
import { useStyles } from "./form-modo-styles";
import { SelectSaurus } from "../../../controles/selects/select-saurus/select-saurus";
import { ModoAplicacaoMock } from "../../../../../data/mocks/modo-aplicacao-mock";
import { ModuloModel } from "../../../../../model/app/forms/master/modulo-model";

export const FormModulo = React.forwardRef<
  DefaultFormRefs<ModuloModel>,
  DefaultFormProps<ModuloModel>
>((props: DefaultFormProps<ModuloModel>, refs) => {
  const utilClasses = makeUtilClasses();
  const classes = useStyles();

  const {
    handleSubmit,
    control,
    setValue,
    reset,
  } = useForm<ModuloModel>({
    criteriaMode: 'all',
    mode: 'onSubmit' && 'onTouched',
  });

  const onSubmit = (form: ModuloModel) => {
    props.onSubmit(form);
  };

  React.useImperativeHandle(refs, () => ({
    submitForm: () => {
      handleSubmit(onSubmit)();
    },
    resetForm: () => {
      reset();
    },
    fillForm: (model: ModuloModel) => {
      reset();
    },
  }));

  return (
    <>
      <div className={utilClasses.formContainer}>
        {props.loading && props.showLoading ? (
          <CircularLoading tipo="FULLSIZED" />
        ) : null}
        <form
          onSubmit={handleSubmit(onSubmit)}
          className={`${classes.formContainer} ${props.loading ? utilClasses.controlLoading : ''
            }`}
        >
          <Grid container style={{
            maxWidth: '100%',
          }}>
            <Grid item xs={12} style={{
              display: 'flex',
              justifyContent: 'center'
            }}>
              <Controller
                name="modulo"
                control={control}
                render={({ field }) => (
                  <SelectSaurus
                    conteudo={ModoAplicacaoMock}
                    fullWidth
                    variant="outlined"
                    label={'Módulo da Aplicação'}
                    {...field}
                    onChange={(event) => {
                      if (event) {
                        const item = ModoAplicacaoMock.filter(
                          (item) => item.Key === event.target.value,
                        )[0];
                        if (item) {
                          setValue('modulo', item.Key);
                        }
                      }
                    }}
                  />
                )}
              />
            </Grid>
          </Grid>
          <Button style={{ display: 'none' }} type="submit"></Button>
        </form>
      </div>
    </>
  );
});
