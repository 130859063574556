import { useHistory } from "react-router-dom";
import { PublicPageHeader } from "../../../components/headers/header-public-page/header-public-page"
import { Navbar } from "../../../components/nav"
import { useThemeQueries } from "../../../theme";
import { useStyles } from "./leitor-mesa-page-styles";
import { useCallback } from "react";
import { LeitorMesaFragment } from "./components/leitor-mesa-fragment";

const LeitorMesaPage = () => {

    const classes = useStyles();
    const { isMobile } = useThemeQueries();
    const history = useHistory();

    const voltarPagina = useCallback(() => {
        return history.push('/confirmar-entrega')
    }, [history])

    return (
        <>
            <PublicPageHeader topOpacity="0.8" />
            <div className={classes.divRoot}>
                <Navbar
                    mobile={isMobile}
                    voltar={voltarPagina}
                    titulo="Leitor de Mesa"
                />
                <div className={classes.root}>
                    <LeitorMesaFragment/>
                </div>
            </div>
        </>
    )
}

export default LeitorMesaPage