import { DefaultIconProps } from './default-icon/default-icon-props';
import { DefaultIcon } from './default-icon/default-icon';

export const CodeBarIcon = (props: DefaultIconProps) => {
    return (
        <>
            <DefaultIcon
                tipo={props.tipo}
                class={props.class}
                style={props.style}
                fill={props.fill}
            >
                <path d="M6.41216 2.01074C7.38266 2.01074 8.35315 2.00568 9.32365 2.01074C10.0161 2.0158 10.4913 2.44065 10.5064 3.05265C10.5216 3.67476 10.0313 4.14007 9.31859 4.14513C8.09537 4.15525 6.86708 4.16536 5.64385 4.14007C5.2597 4.13502 5.11817 4.221 5.12828 4.63574C5.15355 5.84455 5.14344 7.05842 5.13839 8.26724C5.13333 9.02591 4.69863 9.52663 4.0668 9.51651C3.4198 9.5064 3.01037 9.02085 3.01037 8.25206C3.01037 6.30987 3.01543 4.36768 3.00026 2.42548C2.99521 2.10178 3.06092 1.99051 3.40969 2.00063C4.40546 2.02591 5.41134 2.01074 6.41216 2.01074Z" />
                <path d="M47.9565 5.37418C47.9565 6.36045 47.9615 7.35178 47.9565 8.33805C47.9514 9.02591 47.5218 9.5064 46.9102 9.52157C46.2885 9.53675 45.8285 9.04614 45.8234 8.33299C45.8184 7.08877 45.8083 5.84961 45.8285 4.60539C45.8335 4.2564 45.7476 4.14008 45.3786 4.14513C44.12 4.16536 42.8614 4.16536 41.6078 4.15019C40.8244 4.14007 40.3189 3.53314 40.4857 2.84022C40.5969 2.36985 40.9811 2.02592 41.4764 2.02592C43.5438 2.01074 45.6162 2.02086 47.6835 2.01074C47.9363 2.01074 47.9615 2.11696 47.9615 2.32433C47.9565 3.33589 47.9615 4.35756 47.9565 5.37418Z" />
                <path d="M3.00536 43.5909C3.00536 42.6198 3.0003 41.6487 3.00536 40.6776C3.01041 39.9796 3.42995 39.5092 4.04157 39.489C4.66834 39.4688 5.12832 39.9543 5.13843 40.6675C5.14854 41.8004 5.14348 42.9283 5.14348 44.0613C5.14348 44.8553 5.14348 44.8553 5.95728 44.8553C7.05415 44.8553 8.15101 44.8503 9.25293 44.8553C10.0162 44.8604 10.5115 45.2852 10.5115 45.9225C10.5115 46.5649 10.0212 46.9897 9.25798 46.9897C7.31699 46.9948 5.376 46.9796 3.43501 46.9998C3.05591 47.0049 2.99525 46.8835 3.00536 46.5446C3.02052 45.5685 3.00536 44.5772 3.00536 43.5909Z" />
                <path d="M44.5901 46.9948C43.6499 46.9948 42.7097 46.9948 41.7746 46.9948C40.9608 46.9948 40.4705 46.5952 40.4604 45.9377C40.4503 45.2701 40.9558 44.8553 41.7948 44.8553C42.9877 44.8553 44.1806 44.8401 45.3736 44.8654C45.7274 44.8705 45.8386 44.7795 45.8335 44.4153C45.8133 43.1407 45.8133 41.8662 45.8335 40.5916C45.8436 39.8633 46.435 39.3777 47.0972 39.5092C47.5824 39.6053 47.9514 40.0049 47.9565 40.5157C47.9717 42.5844 47.9615 44.6581 47.9716 46.7267C47.9716 46.9897 47.8503 46.9948 47.6532 46.9948C46.6271 46.9948 45.6111 46.9948 44.5901 46.9948Z" />
                <path d="M16.0817 33.0353C16.0817 27.3301 16.0817 21.6654 16.0817 15.9753C17.9974 15.9753 19.8929 15.9753 21.8137 15.9753C21.8137 21.6704 21.8137 27.3402 21.8137 33.0353C19.8979 33.0353 18.0176 33.0353 16.0817 33.0353Z" />
                <path d="M35.6079 33.0302C34.6627 33.0302 33.7427 33.0302 32.7823 33.0302C32.7823 27.3554 32.7823 21.6856 32.7823 15.9804C33.7124 15.9804 34.6425 15.9804 35.6079 15.9804C35.6079 21.6653 35.6079 27.3351 35.6079 33.0302Z" />
                <path d="M28.3645 33.0353C27.6366 33.0353 26.9492 33.0353 26.2213 33.0353C26.2213 27.3402 26.2213 21.6654 26.2213 15.9703C26.9492 15.9703 27.6366 15.9703 28.3645 15.9703C28.3645 21.6654 28.3645 27.3402 28.3645 33.0353Z" />
                <path d="M12.4525 15.9703C13.1854 15.9703 13.8628 15.9703 14.5654 15.9703C14.5654 21.6654 14.5654 27.3351 14.5654 33.0302C13.8527 33.0302 13.1703 33.0302 12.4525 33.0302C12.4525 27.3402 12.4525 21.6704 12.4525 15.9703Z" />
                <path d="M10.926 33.0351C10.466 33.0351 10.0212 33.0351 9.53592 33.0351C9.53592 27.3501 9.53592 21.6803 9.53592 15.9802C9.99084 15.9802 10.4356 15.9802 10.926 15.9802C10.926 21.65 10.926 27.3299 10.926 33.0351Z" />
                <path d="M23.3401 15.9703C23.8153 15.9703 24.2601 15.9703 24.7352 15.9703C24.7352 21.6654 24.7352 27.3402 24.7352 33.0353C24.255 33.0353 23.8102 33.0353 23.3401 33.0353C23.3401 27.3402 23.3401 21.6654 23.3401 15.9703Z" />
                <path d="M29.8556 15.9753C30.3156 15.9753 30.7402 15.9753 31.2204 15.9753C31.2204 21.6502 31.2204 27.3301 31.2204 33.0353C30.7705 33.0353 30.3307 33.0353 29.8556 33.0353C29.8556 27.3402 29.8556 21.6704 29.8556 15.9753Z" />
                <path d="M38.5042 15.9753C38.5042 21.6654 38.5042 27.3352 38.5042 33.0302C38.0392 33.0302 37.5994 33.0302 37.1192 33.0302C37.1192 27.3453 37.1192 21.6704 37.1192 15.9753C37.5893 15.9753 38.0341 15.9753 38.5042 15.9753Z" />
                <path d="M41.4208 33.0353C40.9406 33.0353 40.4958 33.0353 40.0207 33.0353C40.0207 27.3503 40.0207 21.6805 40.0207 15.9804C40.4857 15.9804 40.9406 15.9804 41.4208 15.9804C41.4208 21.6755 41.4208 27.3402 41.4208 33.0353Z" />
            </DefaultIcon>
        </>
    );
};
