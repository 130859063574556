import { VariaveisAmbiente } from "../../../config"

export const AmbienteFooter = () => {
return (<div  style={{
    position: 'fixed',
    fontSize: '11px',
    textAlign:'right',
    right:10,
    bottom:5,
}}>
<p> Ambiente: <strong> {VariaveisAmbiente.environment} </strong> Versão: <strong> {VariaveisAmbiente.version} </strong>  </p>
</div>)
}