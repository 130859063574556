import { useHistory } from "react-router-dom";
import { PublicPageHeader } from "../../../components/headers/header-public-page/header-public-page";
import { Navbar } from "../../../components/nav";
import { useThemeQueries } from "../../../theme";
import { useCallback } from "react";
import { useStyles } from "./adicionar-pedido-entrega-page-styles";
import { AdicionarPedidoEntregaFragment } from "./components/adicionar-pedido-entrega-fragment";

const AdicionarPedidoEntregaPage = () => {

    const classes = useStyles();
    const { isMobile } = useThemeQueries();
    const history = useHistory();

    const voltarPagina = useCallback(() => {
        return history.push('/pedidos-para-entrega');
    }, [history])

    return (
        <>
            <PublicPageHeader topOpacity="0.8" />
            <div className={classes.divRoot}>
                <Navbar mobile={isMobile} voltar={voltarPagina} titulo="Leitor QRCode" />
                <div className={classes.root}>
                    <AdicionarPedidoEntregaFragment />
                </div>
            </div>
        </>
    )
}

export default AdicionarPedidoEntregaPage;