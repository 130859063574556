import { useEffect, useRef, useState, useCallback } from 'react';
import { useHistory } from 'react-router';

import {
  Box,
  Button,
  FormControlLabel,
  Grid,
  Typography,
  useTheme,
} from '@material-ui/core';

import { useStyles } from './login-page-styles';

import { makeUtilClasses } from '../../../theme/util-make-styles';
import { ApiError } from '../../../../model/app/errors/api-erros';
import { LoginFormModel } from '../../../../model/app/forms/master/login-form';
import { KdsStorageKeys, useKdsStorage, useSessaoAtual, useToastSaurus } from '../../../../services/app';
import { CheckboxDefault } from '../../../../views/components/checkboxes';
import { FormLogin } from '../../../components/form/master/login/form-login';
import { CircularLoading } from '../../../components/utils';
import { DefaultFormRefs } from '../../../components/form/utils/form-default-props';
import { LoginIcon, UserIcon } from '../../../components/icons';
import { PublicPageHeader } from '../../../components/headers/header-public-page/header-public-page';
import { useThemeQueries } from '../../../theme';
import { AmbienteFooter } from '../../../components/ambiente-footer/ambiente-footer';
import { useConfirm } from 'material-ui-confirm';
import { AutenticacaoPendenciaContratoModel } from '../../../../model/api/autenticacao-pendencia/autenticacao-pendencia';
import { useEventTools } from '../../../../services/app/use-cases/events/event-tools';
import { AppEventEnum } from '../../../../model/enums/enum-app-event';

const LoginPage = () => {
  const utilClasses = makeUtilClasses();
  const { isMobile } = useThemeQueries();
  const classes = useStyles();
  const { showToast } = useToastSaurus();
  const history = useHistory();
  const theme = useTheme();
  const { logar, carregando, usuario } = useSessaoAtual();
  const [bloqueado, setBloqueado] = useState(false);
  const chkManterConectado = useRef<HTMLInputElement>();
  const loginRefs = useRef<DefaultFormRefs<LoginFormModel>>(null);
  const { setRegistro } = useKdsStorage()
  const confirm = useConfirm()
  const { addHandler, removeHandler } = useEventTools();

  useEffect(() => {
    if (loginRefs) loginRefs.current?.fillForm(new LoginFormModel('', ''));
  }, [usuario]);

  //logar funçao
  const efetuarLogin = async (model: LoginFormModel) => {
    try {
      const manterConectado =
        chkManterConectado.current?.querySelector('input')?.checked === true;
      const res: any = await logar(model.usuario, model.senha, '', manterConectado);
      if (res?.status) {
        if (res.status === 402) {
          setRegistro(KdsStorageKeys.PendenciaContrato, res?.data as AutenticacaoPendenciaContratoModel)
          return history.push('/pendencias')
        }
      }

      if (typeof res !== 'string') {
        return history.push({
          pathname: '/selecionar-contrato',
          state: {
            email: model.usuario,
            senha: btoa(model.senha),
            contratos: res
          }
        })
      }

      setBloqueado(true);
      return history.push('/');
    } catch (e: any) {
      if (e.constructor.name === ApiError.name) {
        history.push(`/ativar-email?email=${model.usuario}`);
        return;
      } else {
        showToast('error', e.message);
        loginRefs.current?.resetForm();
      }
    }
  };

  const redirectToLanding = useCallback(() => {
    history.push('/');
  }, [history]);

  useEffect(() => {
    if (usuario) {
      if (usuario.confirmado !== 'True') {
        confirm({
          title: 'E-mail não confirmado.',
          description: `Atenção, o e-mail "${usuario.email}" ainda não foi confirmado. Pedimos para que seja confirmado antes que o acesso seja bloqueado.`,
          confirmationButtonProps: { variant: 'contained' },
          confirmationText: 'OK',
        }).then(() => {
          redirectToLanding();
        });
        return;
      }
    }
  }, [usuario, confirm, redirectToLanding]);

  useEffect(() => {
    addHandler(AppEventEnum.LoginPush, () => {
      history.push('/')
    })
    return () => {
      removeHandler(AppEventEnum.LoginPush, () => {
        history.push('/')
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addHandler, removeHandler])

  return (
    <>
      <PublicPageHeader topOpacity="0.8" />

      <div className={utilClasses.contentCenter}>
        <Grid container className={utilClasses.rootContainer}>
          {carregando || bloqueado ? (
            <CircularLoading tipo="FULLSIZED" />
          ) : null}
          <Grid item xs={12}>
            <Grid container alignItems="center" direction="column">
              <Grid item xs={10} md={4}>
                <Typography align="center" variant="h3" color="primary">
                  <UserIcon tipo="GERAL" class={classes.imgTitulo} />
                  Login
                </Typography>
                <Box mt={3}>
                  <Typography align="center" variant="body1">
                    Olá, seja muito bem vindo(a) ao Acompanhamento de Processos (KDS)! Informe seu e-mail ou apelido para ingressar.
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} md={8} lg={6} style={{ width: '100%' }}>
                <FormLogin
                  showLoading={false}
                  ref={loginRefs}
                  loading={carregando}
                  onSubmit={(model) => {
                    efetuarLogin(model);
                  }}

                />
                <Grid item xs={12}>
                  <Grid
                    container
                    direction={isMobile ? 'column' : 'row-reverse'}
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Box>
                      <FormControlLabel
                        disabled={carregando || bloqueado}
                        ref={chkManterConectado}
                        control={<CheckboxDefault />}
                        label="Mantenha-me conectado"
                      />
                    </Box>
                  </Grid>
                </Grid>
                <Box mt={4}>
                  <Grid item xs={12}>
                    <Button
                      type="submit"
                      color="primary"
                      variant="contained"
                      fullWidth={true}
                      disabled={carregando || bloqueado}
                      onClick={() => {
                        loginRefs.current?.submitForm();
                      }}
                    >
                      <LoginIcon tipo="BUTTON_PRIMARY" />
                      Acessar
                    </Button>
                  </Grid>
                </Box>
                <Grid item xs={12}>
                  <Box my={1}>
                    <Grid
                      container
                      justifyContent="center"
                      alignItems="center"
                      style={{ color: theme.palette.divider }}
                    >
                    </Grid>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
      <AmbienteFooter />
    </>
  );
};

export default LoginPage;
