export class Produto {
   constructor(
      public vendedor: string = '',
      public cliente: string = '',
      public operador: string = '',
      public descricao: string = '',
      public observacao: string = '',
      public codigoBarra: string = '',
      public codigoReferencia: string = '',
      public quantidade: number = 0,
      public valorTotal: number = 0,
      public valorDesconto: number = 0,
      public valorUnitario: number = 0,
      public valorAdicional: number = 0,
      public valorTotalSubItens: number = 0,
      public inclusos: Produto[] = [],
      public adicionais: Produto[] | null = [],
      public subItens: Produto[] | null = [],
      public groupId: string | null = null,
      public indFin: boolean = true,
      public adicionalId: string = '',
      public pedidoId: string = '',
      public produtoId: string = ''
   ) { }
}