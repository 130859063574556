import { Box, Card, Divider, Grid, Typography } from "@material-ui/core"
import { CardInfoConfirmarEntregaProps } from "./card-info-confirmar-entrega-props";
import { isEmpty } from "lodash";
import { useStyles } from "./card-info-confirmar-entrega-styles";
import { formatarCPFCNPJ } from "../../../../utils/cpfcnpj-format";
import { toDateString } from "../../../../utils/to-date";
import { pegaHora } from "../../../../utils/pegar-data";
import { CardProdutosConfirmarEntrega } from "../card-produtos-confirmar-entrega/card-produtos-confirmar-entrega";
import { KdsStorageKeys, useKdsStorage } from "../../../../services/app";
import { ModuloModel } from "../../../../model/app/forms/master/modulo-model";
import { EnumModulo } from "../../../../model/enums/enum-modulo";
import { PedidoEnderecoModel } from "../../../../model/api/pedidos/pedido-model";
import { useMemo } from "react";

export const CardInfoConfirmarEntrega = (props: CardInfoConfirmarEntregaProps) => {

    const classes = useStyles();
    const { getRegistro } = useKdsStorage();

    const tipoTrabalho = getRegistro(KdsStorageKeys.SelecaoModulo, false) as ModuloModel;

    const retornaEndereco = useMemo(() => {

        const { bairro, municipio, numero, complemento, logradouro, uf } = props.model.pedido?.enderecoEntrega ?? new PedidoEnderecoModel()

        if (isEmpty(props.model.pedido?.enderecoEntrega)) {
            return ''
        }

        return `${logradouro ?? ''}, ${numero ?? 0} - ${bairro ?? ''}${municipio ? `, ${municipio}` : ''}${uf ? ` - ${uf}` : ''}${complemento ? `, ${complemento}` : ''}`
    }, [props.model.pedido?.enderecoEntrega])

    return (
        <>
            <Card className={classes.container}>
                <Grid container spacing={2} style={{
                    height: '400px',
                    overflowY: 'auto',
                }}>
                    <Grid item xs={12}>
                        <Grid container style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between'
                        }}>
                            <div>
                                <Typography
                                    variant="h6"
                                    color="textPrimary"
                                >
                                    <Box fontWeight={700}>
                                        {`Pedido N° ${props.model.codigoPedido}`}
                                    </Box>
                                </Typography>

                            </div>
                            <div>
                                <Typography
                                    variant="body2"
                                    color="primary"
                                >
                                    <Box fontWeight={500}>
                                        {toDateString(new Date(props.model.dataPedido))} - {pegaHora(props.model.dataPedido)}
                                    </Box>
                                </Typography>
                            </div>
                        </Grid>
                        <Divider variant="fullWidth" className={classes.divider} />
                    </Grid>
                    {tipoTrabalho.modulo === EnumModulo.EntregaLocal && (
                        <Grid item xs={12}>
                            <Grid container style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                width: '100%'
                            }}>
                                <div>
                                    <Typography
                                        variant="body2"
                                        color="textSecondary"
                                    >
                                        Comanda
                                    </Typography>
                                    <Typography
                                        variant="body1"
                                        color="textPrimary"
                                    >
                                        {`Nº ${!isEmpty(props.model.codigoComanda) ? props.model.codigoComanda : '-'}`}
                                    </Typography>
                                </div>
                                <div>
                                    <Typography
                                        variant="body2"
                                        color="textSecondary"
                                    >
                                        Mesa
                                    </Typography>
                                    <Typography
                                        variant="body1"
                                        color="textPrimary"
                                    >
                                        {`Nº ${!isEmpty(props.model.codigoMesa) ? props.model.codigoMesa : '-'}`}
                                    </Typography>
                                </div>
                                <div style={{
                                    minWidth: '70px',
                                    maxWidth: '70px'
                                }}>
                                    <Typography
                                        variant="body2"
                                        color="textSecondary"
                                    >
                                        Salão
                                    </Typography>
                                    <Typography
                                        variant="body1"
                                        color="textPrimary"
                                    >
                                        {!isEmpty(props.model.nomeSalao) ? props.model.nomeSalao : 'Sem Salão'}
                                    </Typography>
                                </div>
                            </Grid>
                        </Grid>
                    )}
                    <Grid item xs={12}>
                        <Grid container style={{
                            display: 'flex',
                            justifyContent: 'space-between'
                        }}>
                            <div>
                                <Typography
                                    variant="body2"
                                    color='textSecondary'
                                >
                                    Cliente
                                </Typography>
                                <Typography
                                    variant="body1"
                                    color="textPrimary"
                                >
                                    <Box fontWeight={600}>
                                        {!isEmpty(props.model.nomeCliente) ? props.model.nomeCliente : 'Sem Cliente'}
                                    </Box>
                                </Typography>
                            </div>
                            <div>
                                <Typography
                                    variant="body2"
                                    color="textSecondary"
                                >
                                    CPF/CNPJ
                                </Typography>
                                <Typography
                                    variant="body1"
                                    color="textPrimary"
                                >
                                    {formatarCPFCNPJ(props.model.documentoClinte)}
                                </Typography>
                            </div>
                        </Grid>
                    </Grid>

                    {tipoTrabalho.modulo === EnumModulo.EntregaDelivery && (
                        <Grid item xs={12}>
                            <Typography
                                variant="body2"
                                color="textSecondary"
                            >
                                Endereço
                            </Typography>
                            <Typography
                                variant="body1"
                                color="textPrimary"
                            >
                                {retornaEndereco.length <= 5 ? 'Sem Endereço' : retornaEndereco}
                            </Typography>
                        </Grid>
                    )}

                    <Grid item xs={12}>
                        <CardProdutosConfirmarEntrega
                            produtos={props.model.produtos}
                        />
                    </Grid>
                </Grid>
            </Card>
        </>
    )
}