import { Divider, Grid, Typography } from "@material-ui/core";
import { isEmpty, isEqual } from "lodash";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { useGetProcessoProdutoSetor } from "../../../../data/api/gestao/processo-produto-setor/get-processo-produto-setor";
import { ProcessoProdutoSetorModel } from "../../../../model/api/processo-produto-setor/processo-produto-setor-model";
import { ProcessoSetorModel } from "../../../../model/api/processo-setor/processo-setor-model";
import { KdsStorageKeys, useKdsStorage, useToastSaurus } from "../../../../services/app";
import { useSessaoAtual } from "../../../../services/app/providers/sessao-atual-provider";
import { CircularLoading } from "../../../components";
import { PublicPageHeader } from "../../../components/headers/header-public-page/header-public-page";
import { Navbar } from "../../../components/nav";
import { MonitoramentoSetoresProcessosList } from "./components/monitoramento-setores-processos-list/monitoramento-setores-processos-list";
import { useStyles } from "./monitoramento-setores-styles";
import { EnumStatusPedido } from "../../../../model/enums/enum-status-pedido";
import { useThemeQueries } from "../../../theme";
import { PedidoModel } from "../../../../model/api/pedidos/pedido-model";
import { useGetPedido } from "../../../../data/api/gestao/pedido/get-pedido";
import { DialogQRCodeEntrega } from "../../../components/dialog/dialog-qr-code-entrega/dialog-qr-code-entrega";
import { AdicionarRemoverProcessos } from "../../../../model/app/forms/adicionar-remover-processos/adicionar-remover-processos";

const MonitoramentoSetoresPage = () => {

   //AUX
   const history = useHistory()
   const { showToast } = useToastSaurus()
   const classes = useStyles();
   const { theme } = useThemeQueries();

   //PROVIDERS
   const { getRegistro } = useKdsStorage()
   const { getEmpresaSelecionada } = useSessaoAtual()
   const setoresStorage = getRegistro(KdsStorageKeys.ProcessosSetores, false) as ProcessoSetorModel[]

   //CHAMADAS API
   const { getProcessoProdutoSetor, carregando: carregandoProduto } = useGetProcessoProdutoSetor()
   const { getPedido, carregando: carregandoPedido } = useGetPedido();

   const carregando = carregandoPedido || carregandoProduto;

   //STATES E REFS
   const carregouRef = useRef<boolean>(false)
   const [pedidos, setPedidos] = useState<PedidoModel[]>([]);
   const [produtos, setProdutos] = useState<Array<ProcessoProdutoSetorModel>>([])
   const [abrirQrCode, setAbrirQrCode] = useState<boolean>(false);
   const [processosSelecionados, setProcessosSelecionados] = useState<AdicionarRemoverProcessos[]>([]);

   const handleClose = useCallback(() => {
      setAbrirQrCode(false);
      setProcessosSelecionados([]);
   }, [])

   const setores: ProcessoSetorModel[] = useMemo(() => isEmpty(setoresStorage) ? [] : setoresStorage, [setoresStorage])

   const getPedidosWrapper = useCallback(async () => {
      try {
         const res = await getPedido(getEmpresaSelecionada()?.Id!);

         if (res.erro) throw res.erro;

         return setPedidos(res.resultado?.data.list)
      } catch (e: any) {

      }
   }, [getEmpresaSelecionada, getPedido])

   useEffect(() => {
      if (isEmpty(setores)) {
         history.push('/selecionar-setor')
         showToast('info', 'Selecione os setores para visualizar a página.')
      }
   }, [history, setores, showToast])

   const getProcessoProdutoSetorWrapper = useCallback(async () => {
      try {
         // setores.map(item => item.processos).reduce((arr, obj) => arr.concat(obj), []).map(x => x.id).toString().replaceAll(',', ';')
         const query = setores.map(item => item.processos.filter(item => item.processoProducao < 9000).map(item => `${item.id};`).toString().replaceAll(',', ''))
         const saloes = setores.filter(x => !isEmpty(x.salao)).map(item => (`${item.salao.id};`).toString().replaceAll(',', ''));
         const queryString = ('&processosIds=' + query.map(item => item).toString().replaceAll(',', '')) +
            (!isEmpty(saloes) ? ('&salaoId=' + saloes.map(item => item).toString().replaceAll(',', '')) : '');

         const res = await getProcessoProdutoSetor(getEmpresaSelecionada()?.Id ?? '', queryString + '&pageSize=10000')

         if (res.erro) throw res.erro

         setProdutos(res.resultado?.data.list as ProcessoProdutoSetorModel[])
         return
      } catch (e: any) {
         showToast('error', e.message)
      }
   }, [getEmpresaSelecionada, getProcessoProdutoSetor, setores, showToast])

   useEffect(() => {
      if (!carregouRef.current) {
         getProcessoProdutoSetorWrapper()
         getPedidosWrapper();
      }
      carregouRef.current = true
   }, [getPedidosWrapper, getProcessoProdutoSetorWrapper])

   useEffect(() => {
      const interval = setInterval(() => {
         const asyncInterval = async () => {
            await getProcessoProdutoSetorWrapper()
            await getPedidosWrapper();
         }
         asyncInterval();
      }, 20000)

      return () => clearInterval(interval)
   }, [getPedidosWrapper, getProcessoProdutoSetorWrapper])


   return (
      <>
         <PublicPageHeader topOpacity="0.8" />
         {carregando && (
            <div className={classes.loading}>
               <CircularLoading tipo="FULLSIZED" />
            </div>
         )}
         <div className={classes.root}>
            <Navbar titulo="Monitoramento" />
            {setores
               .map((setor) => {
                  const produtosSetor = produtos.filter(produto => {
                     const prods = setores.filter(x => x.setor.id === produto.setorId)
                     return (produto?.setorId === setor?.setor.id) &&
                        produto.status.codigo !== EnumStatusPedido.Cancelado &&
                        (setor.salao ? isEqual(produto.salaoId, setor.salao?.id) : prods)
                  }
                  )

                  const diffConcluidosCancelados = setor?.processos.filter(item => (item.processoProducao < 9000))

                  return (
                     <Grid container style={{ padding: '16px' }} >
                        <Grid item xs={12} style={{
                           display: 'flex',
                           alignItems: 'center',
                        }}>
                           {/* <img src={setor.setor?.uriImage} alt="" /> */}
                           <Typography className={classes.title}>
                              {setor?.setor.descricao}
                           </Typography>
                           <Typography
                              variant="body2"
                              style={{
                                 paddingLeft: '8px',
                              }}
                           >
                              {!isEmpty(setor?.salao?.descricao) ? `(${setor?.salao?.descricao})` : '(Sem Salão Cadastrado)'}
                           </Typography>
                        </Grid>

                        <Grid item xs={12}>
                           <Divider variant="fullWidth" style={{ background: theme.palette.grey[400] }} />
                        </Grid>

                        <Grid item xs={12}>
                           <Grid container spacing={2} style={{ paddingTop: '16px' }}>
                              <MonitoramentoSetoresProcessosList
                                 pedidos={pedidos}
                                 getProcessoProdutoSetorWrapper={getProcessoProdutoSetorWrapper}
                                 processos={diffConcluidosCancelados}
                                 produtosSetor={produtosSetor}
                                 setAbrirQrCode={setAbrirQrCode}
                                 processosSelecionados={processosSelecionados}
                                 setProcessosSelecionados={setProcessosSelecionados}
                              />
                           </Grid>
                        </Grid>

                     </Grid>
                  )
               })}
         </div >

         <DialogQRCodeEntrega
            open={abrirQrCode}
            carregando={carregando}
            model={processosSelecionados}
            handleClose={handleClose}
            processos={produtos}
         />
      </>
   )
};

export default MonitoramentoSetoresPage