import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => {
  const heightLinha = "25px";
  const heightFavorita = "40px";
  return ({
    root: {
      width: "100%",
      paddingLeft: 20,
      height: "50px",
    },
    favoriteRoot: {
      width: "100%",
      paddingLeft: 25,
      height: "60px",
    },
    text: {
      marginLeft: "-10px",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      overflow: "hidden",
      fontWeight: 450,
      fontSize: "15px",
      color: theme.palette.text.primary,
    },
    icon: {
      fill: theme.palette.primary.main,
      width: heightLinha,
      height: heightLinha,
    },
    favoriteIcon: {
      width: heightFavorita,
      height: heightFavorita,
      fill: theme.palette.primary.main,
      marginLeft: -10
    }
    ,
    favoriteText: {
      color: theme.palette.primary.main,
      fontSize: "17px",
      fontWeight: 650,
    }
  })
});
