import { useCallback } from "react";
import { VariaveisAmbiente } from "../../../../config";

import { useApiBase } from "../../base/api-base";
import { ConfirmarRecebimentoModel } from "../../../../model/api/processo-produto-setor/confirmar-recebimento";

export function usePutConfirmarRecebimento() {
    const { invocarApi, ...outrasPropriedades } = useApiBase();

    const putConfirmarRecebimento = useCallback(
        (empresaId: string, proximoProcesso: number, model: ConfirmarRecebimentoModel) => {
            return invocarApi({
                url: `api/v5/empresa/${empresaId}/processo-produto/confirmar-recebimento/${proximoProcesso}`,
                method: "PUT",
                headers: { "Content-Type": "application/json" },
                data: model,
                baseURL: VariaveisAmbiente.apiSetoresUrl,
                enviarTokenUsuario: true,
            });
        },
        [invocarApi]
    );

    return {
        ...outrasPropriedades,
        putConfirmarRecebimento,
    };
}