import { Accordion, AccordionSummary, Fade, FormControlLabel, Typography } from "@material-ui/core"
import { isEmpty } from "lodash";
import { useGetProcessoProdutoSetor } from "../../../data/api/gestao/processo-produto-setor/get-processo-produto-setor";
import { ProcessoSetorModel } from "../../../model/api/processo-setor/processo-setor-model";
import { SetorProcessoModel } from "../../../model/api/processo-setor/setor-processo/setor-processo-model";
import { CheckboxDefault } from "../checkboxes";
import { ExpandIcon } from "../icons/expand-icon";
import { CircularLoading } from "../utils";
import { useStyles } from './accordion-processo-setores-style'
import { useCallback } from "react";
import { EnumStatusPedido } from "../../../model/enums/enum-status-pedido";

interface Props {
  model: ProcessoSetorModel
  modelSelecionado: ProcessoSetorModel
  setSetorProcesso: (setor: ProcessoSetorModel, processos: SetorProcessoModel[]) => void
}

export const AccordionSetores = ({ model, setSetorProcesso, modelSelecionado }: Props) => {

  const classes = useStyles();
  const { carregando } = useGetProcessoProdutoSetor()

  const validarStatus = useCallback((status: number) => {
    if (status < EnumStatusPedido.EmEntrega) {
        if (status === EnumStatusPedido.AguardandoRetirada) {
            return true;
        }
        if (status < EnumStatusPedido.Finalizado) {
            return true
        }

        return false;
    }
    else {
        return true
    }
}, [])

  return (
    <>
      {carregando && <CircularLoading tipo="FULLSIZED" />}
      <Fade in timeout={400}>
        <Accordion className={classes.setores}>
          <AccordionSummary
            expandIcon={<ExpandIcon tipo="BUTTON" />}>
            <Typography className={classes.title}>{model.setor.descricao}</Typography>
            {!isEmpty(model.salao?.descricao) ?
              <Typography className={classes.comSalao}> ( {model.salao?.descricao} )</Typography>
              :
              <Typography className={classes.semSalao}> ( Sem Salão cadastrado )</Typography>
            }
          </AccordionSummary>
          <div className={classes.container}>
            {model.processos.filter(item => validarStatus(item.status.codigo)).map((processo, index, count) => {
              return (
                <>
                  <FormControlLabel
                    checked={modelSelecionado.processos.filter(item => item.id === processo.id).length > 0}
                    key={processo.descricao}
                    className={classes.formControl}
                    control={
                      <CheckboxDefault
                        onClick={() => {
                          setSetorProcesso(model, [processo])
                        }}
                      />
                    }
                    label={<Typography className={classes.label}>{processo.descricao}</Typography>}
                    labelPlacement="start"
                  />
                  {(index + 1) !== count.length && (
                    <div className={classes.divider} />
                  )}
                </>
              )
            })}
          </div>
        </Accordion>
      </Fade>
    </>
  )
}

