import { useCallback, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { SelecionarContratosListData } from "./selecionar-contrato-list-data";
import { PesquisaSelecionarContrato } from "../pesquisa-selecionar-contrato/pesquisa-selecionar-contrato";
// import { ListaEmpresasListSearchProps } from "../../../lista-empresas/components/lista-empresas-list/lista-empresas-list-search-props";
import { Grid } from "@material-ui/core";
import { useStyles } from './selecionar-contrato-styles'
import { KdsStorageKeys, useKdsStorage, useSessaoAtual, useToastSaurus } from "../../../../../../services/app";
import { AutenticacaoPendenciaContratoModel } from "../../../../../../model/api/autenticacao-pendencia/autenticacao-pendencia";
import { MultiContratoModel } from "../../../../../../model/api/multi-contrato/multi-contrato";
import { CircularLoading } from "../../../../../components";

interface PropsLocation {
    email: string,
    senha: string,
    contratos: MultiContratoModel[]
}

export const SelecionarEmpresaList = () => {

    const [selectedList, setSelectedList] = useState<Array<string>>([])
    const { logar, carregando } = useSessaoAtual()
    const { showToast } = useToastSaurus()
    const history = useHistory()
    const [searchProps, setSearchProps] = useState<string>('');
    const classes = useStyles()
    const location = useLocation();
    const { setRegistro } = useKdsStorage()


    const { email, senha, contratos } = (location.state as PropsLocation) || { email: '', senha: '', contratos: undefined }

    useEffect(() => {
        if (contratos === undefined) {
            history.push('/')
        }
    }, [contratos, history])

    const onCardSelected = async (contratoId: string, documento: string) => {
        try {
            const res: any = await logar(email, atob(senha), contratoId, false)
            setRegistro(KdsStorageKeys.DocumentoContrato, documento)

            if (res.status) {
                if (res.status === 402) {
                    setRegistro(KdsStorageKeys.PendenciaContrato, res?.data as AutenticacaoPendenciaContratoModel)
                    return history.push('/pendencias')
                }
                return
            }

            history.push('/')
        } catch (e: any) {
            showToast('error', e.message)
        }
    };

    const onCardChecked = (id: string) => {
        const aux = [...selectedList];
        aux.push(id);
        setSelectedList(aux);
    };

    const onPesquisar = useCallback(
        (termo: string) => {
            setSearchProps(termo);
        },
        [setSearchProps]
    );

    // eslint-disable-next-line array-callback-return
    const pesquisa = contratos?.filter(item => {
        if (searchProps === '') return item
        if (
            item.Nome.toLowerCase().includes(searchProps.toLowerCase()) ||
            item.CNPJ.toLowerCase().includes(searchProps.toLowerCase())
        ) {
            return item
        }
    })

    return (
        <>
            {carregando && <CircularLoading tipo="FULLSIZED" />}
            <PesquisaSelecionarContrato onPesquisar={onPesquisar} />
            <Grid className={classes.listContainer}>
                <SelecionarContratosListData
                    list={pesquisa ?? []}
                    carregando={carregando}
                    onCardChecked={onCardChecked}
                    onCardSelected={onCardSelected}
                    selectedList={selectedList}
                />
            </Grid>
        </>
    )
}