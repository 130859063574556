import { useCallback } from "react";
import { VariaveisAmbiente } from "../../../../config";
import { useApiBase } from "../../base/api-base";
import { isEmpty } from "lodash";

export function useGetProcessoProdutoSetor() {
    const { invocarApi, ...outrasPropriedades } = useApiBase();

    const getProcessoProdutoSetor = useCallback(
        (empresaId: string, query?: string) => {
            return invocarApi({
                url: `api/v5/empresa/${empresaId}/processo-produto${isEmpty(query) ? '' : '?' + query}`,
                method: "GET",
                baseURL: VariaveisAmbiente.apiSetoresUrl,
                enviarTokenUsuario: true,
            });
        },
        [invocarApi]
    );

    return {
        ...outrasPropriedades,
        getProcessoProdutoSetor,
    };
}
