import { Grid } from "@material-ui/core"
import { CircularLoading } from "../../../../components"
import { useDefaultCadastroStyles } from "../../cadastros/components/default-cadastro-styles"
import { useStyles } from "./pedido-entrga-styles"
import { useCallback, useEffect, useState } from "react"
import { ProcessoProdutoSetorModel } from "../../../../../model/api/processo-produto-setor/processo-produto-setor-model"
import { useGetProcessoProdutoSetor } from "../../../../../data/api/gestao/processo-produto-setor/get-processo-produto-setor"
import { useSessaoAtual, useToastSaurus } from "../../../../../services/app"
import { ButtonFab } from "../../../../components/controles/buttons"
import { BarcodeScanIcon } from "../../../../components/icons/barcode-scan-icon"
import { useHistory } from "react-router-dom"
import { PedidoEntregaData } from "./pedido-entrega-data"
import { EnumStatusPedido } from "../../../../../model/enums/enum-status-pedido"
import { PedidoResumoModel } from "../../../../../model/api/pedidos/pedido-resumo-model"
import { PostPedidosResumoVarios } from "../../../../../model/api/pedidos/post-pedido-resumo-varios"
import { usePostPedidoResumoVarios } from "../../../../../data/api/gestao/pedido/post-pedido-resumo-varios"

export const PedidoEntregaList = () => {

    //AUX
    const history = useHistory();
    const classes = useStyles();
    const { showToast } = useToastSaurus();
    const listClasses = useDefaultCadastroStyles();

    //STATES
    const [pedidos, setPedidos] = useState<PedidoResumoModel[]>([]);
    const [processoProdutoSetor, setProcessoProdutoSetor] = useState<ProcessoProdutoSetorModel[]>([]);
    const [pedidosUnicos, setPedidosUnicos] = useState<string[]>([]);

    //CHAMADAS DA API
    const { postPedidoResumoVarios, carregando: carregandoPedidos } = usePostPedidoResumoVarios();
    const { getProcessoProdutoSetor, carregando: carregandoGetProduto } = useGetProcessoProdutoSetor();
    const { getEmpresaSelecionada, usuario, carregando: carregandoSessaoAtual } = useSessaoAtual();

    // CARREGANDO
    let carregando = carregandoGetProduto || carregandoSessaoAtual || carregandoPedidos;

    // POST DE PEDIDOS RESUMO VARIOS
    const postPedidoResumoByIdWrapper = useCallback(async (pedidosUnicos: string[]) => {
        const model = new PostPedidosResumoVarios();
        model.pedidosIds = pedidosUnicos;

        const res = await postPedidoResumoVarios(getEmpresaSelecionada()?.Id!, model);

        if (res.erro) throw res.erro;

        return setPedidos(res.resultado?.data);
    }, [getEmpresaSelecionada, postPedidoResumoVarios])

    // GET PRODUTO PROCESSO SETOR 
    const search = useCallback(
        async () => {
            try {
                let query = `&pageSize=999999&status=${EnumStatusPedido.EmEntrega}&usuarioId=${usuario?.usuarioId}`
                const res = await getProcessoProdutoSetor(getEmpresaSelecionada()?.Id!, query);

                if (res.erro) throw res.erro;

                if (!res.resultado) return;

                const resultado = res.resultado.data.list as ProcessoProdutoSetorModel[]

                const pedidosIds = Array.from(new Set<string>(resultado.map(x => x.pedidoId)));

                await postPedidoResumoByIdWrapper(pedidosIds);

                setPedidosUnicos(pedidosIds);
                setProcessoProdutoSetor(resultado);
            } catch (e: any) {
                showToast('error', e.message);
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [usuario?.usuarioId, getEmpresaSelecionada, showToast],
    );

    const handleClickButtonFab = useCallback(() => {
        history.push('/adicionar-pedido');
    }, [history])

    useEffect(() => {
        search();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <Grid className={classes.list}>
                <div className={classes.defaultContainer}>
                    {carregando && <CircularLoading tipo="FULLSIZED" />}
                    <Grid container className={listClasses.list}>
                        <Grid item xs={12} className={classes.listContainer}>
                            <PedidoEntregaData
                                pedidos={pedidos}
                                carregando={carregando}
                                list={processoProdutoSetor}
                                pedidosUnicos={pedidosUnicos}
                            />
                        </Grid>
                    </Grid>
                </div>
            </Grid>

            <ButtonFab
                tooltip="Abrir Leitor"
                style={{ position: 'fixed' }}
                icon={<BarcodeScanIcon tipo="BUTTON_FAB" />}
                onClick={() => { handleClickButtonFab() }}
            />
        </>
    )
}