import { PedidoResumoModel } from "../../../../../model/api/pedidos/pedido-resumo-model";
import { ProcessoProdutoSetorModel } from "../../../../../model/api/processo-produto-setor/processo-produto-setor-model";
import { CardNaoEncontrado } from "../../../../components/cards/card-nao-encontrado";
import { CardPedidoEntrega } from "../../../../components/cards/card-pedido-entrega/card-pedido-entrega";
import { InformacaoIcon } from "../../../../components/icons";

interface PedidoEntregaProps {
    list: Array<ProcessoProdutoSetorModel>;
    pedidos: Array<PedidoResumoModel>;
    carregando: boolean;
    pedidosUnicos: string[];
}

export const PedidoEntregaData = (props: PedidoEntregaProps) => {

    return (
        <>
            {((props.list ?? []).length === 0) && !props.carregando && (
                <CardNaoEncontrado
                    mensagem="Nenhum Pedido Encontrado."
                    icon={<InformacaoIcon tipo="GERAL" />}
                />
            )}
            {((props.list ?? []).length > 0) && (
                <>
                    <CardPedidoEntrega
                        pedidos={props.pedidos}
                        processos={props.list}
                        pedidosUnicos={props.pedidosUnicos}
                    />
                </>
            )}
        </>
    )
}