import { forwardRef } from "react"
import { AssinaturaFragmentProps } from "./assinatura-fragment-props"
import { Assinatura } from "./assinatura/assinatura"

export const AssinaturaFragment = forwardRef((props: AssinaturaFragmentProps, ref) => {

    return (
        <>
            <Assinatura
                ref={ref}
                setDataUrl={props.setDataUrl}
            />
        </>
    )
})