import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    container: {
        height: '75%',
        width: '100vw',
    },
    canvas: {
        width: '80%',
        height: '90%',
        padding: '16px'
    },
    sigCanvas: {
        width: '100%',
        height: '100%',
        border: '1px solid',
        borderColor: theme.palette.primary.main,
        borderRadius: '10px',
        background: '#FFF'
    },
    divider: {
        height: 1,
        backgroundColor: '#e0e0e0',
        margin: '16px 0',
    },
}));