import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    divRoot: {
        height: '100%',
        width: '100%',
        display: 'flex',
        position: 'fixed',
        flexDirection: 'column'
    },
    root: {
        height: '100%',
    },
}))