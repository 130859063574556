import { useCallback } from "react";
import { useApiBase } from "../../base/api-base";
import { VariaveisAmbiente } from "../../../../config";

export function useGetComanda() {
    const { invocarApi, ...outrasPropriedades } = useApiBase();

    const getComanda = useCallback(
        (empresaId: string) => {
            return invocarApi({
                url: `/${VariaveisAmbiente.pedidosVersion}/empresa/${empresaId}/comanda`,
                method: "GET",
                enviarTokenUsuario: true,
                baseURL: `${VariaveisAmbiente.apiPedidosUrl}/api`,
            });
        },
        [invocarApi]
    );
    return {
        ...outrasPropriedades,
        getComanda,
    };
}