import { Typography } from "@material-ui/core";
import { DialogSaurus } from "../dialog-saurus/dialog-saurus"
import { useStyles } from "./dialog-qr-code-entrega-styles";
import { QRCodeSVG } from 'qrcode.react';
import { useCallback, useEffect } from "react";
import { AdicionarRemoverProcessos } from "../../../../model/app/forms/adicionar-remover-processos/adicionar-remover-processos";
import { ProcessoProdutoSetorModel } from "../../../../model/api/processo-produto-setor/processo-produto-setor-model";
import { EnumStatusPedido } from "../../../../model/enums/enum-status-pedido";
import { isEmpty } from "lodash";
import { CircularLoading } from "../../utils";

interface DialogQRCodeEntregaProps {
    open: boolean;
    carregando: boolean;
    model: AdicionarRemoverProcessos[];
    processos: ProcessoProdutoSetorModel[];
    handleClose: () => void;
}

export const DialogQRCodeEntrega = (props: DialogQRCodeEntregaProps) => {

    const classes = useStyles();

    const texto = useCallback(() => {
        let valor = '';

        if (props.model.length === 1) {
            return valor = props.model[0].produtoId;
        }

        if (props.model.length > 1) {
            // eslint-disable-next-line array-callback-return
            props.model.find((item) => {
                let ids = item.produtoId;
                valor += `${ids};`
            })
        }

        return valor;
    }, [props.model])

    const verificarQrEscaneado = useCallback(() => {
        const isEscaneado = props.processos
            .filter(x => x.id === props.model[0]?.produtoId)
            .filter(x => x.status.codigo === EnumStatusPedido.EmEntrega);

        if (!isEmpty(isEscaneado)) {
            props.handleClose();
            return;
        }

        return;
    }, [props])

    useEffect(() => {
        verificarQrEscaneado();
    }, [verificarQrEscaneado])

    return (
        <>
            {props.carregando && (
                <div className={classes.loading}>
                    <CircularLoading tipo="FULLSIZED" />
                </div>
            )}
            <DialogSaurus
                aberto={props.open}
                titulo="QR Code"
                handleClickClose={props.handleClose}
                isButtonTitleClose={true}
                tamanho='xs'
                isDivider={true}
            >
                <div className={classes.qrcode}>
                    <QRCodeSVG value={texto()} size={200} />
                    <Typography variant="caption" className={classes.qrText}>
                        Leia o QRCode para iniciar <br /> a entrega.
                    </Typography>
                </div>
            </DialogSaurus>
        </>
    )
}