import { PedidoResumoModel } from "../../../api/pedidos/pedido-resumo-model";
import { Produto } from "../../../api/produto/produto-model";

export class ConfirmarEntregaModel {
    constructor (
        public processosProdutosIds: string[] = [],
        public salaoId: string = '',
        public processoAtual: number = 0,
        public codigoPedido: string = '',
        public nomeCliente: string = '',
        public documentoClinte: string = '',
        public codigoComanda: string = '',
        public codigoMesa: string = '',
        public nomeSalao: string = '',
        public dataPedido: string = '',
        public produtos: Array<Produto> = new Array<Produto>(),
        public pedido: PedidoResumoModel | undefined = new PedidoResumoModel(),
    ) {}
}