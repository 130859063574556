import { Box, Card, Divider, Grid, Typography } from "@material-ui/core"
import { CardProdutosConfirmarEntregaProps } from "./card-produtos-confirmar-entrega-props"
import { toDecimalString } from "../../../../utils/to-decimal"
import { isEmpty } from "lodash"
import CardInfoProdutoSubItem from "../card-info-produto-subitem/card-info-produto-subitem";
// import { useStyles } from "./card-produtos-confirmar-entrega-styles"

export const CardProdutosConfirmarEntrega = (props: CardProdutosConfirmarEntregaProps) => {

    // const classes = useStyles();

    let qtdTotal = 0;
    let valorTotal = 0;

    return (
        <>
            <Card
                style={{
                    boxShadow: '1px 1px 1px 2px rgb(0, 0, 0, 0.1)',
                    padding: '8px',
                }}
            >
                <Grid container>
                    <Grid item xs={12}>
                        {props.produtos.map((item) => {

                            qtdTotal = qtdTotal + item.quantidade;
                            valorTotal = valorTotal + item.valorTotal;

                            return (
                                <Grid container>
                                    <Grid item xs={12} style={{
                                        paddingBottom: '8px'
                                    }}>
                                        <Typography
                                            variant="body1"
                                            color="primary"
                                        >
                                            <Box fontWeight={700}>
                                                {item.descricao}
                                            </Box>
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} style={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                        paddingBottom: '8px'
                                    }}>
                                        <div style={{
                                            minWidth: 130,
                                            maxWidth: 130
                                        }}>
                                            <Typography
                                                variant="body1"
                                                color="textPrimary"
                                            >
                                                <Box fontWeight={500}>
                                                    {!isEmpty(item?.vendedor) ? item?.vendedor : 'Sem Vendedor'}
                                                </Box>
                                            </Typography>
                                        </div>
                                        <div>
                                            <Typography
                                                variant="body1"
                                                color="textPrimary"
                                            >
                                                <Box fontWeight={500}>
                                                    {`${item.quantidade} UN`}
                                                </Box>
                                            </Typography>
                                        </div>
                                        <div>
                                            <Typography
                                                variant="body1"
                                                color="textPrimary"
                                            >
                                                <Box fontWeight={500}>
                                                    {`R$ ${toDecimalString(item.valorUnitario)}`}
                                                </Box>
                                            </Typography>
                                        </div>
                                    </Grid>

                                    {item.inclusos.length > 0 && (
                                        <Grid item xs={12} style={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            marginLeft: '16px'
                                        }}>
                                            <Grid container spacing={2}>
                                                <Grid item xs={12}>
                                                    {item.inclusos.map(x => {
                                                        return (
                                                            <>
                                                                <Grid container spacing={2}>
                                                                    <Grid item xs={12} style={{
                                                                        display: 'flex',
                                                                        justifyContent: 'space-between'
                                                                    }}>
                                                                        <CardInfoProdutoSubItem
                                                                            produto={x}
                                                                            qtdPai={item.quantidade}
                                                                        />
                                                                    </Grid>
                                                                </Grid>
                                                            </>
                                                        )
                                                    })}
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    )}

                                    {item.adicionais && item.adicionais.length > 0 && (
                                        <Grid item xs={12} style={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            marginLeft: '16px'
                                        }}>
                                            <Grid container spacing={2}>
                                                <Grid item xs={12}>
                                                    <Typography
                                                        variant="body2"
                                                        color='textSecondary'
                                                        style={{
                                                            paddingBottom: '8px'
                                                        }}
                                                    >
                                                        Adicionais
                                                    </Typography>

                                                    {item.adicionais.map(x => {
                                                        return (
                                                            <>
                                                                <Grid container spacing={2}>
                                                                    <Grid item xs={12} style={{
                                                                        display: 'flex',
                                                                        justifyContent: 'space-between'
                                                                    }}>
                                                                        <CardInfoProdutoSubItem
                                                                            produto={x}
                                                                            qtdPai={item.quantidade}
                                                                        />
                                                                    </Grid>
                                                                </Grid>
                                                            </>
                                                        )
                                                    })}
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    )}
                                    <Grid item xs={12} style={{ marginBottom: '16px', marginTop: '16px' }}>
                                        <Divider variant="fullWidth" style={{ background: '#E6E6E6' }} />
                                    </Grid>
                                </Grid>
                            )
                        })}

                        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <Typography
                                variant="body1"
                                color="textPrimary"
                            >
                                <Box fontWeight={600}>
                                    {`Itens: ${qtdTotal} / Total: R$ ${toDecimalString(valorTotal)}`}
                                </Box>
                            </Typography>
                        </div>
                    </Grid>
                </Grid >
            </Card>
        </>
    )
}