import { useStyles } from './button-keyboard-backspace-styles';
import { ButtonKeyboardBackspaceProps } from './button-keyboard-backspace-props';
import { ApagarIcon } from '../../../icons/apagar-icon';

export function ButtonKeyboardBackspace(props: ButtonKeyboardBackspaceProps) {
  const classes = useStyles();
  return (
    <div
      className={classes.buttonKeyboardBackspaceContainer}
      onClick={() => {
        if (props.clickFunc != null) props.clickFunc();
      }}
    >
      <ApagarIcon tipo="BUTTON_FAB" fill="#878787" />
    </div>
  );
}
