import { isEmpty } from "lodash"
import { Redirect, Route, Switch } from "react-router-dom"
import { ProcessoSetorModel } from "../../../model/api/processo-setor/processo-setor-model"
import { KdsStorageKeys, useKdsStorage } from "../../../services/app"
import { MenuPrincipalProvider } from "../../../services/app/providers/menu-principal-provider"
import { NavegacaoProvider } from "../../../services/app/providers/navegacao-provider"
import { SetoresProvider } from "../../../services/app/providers/setores-provider"
import ConcluidosCanceladosPage from "../../../views/pages/private/concluidos-cancelados/concluidos-cancelados-page"
import { MainPage } from "../../../views/pages/private/main/main-page"
import MonitoramentoSetoresPage from "../../../views/pages/private/monitoramento/monitoramento-setores-page"
import PesquisaAvancadaPage from "../../../views/pages/private/pesquisa-avancada/pesquisa-avancada-page"
import SelecionarSetorPage from "../../../views/pages/private/selecionar-setor/selecionar-setor-page"
import LeitorComandaPage from "../../../views/pages/private/leitor-comanda/leitor-comanda-page"
import PedidoEntregaPage from "../../../views/pages/private/pedido-entrega/pedido-entrega-page"
import ConfirmarEntregaPage from "../../../views/pages/private/confirmar-entrega/confirmar-entrega-page"
import AssinaturaEntregaPage from "../../../views/pages/private/assinatura-entrega/assinatura-entrega-page"
import AdicionarPedidoEntregaPage from "../../../views/pages/private/adicionar-pedido-entrega/adicionar-pedido-entrega-page"
import LeitorMesaPage from "../../../views/pages/private/leitor-mesa/leitor-mesa-page"
import { ModuloModel } from "../../../model/app/forms/master/modulo-model"
import { EnumModulo } from "../../../model/enums/enum-modulo"

const RouterMain = () => {

    const { getRegistro } = useKdsStorage()

    const setores = getRegistro(KdsStorageKeys.ProcessosSetores, false) as ProcessoSetorModel[]
    const tipo = getRegistro(KdsStorageKeys.SelecaoModulo, false) as ModuloModel

    return (
        <Switch>
            <SetoresProvider>
                <MenuPrincipalProvider>
                    <MainPage>
                        <NavegacaoProvider>
                            <Route exact path="/cancelados-concluidos" component={ConcluidosCanceladosPage}
                            />
                            <Route exact path="/monitoramento" component={MonitoramentoSetoresPage}
                            />
                            <Route exact path="/selecionar-setor" component={SelecionarSetorPage}
                            />
                            <Route exact path="/ler-comanda" component={LeitorComandaPage}
                            />
                            <Route exact path="/ler-mesa" component={LeitorMesaPage} 
                            />
                            <Route exact path="/adicionar-pedido" component={AdicionarPedidoEntregaPage}
                            />
                            <Route exact path="/pedidos-para-entrega" component={PedidoEntregaPage}
                            />
                            <Route exact path="/confirmar-entrega" component={ConfirmarEntregaPage}
                            />
                            <Route exact path="/assinatura" component={AssinaturaEntregaPage}
                            />
                            {/* <Route exact path="/selecionar-mesa" component={SelecionarMesaPage}
                            /> */}
                            <Route exact path="/">
                                <Redirect to={
                                    !isEmpty(tipo) ?
                                        tipo.modulo === EnumModulo.KDS ?
                                            isEmpty(setores)
                                                ? '/selecionar-setor'
                                                : "/monitoramento"
                                            : '/pedidos-para-entrega'
                                        : '/'
                                }
                                />
                            </Route>
                            <Route exact path="/pesquisa-avancada" component={PesquisaAvancadaPage} />
                        </NavegacaoProvider>
                    </MainPage>
                </MenuPrincipalProvider>
            </SetoresProvider>
        </Switch>
    )
}

export default RouterMain