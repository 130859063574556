import { useCallback } from "react";
import { isEmpty } from 'lodash';
import { decodeToken } from "react-jwt";
import { UsuarioConectadoModel } from "../../../model/app";
import { PerfilModel } from '../../../model/api/gestao/master/perfil';

import { PlanoAtualModel } from "../../../model/api/gestao/plano/plano-atual-model";
import { TokenKdsModel } from "../../../model/api/gestao/master/token-kds";
import { KdsStorageKeys, useKdsStorage } from "./kds-storage";

export function useKdsToken() {
    const { setRegistro, delRegistro, getRegistro } = useKdsStorage();
    const tokenFromSession: boolean = false;

    const isTokenExpired = useCallback((token: TokenKdsModel | undefined): boolean => {
        if (!token?.exp) {
            return true;
        }
        return token?.exp * 1000 <= new Date().valueOf();
    }, []);

    const convertToken = useCallback((token?: string): TokenKdsModel | undefined => {
        let gtoken: TokenKdsModel | undefined;
        try {
            const utf8 = require('utf8');
            gtoken = decodeToken(token!) as TokenKdsModel;
            gtoken.saudacao = utf8.decode(gtoken.saudacao);
            gtoken.originalToken = token || '';
            gtoken.empresa = JSON.parse(JSON.parse(JSON.stringify(gtoken.empresa)))
            gtoken.usuarioId = gtoken.sub || gtoken.jti;
        } catch (e) {
            gtoken = undefined;
        }
        return gtoken;
    }, []);

    const getTokenFromStorage = useCallback((): TokenKdsModel | undefined => {
        const token = getRegistro(KdsStorageKeys.Token, tokenFromSession);
        if (isEmpty(token))
            return undefined;

        return (convertToken(token));
    }, [getRegistro, convertToken, tokenFromSession]);

    const isTokenValid = useCallback((token: TokenKdsModel | undefined): boolean => {
        return !isEmpty(token) && !isTokenExpired(token);
    }, [isTokenExpired]);

    const persistToken = useCallback((token: TokenKdsModel | undefined): void => {
        if (isEmpty(token)) {
            return delRegistro(KdsStorageKeys.Token, tokenFromSession);
        } else {
            return setRegistro(KdsStorageKeys.Token, token?.originalToken || "", tokenFromSession);
        }
    }, [delRegistro, setRegistro, tokenFromSession]);

    const persistPerfilPermissoes = useCallback((perfis: Array<PerfilModel> | undefined) => {
        if (perfis)
            setRegistro(KdsStorageKeys.PerfisDoUsuario, perfis, tokenFromSession);
        else
            delRegistro(KdsStorageKeys.PerfisDoUsuario, tokenFromSession);
    }, [setRegistro, delRegistro, tokenFromSession]);

    const getPerfilPermissoes = useCallback((): Array<PerfilModel> => {
        const ret = getRegistro(KdsStorageKeys.PerfisDoUsuario, tokenFromSession);
        if (isEmpty(ret))
            return new Array<PerfilModel>();

        return ret;
    }, [getRegistro, tokenFromSession])

    const persistTermosDeUso = useCallback((valid: boolean | undefined, statusApi: number | undefined) => {
        if (valid === undefined)
            delRegistro(KdsStorageKeys.TermosDeUso, tokenFromSession)
        else {
            const termo = {
                termoAceito: valid,
                statusApi
            }
            setRegistro(KdsStorageKeys.TermosDeUso, termo, tokenFromSession);
        }
    }, [setRegistro, delRegistro, tokenFromSession]);

    const getTermosDeUso = useCallback((): boolean => {
        const ret = getRegistro(KdsStorageKeys.TermosDeUso, tokenFromSession);
        if (isEmpty(ret)) {
            return true;
        } if (ret.termoAceito === false && ret.statusApi === 400) {
            return false
        } else {
            return true;
        }
    }, [getRegistro, tokenFromSession])


    const getEmpresaAtual = useCallback((): string => {
        const ret = getRegistro(KdsStorageKeys.EmpresaAtual, tokenFromSession);
        if (isEmpty(ret))
            return '';

        return ret;
    }, [getRegistro, tokenFromSession])

    const persistEmpresaSelecionada = useCallback((empresaId: string | undefined) => {
        if (empresaId === undefined)
            delRegistro(KdsStorageKeys.EmpresaAtual, tokenFromSession)
        else
            setRegistro(KdsStorageKeys.EmpresaAtual, empresaId.toString(), tokenFromSession);
    }, [setRegistro, delRegistro, tokenFromSession]);

    const getEmpresaAnterior = useCallback((empresaId: string | undefined) => {
        if (empresaId === undefined)
        delRegistro(KdsStorageKeys.EmpresaAtual, tokenFromSession)
    else
        setRegistro(KdsStorageKeys.EmpresaAtual, empresaId.toString(), tokenFromSession);
    }, [delRegistro, setRegistro, tokenFromSession])


    const getPlanoUsuario = useCallback((): PlanoAtualModel | undefined => {
        const ret = getRegistro(KdsStorageKeys.Plano, tokenFromSession);
        if (isEmpty(ret))
            return undefined;

        return ret;
    }, [getRegistro, tokenFromSession])

    const persistPlanoUsuario = useCallback((plano: PlanoAtualModel | undefined) => {
        if (plano)
            setRegistro(KdsStorageKeys.Plano, plano, tokenFromSession);
        else
            delRegistro(KdsStorageKeys.Plano, tokenFromSession);
    }, [setRegistro, delRegistro, tokenFromSession]);

    const removeConnectedUser = useCallback((usuarioId: string) => {
        let conectados: UsuarioConectadoModel[] = getRegistro(KdsStorageKeys.UsuariosConectados, false) as UsuarioConectadoModel[];
        if (isEmpty(conectados)) {
            conectados = new Array<UsuarioConectadoModel>();
        }
        conectados = conectados.filter(x => x.usuarioId !== usuarioId);
        setRegistro(KdsStorageKeys.UsuariosConectados, conectados, false);
    }, [setRegistro, getRegistro]);

    const getConnectedUsers = useCallback((): UsuarioConectadoModel[] => {
        let conectados: UsuarioConectadoModel[] = getRegistro(KdsStorageKeys.UsuariosConectados, false) as UsuarioConectadoModel[];
        if (isEmpty(conectados)) {
            conectados = new Array<UsuarioConectadoModel>();
        }
        return conectados;
    }, [getRegistro]);

    const addConnectedUser = useCallback((token: TokenKdsModel) => {
        let conectados: UsuarioConectadoModel[] = getRegistro(KdsStorageKeys.UsuariosConectados, false) as UsuarioConectadoModel[];
        if (isEmpty(conectados)) {
            conectados = new Array<UsuarioConectadoModel>();
        }

        conectados = conectados.filter(x => x.usuarioId !== token.usuarioId);

        let conUser = new UsuarioConectadoModel(token.usuarioId, token.empresa[0].Descricao, token.saudacao, token.originalToken);
        conectados.push(conUser);

        setRegistro(KdsStorageKeys.UsuariosConectados, conectados, false);
    }, [setRegistro, getRegistro]);

    return {
        isTokenExpired,
        isTokenValid,
        persistToken,
        getTokenFromStorage,
        convertToken,
        removeConnectedUser,
        addConnectedUser,
        getConnectedUsers,
        persistTermosDeUso,
        getTermosDeUso,
        persistPerfilPermissoes,
        getPerfilPermissoes,
        getEmpresaAtual,
        getEmpresaAnterior,
        persistEmpresaSelecionada,
        getPlanoUsuario,
        persistPlanoUsuario,
    };
}