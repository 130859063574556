import { useThemeQueries } from "../../../theme";
import { DefaultCard } from "../components";
import { Box, Button, Divider, Grid, Tooltip, Typography } from "@material-ui/core";
import { isEmpty } from "lodash";
import { toDateString } from "../../../../utils/to-date";
import { CardPedidoEntregaProps } from "./card-pedido-entrega-props";
import { ProcessoProdutoSetorModel } from "../../../../model/api/processo-produto-setor/processo-produto-setor-model";
import { useCallback, useState } from "react";
import { CardProdutosEntrega } from "../card-produtos-entrega/card-produtos-entrega";
import { KdsStorageKeys, useKdsStorage } from "../../../../services/app";
import { EnumModulo } from "../../../../model/enums/enum-modulo";
import { ModuloModel } from "../../../../model/app/forms/master/modulo-model";
import { MapsIcon } from "../../icons/maps-icon";
import { PedidoEnderecoModel } from "../../../../model/api/pedidos/pedido-model";
import { EnumTipoPedido } from "../../../../model/enums/enum-tipo-pedido";
import { PedidoResumoModel } from "../../../../model/api/pedidos/pedido-resumo-model";

export const CardPedidoEntrega = (props: CardPedidoEntregaProps) => {

    //AUX
    const { isMobile, theme } = useThemeQueries();
    const { getRegistro } = useKdsStorage();

    //STATES
    const [processoSelecionados, setProcesssosSelecionados] = useState<ProcessoProdutoSetorModel[]>([])

    const modulo = getRegistro(KdsStorageKeys.SelecaoModulo, false) as ModuloModel;

    const pegaHora = useCallback((data: string) => {
        const date = new Date(data)
        return `${('00' + date.getHours()).substr(-2)}:${('00' + date.getMinutes()).substr(-2)}`
    }, [])

    const retornaEndereco = useCallback((model: PedidoResumoModel | undefined) => {
        const { bairro, municipio, numero, complemento, logradouro, uf } = model?.enderecoEntrega ?? new PedidoEnderecoModel()

        if (isEmpty(model?.enderecoEntrega)) {
            return ''
        }

        return `${logradouro ?? ''}, ${numero ?? 0} - ${bairro ?? ''}${municipio ? `, ${municipio}` : ''}${uf ? ` - ${uf}` : ''}${complemento ? `, ${complemento}` : ''}`
    }, [])

    const linkGoogleMaps = useCallback((model: PedidoResumoModel | undefined) => {
        let localizacao = '';

        if (model?.enderecoEntrega?.latitude !== 0 && model?.enderecoEntrega?.longitude !== 0) {
            localizacao = `${model?.enderecoEntrega?.latitude?.toString()},${model?.enderecoEntrega?.longitude?.toString()}`;
        }
        else {
            localizacao = retornaEndereco(model);
        }

        const path = `https://maps.google.com/?q=${localizacao}`;

        return window.open(path, '_blank');
    }, [retornaEndereco])

    const processosProdutos = props.pedidosUnicos.filter(x => props.pedidos.find(y => y.id === x)).map((pedidosIds) => {

        const processos = props.processos.find(item => item.pedidoId === pedidosIds)
        const produtos = props.processos.filter(x => x.pedidoId === pedidosIds)
        const pedido = props.pedidos.find(x => x.id === pedidosIds);

        return (
            <>
                <Grid item xs={isMobile ? 12 : 4}>
                    <DefaultCard
                        style={{
                            boxShadow: '1px 1px 1px 2px rgb(0, 0, 0, 0.1)',
                        }}
                    >
                        <Grid container spacing={2} style={{
                            padding: '8px',
                            display: 'flex',
                            alignItems: 'center',
                        }}>
                            <Grid item xs={12}>
                                <Grid container>
                                    <Grid item xs={6}>
                                        <Typography
                                            variant="h6"
                                            color="textPrimary"
                                        >
                                            <Box fontWeight={600}>
                                                {`Pedido Nº ${processos?.codigoPedido}`}
                                            </Box>
                                        </Typography>
                                    </Grid>

                                    <Tooltip arrow title="Data de Inserção">
                                        <Grid item xs={6} style={{
                                            display: 'flex',
                                            justifyContent: 'flex-end'
                                        }}>
                                            <Typography
                                                variant="body2"
                                                color="primary"
                                            >
                                                <Box fontWeight={600}>
                                                    {toDateString(new Date(processos?.systemInsertDate!))} - {pegaHora(processos?.systemInsertDate!)}
                                                </Box>
                                            </Typography>
                                        </Grid>
                                    </Tooltip>

                                    {modulo.modulo === EnumModulo.EntregaLocal && pedido?.tipoPedido.codigo === EnumTipoPedido.LOCAL && (
                                        <Grid item xs={12} style={{ paddingTop: '0x' }}>
                                            <Grid container spacing={2}>
                                                <Grid item xs={12} style={{
                                                    display: 'flex',
                                                }}>
                                                    <Grid container spacing={1}>
                                                        <Grid item xs={3} style={{
                                                            display: 'flex',
                                                        }}>
                                                            <Typography
                                                                variant="body2"
                                                                color='textPrimary'
                                                            >
                                                                Mesa:
                                                            </Typography>
                                                            <Typography
                                                                variant="body2"
                                                                color="textPrimary"
                                                                style={{
                                                                    marginLeft: '8px'
                                                                }}
                                                            >
                                                                <Box fontWeight={600}>
                                                                    {!isEmpty(processos?.mesaCodigo) ? processos?.mesaCodigo : '-'}
                                                                </Box>
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={9} style={{
                                                            display: 'flex'
                                                        }}>
                                                            <Typography
                                                                variant="body2"
                                                                color='textPrimary'
                                                            >
                                                                Comanda:
                                                            </Typography>
                                                            <Typography
                                                                variant="body2"
                                                                color="textPrimary"
                                                                style={{
                                                                    marginLeft: '8px'
                                                                }}
                                                            >
                                                                <Box fontWeight={600}>
                                                                    {!isEmpty(processos?.comandaCodigo) ? processos?.comandaCodigo : '-'}
                                                                </Box>
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    )}
                                </Grid>
                            </Grid>

                            <Grid item xs={12}>
                                <Divider variant="fullWidth" style={{ background: theme.palette.grey[300] }} />
                            </Grid>

                            {modulo.modulo === EnumModulo.EntregaDelivery && (
                                <>
                                    <Grid item xs={12} style={{
                                        display: 'flex',
                                    }}>
                                        <div style={{
                                            width: '100%',
                                            marginRight: '8px'
                                        }}>
                                            <Grid container style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                background: theme.palette.grey[100],
                                                borderRadius: '5px',
                                                padding: '8px',
                                                width: '100%'
                                            }}>
                                                <Grid item xs={12} style={{
                                                    display: 'flex',
                                                }}>
                                                    <Typography
                                                        variant="body1"
                                                        color="textSecondary"
                                                    >
                                                        Cliente:
                                                    </Typography>
                                                    <Typography
                                                        variant="body1"
                                                        color="textPrimary"
                                                        style={{
                                                            paddingLeft: '8px',
                                                        }}
                                                    >
                                                        <Box fontWeight={700}>
                                                            {!isEmpty(processos?.cliente) ? processos?.cliente : 'Consumidor'}
                                                        </Box>
                                                    </Typography>
                                                </Grid>
                                                {!(retornaEndereco(pedido).length <= 5) && (
                                                    <Grid item xs={12} style={{ paddingTop: '8px' }}>
                                                        <Typography
                                                            variant="body1"
                                                            color="textSecondary"
                                                        >
                                                            {retornaEndereco(pedido)}
                                                        </Typography>
                                                    </Grid>
                                                )}
                                            </Grid>
                                        </div>
                                        <div>
                                            <Tooltip arrow title='Mapa'>
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    size="small"
                                                    onClick={() => linkGoogleMaps(pedido)}
                                                    style={{
                                                        height: '40px',
                                                        width: '40px'
                                                    }}
                                                >
                                                    <span style={{ display: 'flex', alignItems: 'center' }}>
                                                        <MapsIcon tipo="BUTTON_PRIMARY" style={{ marginRight: '0px' }} />
                                                    </span>
                                                </Button>
                                            </Tooltip>
                                        </div>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <Divider variant="fullWidth" style={{ background: theme.palette.grey[300] }} />
                                    </Grid>
                                </>
                            )}

                            <Grid item xs={12}>
                                <CardProdutosEntrega
                                    pedido={pedido}
                                    model={produtos}
                                    tipoTrabalho={modulo}
                                    processosSelecionados={processoSelecionados}
                                    setProcessosSelecionados={setProcesssosSelecionados}
                                />
                            </Grid>
                        </Grid>
                    </DefaultCard>
                </Grid>
            </>
        )
    })

    return (
        <>
            <Grid container>
                {processosProdutos}
            </Grid>
        </>
    );
}