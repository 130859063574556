import { makeStyles } from "@material-ui/core"

export const useStyles = makeStyles((theme) => ({
    cardProduto: {
        // marginTop: '8px',
        maxHeight: '220px',
        overflowY: 'auto',
    },
    buttonCard: {
        height: '40px'
    }
    
}))