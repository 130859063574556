import { ModuloModel } from "../../../../model/app/forms/master/modulo-model";
import { EnumModulo } from "../../../../model/enums/enum-modulo";
import { KdsStorageKeys, useKdsStorage } from "../../../../services/app";
import { PublicPageHeader } from "../../../components/headers/header-public-page/header-public-page";
import { Navbar } from "../../../components/nav";
import { PedidoEntregaList } from "./components/pedido-entrega-list";
import { useStyles } from "./pedido-entrega-styles";

const PedidoEntregaPage = () => {
    const classes = useStyles();
    const {getRegistro} = useKdsStorage();

    const model = getRegistro(KdsStorageKeys.SelecaoModulo, false) as ModuloModel;

    return (
        <>
            <PublicPageHeader topOpacity="0.8" />
            <div className={classes.divRoot}>
                <Navbar titulo={model.modulo === EnumModulo.EntregaDelivery ? 'Entrega Delivery' : 'Entrega Local'} />
                <div className={classes.root}>
                    <PedidoEntregaList/> 
                </div>
            </div>
        </>
    )
}

export default PedidoEntregaPage;