import { makeStyles } from "@material-ui/core";

export const makeUtilClasses = makeStyles((theme) => ({
    rootContainer: {
        position: "relative",
        maxWidth: '1120px'
    },
    contentCenter: {
        width: "100%",
        minHeight: "100vh",
        padding: 20,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    controlLoading: {
        opacity: 0.6,
    },
    formContainer: {
        position: "relative",
        "& form": {
            transition: "0.3s all",
        },
        width: "100%",
    },

    btnSticky: {
        position: "sticky",
        bottom: "0",
        zIndex: 2,
        backgroundColor: "#FFFFFF",
    },
    acoes: {
        flex: "0 0 auto",
    },
}));
