import React from "react";
import { AppEventEnum } from "../../../../model/enums/enum-app-event";

export function useEventTools() {

    const callEvent = React.useCallback((e: AppEventEnum, object: any) => {
        const event = new CustomEvent(e.toString(), { detail: object });
        window.dispatchEvent(event);
    }, []);

    const addHandler = React.useCallback((e: AppEventEnum, callback: any) => {
        window.addEventListener(e.toString(), (ret :any) => callback(ret.detail), false);
    }, [])

    const removeHandler = React.useCallback((e: AppEventEnum, callback: any) => {
        window.removeEventListener(e.toString(), (ret :any) => callback(ret.detail));
    }, [])

    return {
        callEvent,
        addHandler,
        removeHandler
    }
}
