
import { TokenKdsEmpresaModel } from "../../../../../../model/api/gestao/master/token-kds-empresa";
import { CardEmpresaConfig } from "../../../../../components/cards/card-empresa/card-empresa-config";
import { CardNaoEncontrado } from "../../../../../components/cards/card-nao-encontrado";
import { InformacaoIcon } from "../../../../../components/icons";

export interface SelecionarEmpresaGridProps {
    list: Array<TokenKdsEmpresaModel>;
    carregando: boolean;
    selectedList: Array<string>;
    onCardSelected: (id: string) => any;
    onCardChecked: (id: string) => any;
}

export const SelecionarEmpresaListData = (props: SelecionarEmpresaGridProps) => {

    const onCardSelected = (id: string) => {
        props.onCardSelected(id);
    };

    const onCardChecked = (id: string) => {
        props.onCardChecked(id);
    };

    return (
        <>
            {props.list.length === 0 && !props.carregando && (
                <CardNaoEncontrado
                    mensagem="Nenhuma Empresa encontrada."
                    icon={<InformacaoIcon tipo="GERAL" />}
                />
            )}
            {props.list.length > 0 &&
                props.list.map((empresa, index) => {
                    return (
                        <CardEmpresaConfig
                            selected={
                                props.selectedList.filter((item) => item === empresa.Id).length >
                                    0
                                    ? true
                                    : false
                            }
                            onCheck={onCardChecked}
                            onClick={onCardSelected}
                            model={empresa}
                            key={index}
                        />
                    );
                })}
        </>
    );
};
